import styled from 'styled-components';
import { grey } from '@material-ui/core/colors';

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: ${grey[800]};
  cursor: pointer;

  > span {
    margin-top: 2px;
  }
`;
