import { LocaleStrings } from '../index';

const esMX: LocaleStrings = {
  ProtocolNumber: 'Número de ticket',
  SetYourStatus: 'Selecciona tu estado',
  LogOut: 'Salir',
  SigningIn: 'Entrar',
  SessionExpired:
    "Tu sesión expiró. <a href='#' onClick='window.location.reload()'>Haz click aquí para volver a entrar</a>",
  NoTasksTitle: 'Estado',
  NoTasks: 'Sin tareas activas',
  NoTasksHintNotAvailable: 'Cambia tu estado para comenzar a recibir tareas. ',
  NoTasksHintAvailable: 'Estás disponible para recibir tareas. ',
  NoCRMConfigured: 'Sin CRM configurado',
  NoCRMConfiguredAction: 'Cómo configurar',
  NoCRMTitle: 'Configuración del CRM',
  NoCRMHint: 'Nuestros documentos pueden ayudarte a empezar. ',
  LoginUsername: 'Nombre de usuario',
  LoginPassword: 'Contraseña',
  LoginLoading: 'Cargando... ',
  LoginWelcome: '¡Hola! Bienvenido a Flex. ',
  LoginLogin: 'Entrar',
  LoginContinue: 'Continuar',
  LoginAgain: 'Entrar de nuevo',
  LoginLaunch: 'Lanzar',
  LoginTitle: 'Twilio Flex',
  LoginErrorDomain: '¡Uy! El dominio está incorrecto. ',
  LoginErrorDomainDetails:
    "Encontré tu dominio <a href='https://www.twilio.com/console/runtime/overview'>aquí</a>",
  LoginErrorSso: 'Hmm, tu Single Sign-On no está configurado. ',
  LoginErrorSsoDetails:
    "Aprende a configurar tu Single Sign-On <a href='https://www.twilio.com/console/flex/users/single-sign-on'>aquí</a>",
  LoginErrorPopupBlocked: 'Disculpa, tus pop-ups están bloqueados. ',
  LoginErrorPopupBlockedDetails:
    "<a href='https://support.google.com/chrome/answer/95472'> Descubre cómo activar los pop-ups en tu navegador</a>",
  LoginErrorUntrustedDomain:
    'Single Sign-On no esté configurado en este dominio. ',
  LoginErrorUntrustedDomainDetails:
    "Aprende a configurar tu Single Sign-On <a href='https://www.twilio.com/docs/flex/configuring-single-sign-and-identity-provider-integration#how-do-i-login-to-a-self-hosted-domain' target='_blank'>aquí</a>",
  LoginErrorInvalidCertificate: 'La configuración de SSO es inválida',
  LoginErrorInvalidCertificateDetails:
    "Puede ser por una URL mal escrita o por certificados inválidos/inexistentes. <br><br>Reconfigura en <a href='https://www.twilio.com/docs/api/errors/70251' target='_blank'>configuración de SSO</a> e intenta nuevamente. ",
  LoginErrorGeneric: '¡Uy!, esto no funcionó. Verifica tu información.',
  LoginIsAdmin: '¿Eres administrador Flex?” ',
  LoginWithTwilio: 'Entra con Twilio. ',
  TaskHeaderLeaveCall: 'DEJAR LLAMADA',
  TaskHeaderEndCall: 'COLGAR',
  TaskHeaderWrapup: 'CERRAR',
  TaskHeaderComplete: 'COMPLETO',
  TaskHeaderEndChat: 'CERRAR CHAT',
  TaskHeaderStatusPending: '',
  TaskHeaderStatusAccepted: 'En vivo | {{helper.DurationSinceUpdate}}',
  TaskHeaderWebChatStatusAccepted:
    "{{#if helper.chat.isCustomerOnline}} {{icon name='GreenIndicator'}} {{else}} {{icon name='greyIndicator'}} {{/if}} En vivo | {{helper.DurationSinceUpdate}}",
  TaskHeaderGroupCallAccepted:
    "En vivo | {{helper.DurationSinceUpdate}} | {{icon name='Participant'}} {{task.conference.liveParticipantCount}}",
  TaskHeaderStatusWrapup: 'CERRAR | {{helper.DurationSinceUpdate}}',
  TaskHeaderStatusCompleted: 'COMPLETO | {{helper.DurationSinceUpdate}}',
  SideNavTaskView: 'Área de trabajo del Agente',
  SideNavTeamsView: 'Equipos',
  SideNavAgentDashboard: 'Panel de control del Agente',
  SideNavSupervisorInspections: 'Inspección del Supervisor',
  SideNavSupervisorDashboard: 'Panel de control del Supervisor',
  SideNavSupervisorReports: 'Reportes del Supervisor',
  SideNavSettings: 'Configuración',
  SideNavQueuesStatsView: 'Estadísticas de Fila',
  TaskHeaderLine: '{{task.defaultFrom}}',
  TaskLineCallIncomingTitle: 'Llamada recibida',
  TaskLineCallTransferTitle: 'Transferencia',
  TaskLineCallEndedTitle: 'Llamada terminada',
  TaskLineQueue: 'Fila: {{task.queueName}}',
  TaskLineCallDuration: '{{helper.DurationSinceUpdate}}',
  TaskLineCallReserved: 'Llamada recibida en la fila {{task.queueName}}',
  TaskLineCallAssigned: 'En vivo | {{helper.DurationSinceUpdate}}',
  TaskLineCallWrapup: 'Encerrar | {{helper.DurationSinceUpdate}}',
  TaskLineGroupCallAssigned:
    "Live | {{helper.DurationSinceUpdate}} | {{icon name='Participant'}} {{task.conference.liveParticipantCount}}",
  TaskLineCallTransferWorker:
    'Solicitud de {{task.incomingTransferObject.worker.fullName}} (direct)',
  TaskLineCallTransferWorkerDetail:
    'De: {{task.incomingTransferObject.worker.fullName}} (direct)<br />Fila: {{task.queueName}}',
  TaskLineCallTransferQueue:
    'Solicitud de {{task.incomingTransferObject.worker.fullName}} ({{task.IncomingTransferObject.queue.name}})',
  TaskLineCallTransferQueueDetail:
    'De: {{task.incomingTransferObject.worker.fullName}}<br />Fila: {{task.queueName}}',
  TaskLineChatReserved: 'Pedido de chat recibido',
  TaskLineChatLineReserved: 'Pedido de linea de entrada',
  TaskLineChatMessengerReserved: 'Pedido de Messenger de entrada',
  TaskLineChatWhatsAppReserved: 'Pedido  de WhatsApp recibido',
  TaskLineSmsReserved: 'Pedido de SMS de entrada',
  TaskLineTitle: '',
  TaskReserved: 'Reservado',
  TaskAssigned: 'Asignado',
  TaskWrapup: 'En finalización',
  TaskCompleted: 'Completo',
  TaskCanceled: 'Cancelado',
  TaskFilterAll: 'TODAS LAS TAREAS',
  TaskFilterActive: 'TAREAS ACTIVAS',
  TaskFilterWrapping: 'TAREAS POR FINALIZAR',
  ColumnHeaderAgent: 'AGENTE',
  ColumnHeaderCalls: 'LLAMADAS',
  ColumnHeaderTasks: 'OTRAS TAREAS',
  SupervisorUserCardFirstLine: '{{worker.fullName}}',
  SupervisorUserCardSecondLine:
    '{{worker.activityName}} | {{worker.activityDuration}}',
  SupervisorTaskViewContentHeader: '{{worker.fullName}}, {{task.defaultFrom}}',
  SupervisorTaskHeaderLine: '{{task.defaultFrom}}',
  SupervisorTaskLive: '{{helper.DurationSinceUpdateShort}}',
  SupervisorTaskGroupCall:
    '{{helper.DurationSinceUpdateShort}} | ({{task.Conference.LiveParticipantCount}})',
  SupervisorTaskWrapUp: 'Cerrar | {{helper.DurationSinceUpdateShort}}',
  SupervisorTaskCompleted: 'Completo | {{helper.DurationSinceUpdateShort}}',
  SupervisorTaskCardHeader: '{{task.defaultFrom}}',
  LiveCommsMonitoringMessage:
    'Estás escuchando una llamada con {{task.defaultFrom}} e {{worker.fullName}}',
  LiveCommsMonitoringMessageMoreWorkers:
    'Estás escuchando una llamada con {{task.defaultFrom}} y {{workers.length}} agentes',
  LiveCommsMonitoringMessageNoWorkers:
    'Estás escuchando una llamada con {{task.defaultFrom}}',
  LiveCommsOngoingCallMessage: 'Estás en una llamada con {{task.defaultFrom}}',
  // LiveCommsIncomingCallMessage: 'Llamada recibida de {{task.defaultFrom}}',
  // LiveCommsBarAccept: 'aceptar',
  // LiveCommsBarDeny: 'declinar',
  LiveCommsBarReturnToCall: 'REGRESAR',
  LiveCommsBarStopListening: 'DEJAR DE ESCUCHAR',
  LiveCommsBarHold: 'EN ESPERA',
  LiveCommsBarUnHold: 'RETOMAR',
  WorkerDirectoryTitle: 'Transferir',
  WorkerDirectoryItemFirstLine: '{{worker.fullName}}',
  // WirectoryItemSecondLine: '{{worker.activityName}}',
  WorkerDirectoryAgentsTabLabel: 'AGENTES',
  WorkerDirectoryQueuesTabLabel: 'FILAS',
  WorkerDirectoryQueueItemText: '{{queue.name}}',
  WorkerDirectorySearchPlaceholder: 'Buscar',
  ChatInputDisabledText: 'La conversación terminó',
  WorkerCanvasHeader: 'Perfil',
  WorkerCanvasDetailsHeader: 'Detalles de Agente',
  WorkerCanvasSkillsHeader: 'Habilidades',
  WorkerSkillLevelInvalid:
    'Seleciona un nivel de habilidad entre {{min}} - {{max}}',
  WorkerSkillPleaseSelect: 'Agregar habilidad',
  WorkerSkillsNoSkills: 'Sin habilidades guardadas',
  WorkerSkillsSchemaMismatch:
    'Incompatibilidad de esquema de habilidades del agente',
  WorkerSkillsSaved: 'Cambios guardados con éxito',
  WorkerSkillsError: 'Error al guardar habilidades',
  WorkerSkillsReverted: 'Cambios deshechos',
  ChatWelcomeText: 'La conversación con el conductor comenzó',
  UiVersionDeprecated: '{{message}}',
  RuntimeLoginLabel: 'Tiempo transcurrido',
  RuntimeLoginPlaceholder: 'Tiempo de ejecución de dominio',
  RuntimeLoginLaunch: 'Lanzar',
  RuntimeLoginWithTwilio: 'Entra con Twilio',
  RuntimeLoginIsAdmin: '¿Eres administrador Flex?',
  RuntimeLoginErrorDomain:
    '¡Uy! Eso no funcionó: (Verifica tu <b> dominio</b>. ',
  RuntimeLoginErrorSso:
    '¡Uy! Eso no funcionó (Verifica tu configuración <b>SSO</b> . ',
  TransferFailedNotification: 'Agente no disponible',
  TaskCannotBeTransferredNotification: 'Tarea no puede ser transferida',
  SupervisorTaskCanvasHeader: 'DETALLES DE TAREA',
  TaskTabAgentIncomingLabel: 'Recibiendo',
  // TaskTabAgentCallLabel: ‘Llamar’,
  // TaskTabAgentChatLabel: 'Chat',
  TaskTabAgentInfoLabel: 'Información',
  TaskTabSupervisorOverviewLabel: 'Vista general',
  TaskTabSupervisorCallLabel: 'Llamar',
  TaskTabSupervisorChatLabel: 'Chat',
  TaskTabSupervisorInfoLabel: 'Información',
  MicNotAvailableNotification:
    'No es posible acceder al micrófono, verifica la configuración de tu navegador.',
  BrowserVoiceDisabledNotification:
    'La llamada no puede ser aceptada. El SDK de Twilio Voice fue desactivado. ',
  MessageSizeExceeded:
    'El número de caracteres excede el límite - {{currentCharCount}}/{{maxCharCount}}',
  UserControlWorkerSecondLine:
    '{{worker.activityName}} | {{worker.activityDuration}}',
  UserLoggedOutSuccess: '¡Te has desconectado!” ',
  CannotChangeOtherWorkerActivity:
    'No puedes cambiar la actividad de un agente mientras tenga una tarea pendiente. ',
  CallCanvasWorkerOnHold: 'Estás en espera',
  CallCanvasUnholdWorker: 'Quitar la espera',
  CallParticipantStatusOnHold: 'En espera',
  CallParticipantStatusLive: 'En vivo',
  CallParticipantStatusConnecting: 'Llamando... ',
  CallParticipantStatusLeft: 'Llamada abandonada',
  CallParticipantStatusTransferFailed: 'Sin respuesta',
  CallParticipantStatusKickConfirmation: '¿Sacar de la llamada?” ',
  LiveCommsIncomingDirectCallTransfer:
    'Solicitud de {{task.incomingTransferObject.worker.fullName}} (direct)',
  LiveCommsIncomingQueueCallTransfer:
    'Solicitud de {{task.incomingTransferObject.worker.fullName}} ({{task.IncomingTransferObject.queue.name}})',
  CallParticipantCustomerName: '{{task.defaultFrom}}',
  MonitoringFailedNotification: 'Falla de monitoreo',
  PredefinedChatMessageAuthorName: 'Bot',
  PredefinedChatMessageBody: '¡Hola! ¿Cómo te puedo ayudar hoy?” ',
  AgentStatusAvailable: 'Disnponible',
  AgentStatusBusy: 'Ocupado',
  AgentStatusUnavailable: 'No disponible',
  AgentStatusOffline: 'Offline',
  ActiveTasksTileTitle: 'Tareas activas',
  WaitingTasksTileTitle: 'Tareas en espera',
  AgentsByActivityTileTitle: 'Agentes',
  LongestWaitTimeTileTitle: 'Espera más larga',
  QueuesNoDataHeading: 'Filas',
  QueuesNoDataBodyForAdmin:
    'No tienes filas configuradas. Configura tus filas. ',
  QueuesNoDataBodyForSupervisor:
    'No hay filas configuradas. Habla con tu administrador. ',
  QueuesNoDataLinkLabelForAdmin: 'Agrega fila',
  PendingReservationsOnLogoutNotification:
    'No puedes salir si tienes una tarea pendiente.',
  PendingReservationsOnActivityStateChangeNotification:
    'No puedes actualizar tu actividad si tienes una tarea pendiente.',
  AcceptTaskTooltip: 'Acepar',
  RejectTaskTooltip: 'Rechazar',
  HangupCallTooltip: 'Colgar',
  LeaveCallTooltip: 'Salir',
  MuteCallTooltip: 'Silencio',
  HoldCustomerTooltip: 'En espera',
  UnholdCustomerTooltip: 'Cancelar la espera del cliente',
  HoldAgentTooltip: '{{worker.fullName}} en espera',
  UnholdAgentTooltip: 'Quitar {{worker.fullName}} de espera',
  CancelTransferTooltip: 'Cancelar transferencia',
  KickAgentTooltip: 'Colgar {{worker.fullName}}',
  ColdTransferTooltip: 'Transferencia',
  WarmTransferTooltip: 'Consultar',
  MonitorCallTooltip: 'Llamada de monitoreo',
  LogToFileStartLoggingText:
    '<div><h2>Habilitar registro</h2><ul><li>Cuando habilitas el registro, tu navegador será actualizado y la captura de logs será iniciada. </li><li>Una vez que estés cerca de terminar - para el registro. Todos los logs capturados serán guardados en un archivo de texto.</li></ul></div>',
  LogToFileStartLoggingLabel: 'Actualizar e iniciar',
  LogToFileStopLoggingText:
    '<div><h2>El registro está activo</h2><p>Si repites las acciones y reproduces el problema, para el registro y baja el archivo con logs capturados.</p></div>',
  LogToFileStopLoggingLabel: 'Terminar y descargar',
  ChatOrchestrationAddToChatChannelFailed:
    'Error al agregar al agente al canal de chat. ',
  ChatOrchestrationDeactivateChatChannelFailed:
    'Error al desactivar el canal de chat. ',
  ChatOrchestrationLeaveChatChannelFailed:
    'Error al remover al agente del canal del chat ',
  TeamsViewFilterAction: 'Filtro {{#if filterCount}} ({{filterCount}}) {{/if}}',
  TeamsViewClearSearchAction: 'Limpar resultado de busqueda',
  TeamsViewClearSearchPlaceholder: 'Búsqueda de agente',
  TeamsViewResultsSummaryNoWorkersDisplayed:
    'Ningún agente corresponde a tus critérios. Refina tus resultados por medio de la búsqueda o del filtro. ',
  TeamsViewResultsSummaryMaxWorkersDisplayed:
    'Mostrando el número máximo de agentes. Refina tu búsqueda. ',
  TeamsViewResultsSummaryFilterCTA: 'filtro',
  TeamFiltersPanelTitle: 'Filtros',
  TeamFiltersPanelSingularLabel: 'Mostrando <strong>{{number}}</strong> agente',
  TeamFiltersPanelPluralLabel: 'Mostrando <strong>{{number}}</strong> agentes',
  TeamFiltersApply: 'Aplicar',
  TeamFiltersReset: 'Restablecer',
  InputPlaceHolder: 'Escribe un mensaje',
  TypingIndicator: '{0} está escribiendo... ',
  Connecting: 'Conectando... ',
  Disconnected: 'Conexión perdida',
  Read: 'Leer',
  MessageSendingDisabled: 'El envío de mensajes fue desactivado',
  Today: 'HOY',
  Yesterday: 'AYER',
  MessageCanvasTrayContent:
    '<h6>Terminar</h6><p>Este chat ya no está activo.</h6>',
  MessageCanvasTrayButton: 'INICIAR NOVO CHAT',
  WelcomeMessage: '¡Bienvenido!',
  NotificationMessage: 'Fue imposible ejecutar esta acción: {{message}}',
  Save: 'Guardar',
  Reset: 'Restablecer',
  MessageCharacterCountStatus: '{{currentCharCount}}/{{maxCharCount}}',
  MessageCharacterCountReached:
    'Límite de caracteres alcanzado - {{currentCharCount}}/{{maxCharCount}}',
  SendMessageTooltip: 'Enviar mensaje',
  FieldValidationRequiredField: 'Campo obligatorio',
  FieldValidationInvalidEmail: 'Introduce una dirección válida de email',
  ChatInputUserNotMemberDisabledText: 'No eres un participante de este chat',
  FilterItemAny: 'Cualquier',
  FilterItemAmountSelected: '{{amount}} seleccionado',
  FilterItemOnly: '{{item.label}} solamente',
  TaskLineChatAssigned:
    '{{helper.DurationSinceUpdate}} | {{#if helper.chat.typers.length}} escribiendo... {{else}} {{#if helper.chat.lastMessage}} {{helper.chat.lastMessage.authorName}}: {{helper.chat.lastMessage.Body}} {{else}} Ningún mensaje {{/if}} {{/if}}',
  TaskLineWebChatAssigned:
    "{{#if helper.chat.isCustomerOnline}} {{icon name='GreenIndicator'}} {{else}} {{icon name='greyIndicator'}} {{/if}} {{helper.DurationSinceUpdate}} | {{#if helper.chat.typers.length}} escribiendo... {{else}} {{#if helper.chat.lastMessage}} {{helper.chat.lastMessage.authorName}}: {{helper.chat.lastMessage.Body}} {{else}} Ningún mensaje {{/if}} {{/if}}",
};

export default esMX;
