import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Snackbar as MaterialSnackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

export enum SnackbarType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  autoHideDuration: number;
  message: string;
  type: SnackbarType;
}

export const Snackbar: FC<Props> = (props) => {
  const { isOpen, onClose, autoHideDuration, message, type } = props;
  return (
    <div>
      <MaterialSnackbar
        open={isOpen}
        onClose={onClose}
        autoHideDuration={autoHideDuration}
      >
        <Alert onClose={onClose} severity={type}>
          {message}
        </Alert>
      </MaterialSnackbar>
    </div>
  );
};

Snackbar.defaultProps = {};

Snackbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  type: PropTypes.oneOf([
    SnackbarType.ERROR,
    SnackbarType.WARNING,
    SnackbarType.INFO,
    SnackbarType.SUCCESS,
  ]).isRequired,
};
