/* eslint-disable no-bitwise */
/* eslint-disable prefer-destructuring */

export enum ColorLiminosity {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export function isHexColorLightOrDark(colorParam: any) {
  // Variables for red, green, blue values
  let red;
  let green;
  let blue;
  let color;

  // Check the format of the color, HEX or RGB?
  if (colorParam.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = colorParam.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    red = color[1];
    green = color[2];
    blue = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +`0x${colorParam
      .slice(1)
      .replace(colorParam.length < 5 && /./g, '$&$&')}`;

    red = color >> 16;
    green = (color >> 8) & 255;
    blue = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp && hsp > 127.5) {
    return ColorLiminosity.LIGHT;
  }
  return ColorLiminosity.DARK;
}
