/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Box } from '@material-ui/core';
import { withTaskContext } from '@twilio/flex-ui';
import { Attachment } from './features/Attachment/Attachment';
import { MaximizeMinimize } from './features/MaximizeMinimize/MinimizeMaximize';
import { ListComments } from './features/Comments/CommentsList';

export const Toolbar = withTaskContext((props: any) => {
  const { onChangeTextBoxIsMaximized } = props;
  const taskIncoming = props.task?.attributes?.taskIncoming;
  const workerEmail = props.task?._task?._worker?.attributes?.email;
  const channelSid = props.task?.attributes?.channelSid;

  return (
    <Box display="flex">
      <Box flex="1" />
      <ListComments taskIncoming={taskIncoming} workerEmail={workerEmail} />
      <Attachment channelSid={channelSid} />
      <MaximizeMinimize onChange={onChangeTextBoxIsMaximized} />
    </Box>
  );
});
