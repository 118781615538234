import * as Flex from '@twilio/flex-ui';

export function flexVerifiedNumber(flex: typeof Flex) {
  flex.Actions.addListener('beforeAcceptTask', async (payload) => {
    const { attributes } = payload.task;
    const { REACT_APP_VERIFIED_PHONE_NUMBER } = process.env;

    if (attributes?.to.match(/sip:.*/) && REACT_APP_VERIFIED_PHONE_NUMBER) {
      await payload.task.setAttributes({
        ...attributes,
        to: REACT_APP_VERIFIED_PHONE_NUMBER,
      });
    }
  });
}
