import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_TAGS_ON_TICKET } from './Ticket.Mutations';
import { GET_TICKETS } from './Ticket.Queries';
import {
  updateTagsOnTicketVariables,
  ticketsVariables,
} from '../../__generated_rental__';

export function useTicketsQuery(variables: ticketsVariables) {
  return useQuery(GET_TICKETS, { variables, fetchPolicy: 'network-only' });
}

export function useUpdateTagsOnTicketMutation(
  variables: updateTagsOnTicketVariables
) {
  return useMutation(UPDATE_TAGS_ON_TICKET, { variables });
}
