import styled from 'styled-components';
import { Box as MuiBox } from '@material-ui/core';

export const MessageBox = styled(MuiBox)`
  background-color: #ecedf1;
  padding: 6px 10px 8px 12.5px;
  border-radius: 4px;
`;

export const Avatar = styled(MuiBox)`
  background-color: #ecedf1;
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

export const Name = styled.span`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 2px;
`;

export const AgentEmail = styled.span`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 2px;
`;
