import React from 'react';
import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';
import {
  TagsContainer,
  chatTagsNamespace,
  mergeTags,
  setSelectedTags,
  chatTagsReduce,
  MessageInputEntry,
  withMacros,
  chatMacrosReducers,
  chatMacroNamespace,
  cleanSelectedMacro,
  DriverGoneNotification,
  Toolbar,
} from '@kovi-cx-frontend/flex-chat';

const CustomMessageInput = withMacros<{
  key: string;
  flex: typeof Flex;
  ToolbarComponent: any;
  agentEmail: string;
  agentName: string;
}>(MessageInputEntry);

export function setupChat(
  flex: typeof Flex,
  manager: Manager,
  agentEmail: string,
  agentName: string
) {
  flex.MessageInput.Content.replace(
    <div key="chat">
      <DriverGoneNotification agentEmail={agentEmail} />
      <TagsContainer key="chat-tags" />
      <CustomMessageInput
        key="custom-message-input"
        flex={Flex}
        ToolbarComponent={Toolbar}
        agentEmail={agentEmail}
        agentName={agentName}
      />
    </div>
  );

  if (manager.store.addReducer) {
    manager.store.addReducer(chatMacroNamespace, chatMacrosReducers);

    manager.store.addReducer(chatTagsNamespace, chatTagsReduce);
    manager.store.subscribe(() => {
      const state = manager.store.getState();
      const chatMacroState = state[chatMacroNamespace];
      if (chatMacroState.macros.hasSelectedMacro) {
        const macroTags = chatMacroState.macros.selected?.tags;
        const { selectedTags } = state[chatTagsNamespace];
        const mergedTags = mergeTags(selectedTags, macroTags);
        manager.store.dispatch(cleanSelectedMacro());
        manager.store.dispatch(setSelectedTags(mergedTags));
      }
    });
  }
}
