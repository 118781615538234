import gql from 'graphql-tag';

export const GET_MESSAGES = gql`
  query SupportMessages(
    $page: Int
    $limit: Int
    $filters: supportMessagesFilterInput
  ) {
    supportMessages(limit: $limit, page: $page, filters: $filters) {
      total
      items {
        id
        support_ticket_id
        support_agent_email
        sender
        receiver
        channel
        text
        sent_at {
          date
        }
        created_at {
          date
        }
        updated_at {
          date
        }
      }
    }
  }
`;
