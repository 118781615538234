import React, { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { IconMinimize, IconMaximize } from '@kovi-cx-frontend/components';
import * as Styled from './MinimizeMaximize.Styled';

export interface Props {
  onChange: (isMaximized: boolean) => void;
}

export const MaximizeMinimize: FC<Props> = (props) => {
  const { onChange } = props;
  const [isMaximized, setIsTextFieldMaximized] = useState<boolean>(false);

  const handleChangeSize = () => {
    setIsTextFieldMaximized(!isMaximized);
  };

  useEffect(() => {
    onChange(isMaximized);
  }, [isMaximized]);

  return (
    <Tooltip title={isMaximized ? 'Minimize' : 'Maximize'}>
      <Styled.CustomToolbarButton type="button" onClick={handleChangeSize}>
        {isMaximized}
        {isMaximized ? <IconMinimize /> : <IconMaximize />}
      </Styled.CustomToolbarButton>
    </Tooltip>
  );
};

MaximizeMinimize.propTypes = {
  onChange: PropTypes.func.isRequired,
};
