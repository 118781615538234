import { createSelector } from 'reselect';
import { AppState } from './store';

const getMacros = (state: AppState) => {
  return state['chat-macro'].macros;
};

export const selectMacroMode = createSelector([getMacros], (macros) => {
  return macros.mode;
});

const getFlex = (state: AppState) => {
  return state.flex;
};

export const selectWorker = createSelector([getFlex], (flex: any) => {
  return flex?.worker?.worker?.attributes;
});
