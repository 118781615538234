import { ApolloClient } from '@apollo/client';
import {
  GET_COMMUNICATION,
  GET_COMMUNICATION_ARCHIVED,
} from './Communication.Queries';

export function imperativelyGetCommunication(
  client: ApolloClient<any>,
  customerId: string,
  next?: string | null
): Promise<any> {
  return new Promise((resolve, reject) => {
    const queryPromise = client.query({
      query: GET_COMMUNICATION,
      variables: { filters: { customerId, next } },
      fetchPolicy: 'network-only',
    });
    queryPromise.then(resolve).catch(reject);
  });
}

export function imperativelyGetCommunicationArchived(
  client: ApolloClient<any>,
  messageId: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    const queryPromise = client.query({
      query: GET_COMMUNICATION_ARCHIVED,
      variables: { filters: { messageId } },
      fetchPolicy: 'network-only',
    });
    queryPromise.then(resolve).catch(reject);
  });
}
