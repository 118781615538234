/* eslint-disable indent */
import styled from 'styled-components';
import {
  Badge as MuiBadge,
  BadgeProps as MuiBadgeProps,
} from '@material-ui/core';

interface OwnProps {
  customcolor?: string;
}

type Props = OwnProps & MuiBadgeProps;

export const Badge = styled(MuiBadge)`
  margin: 2px;
  ${(props: Props) => {
    return props.customcolor ? `background-color: ${props.customcolor}` : null;
  }};
` as React.ComponentType<Props>;
