import gql from 'graphql-tag';

const GET_ATENDENCE = gql`
  query atendance(
    $limit: Int
    $offset: Int
    $page: Int
    $filters: SupportTicketsFilterInput!
    $filtersIncludes: JSONObject
    $attributesIncludes: JSONObject
  ) {
    tickets(
      limit: $limit
      offset: $offset
      page: $page
      filters: $filters
      filtersIncludes: $filtersIncludes
      attributesIncludes: $attributesIncludes
    ) {
      limit
      total
      offset
      items {
        id
        external_id
        status
        provider
        origin
        protocol_number
        created_at {
          date
        }
        driver {
          id
          name
        }
        tags {
          id
          name
          color
          SupportTicketsTag {
            origin
            support_agent_email
            is_active
          }
        }
        events {
          id
          kind
          channel
          external_time {
            date
          }
        }
        messages {
          id
          channel
          sender
          receiver
          text
          sent_at {
            date
          }
        }
      }
    }
  }
`;

export default GET_ATENDENCE;
