/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface Option<Value> {
  label: string;
  value: Value;
}

interface Props<OptionValue> {
  onChange: (value: number) => void;
  options: Option<OptionValue>[];
  selectedOptionIndex: number;
}

export function TabBar<OptionValue>(props: Props<OptionValue>) {
  const { onChange, options, selectedOptionIndex } = props;

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    onChange(newValue);
  };

  const renderTabs = () => {
    return options.map((option) => {
      return <Tab key={option.label} label={option.label} />;
    });
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={selectedOptionIndex}
          onChange={handleChange}
        >
          {renderTabs()}
        </Tabs>
      </AppBar>
    </div>
  );
}

TabBar.propTypes = {
  onChange: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  selectedOptionIndex: PropTypes.any.isRequired,
};
