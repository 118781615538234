/* eslint-disable react/forbid-prop-types */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, ListItem, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import {
  GeneratedTypes,
  useCommentsQuery,
} from '@kovi-cx-frontend/apollo-graphql-kovi';
import {
  AlertBox,
  AlertBoxSeverity,
  Chip,
  Progress,
} from '@kovi-cx-frontend/components';
import * as Styled from './Comments.Styled';

export interface CommentsListProps {
  taskIncoming: string;
  onLoading: any;
}

export const CommentsList: FC<CommentsListProps> = (props) => {
  const { taskIncoming, onLoading } = props;

  const commentVariables: GeneratedTypes.ticketsVariables = {
    filters: {
      external_id: taskIncoming,
      order: [['created_at', 'DESC']],
    },
    filtersIncludes: {
      comments: {
        required: false,
      },
    },
    attributesIncludes: {
      comments: [
        'id',
        'support_ticket_id',
        'support_agent_email',
        'comment',
        'is_active',
        'internal',
        'file_comment',
        'created_at',
      ],
    },
  };

  const { data, loading, error } = useCommentsQuery(commentVariables);
  const comments = data?.tickets?.items[0]?.comments || [];
  const ticket = data?.tickets?.items[0]?.id;

  useEffect(() => {
    onLoading(ticket);
  }, [ticket]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        p={2}
      >
        <AlertBox severity={AlertBoxSeverity.ERROR}>
          {`This might be a network or a server error! Check you internet
          connection and retry or contact the`}
          <a href="mailto:cxdev@kovi.com.br">developer team.</a>
        </AlertBox>
      </Box>
    );
  }

  if (comments.length <= 0) {
    return (
      <Box mb={2} margin={2}>
        <Typography variant="body1">Não há comentários</Typography>
      </Box>
    );
  }
  const commentsItems = comments.map((current: any) => {
    return <CommentsListItem comment={current} />;
  });

  return <Box>{commentsItems}</Box>;
};

CommentsList.propTypes = {
  taskIncoming: PropTypes.string.isRequired,
  onLoading: PropTypes.any.isRequired,
};

export interface CommentsListItemProps {
  comment: any;
}

export const CommentsListItem: FC<CommentsListItemProps> = (props) => {
  const { comment } = props;
  const {
    id,
    comment: text,
    created_at: sentAt,
    file_comment: file,
    support_agent_email: sender,
  } = (comment as unknown) as any;
  const sentAtDate = new Date(sentAt.date);
  const formatedSentAtDate = format(sentAtDate, 'dd/MM/yyyy hh:mm:ss');

  return (
    <ListItem component="li" key={id}>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        marginBottom="15px"
      >
        <Grid container>
          <Grid item xs={12}>
            <Styled.CommentPaper elevation={2}>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <Box mb={2} margin={2}>
                    <Typography variant="body1">
                      {sender}
                      <Chip
                        key="interno"
                        label="interno"
                        customcolor="#FFDBE1"
                      />
                    </Typography>
                  </Box>
                  <Box mb={2} margin={2} flexWrap="wrap">
                    <Typography variant="body1">{text}</Typography>
                  </Box>
                  <Box mb={2} margin={2}>
                    <Typography variant="body2">
                      {` ${formatedSentAtDate}`}
                    </Typography>
                  </Box>
                  {file && (
                    <Box mb={2} margin={2}>
                      {file.search(/(.pdf)$/i) > 0 ? (
                        <a href={file}>{file}</a>
                      ) : (
                        <img alt={file} src={file} width="100%" />
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Styled.CommentPaper>
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  );
};

CommentsListItem.defaultProps = {};
CommentsListItem.propTypes = {
  comment: PropTypes.any.isRequired,
};
