/* eslint-disable indent */
import styled from 'styled-components';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@material-ui/core';

import { isHexColorLightOrDark, ColorLiminosity } from './Chip.Utils';

interface OwnProps {
  customcolor?: string;
}

type Props = OwnProps & MuiChipProps;

export const Chip = styled(MuiChip)`
  margin: 2px;
  ${(props: Props) => {
    const backgroundColor = props.customcolor;
    if (backgroundColor) {
      const luminosity = isHexColorLightOrDark(backgroundColor);
      const color =
        luminosity === ColorLiminosity.LIGHT ? '#000000' : '#FFFFFF';
      return {
        backgroundColor,
        color,
      };
    }

    return null;
  }};
` as React.ComponentType<Props>;
