import gql from 'graphql-tag';

const GET_DRIVER = gql`
  query DriverGetProfile($id: ID!) {
    driver(id: $id) {
      id
      name
      bgc_status
      cpf
      email
      gender
      phone_number
      phone_number_verified
      emergency_1_phone_number
      emergency_1_name
      emergency_2_phone_number
      emergency_2_name
      address_street_name
      address_street_no_number
      address_street_number
      address_street_details
      address_neighborhood
      address_city
      address_state
      address_country
      address_postal_code
      address_latlong
      status
      step
      created_at
      updated_at
      father_name
      mother_name
      license_number
      license_state
      license_expired_at
      license_first_issued_at
      license_points
      license_category
      license_has_permission
      license_status
      license_status_detail
      is_overdue
      score {
        kovi_score
        total_transactions
        total_transactions_overdue
        total_transactions_on_time
        avg_transactions_overdue_days
        debit_recurrencies
        debit_fines
        debit_maintenances
        debit_others
        debit_total
      }
      booking {
        car_scheduled_at
        transaction_type
        transaction_amount
        car {
          id
          license_plate
          brand {
            name
          }
          model {
            name
          }
        }
        plan {
          name
        }
      }
    }
  }
`;

export default GET_DRIVER;
