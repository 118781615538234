import { GeneratedTypes } from '@kovi-cx-frontend/apollo-graphql-kovi';
import { Region } from '../interface/region';

export const whichRegion = (
  driverRegion: string,
  taskRegion: string
): Region => {
  if (
    typeof driverRegion === 'undefined' ||
    driverRegion.includes(GeneratedTypes.CxSupportCountry.OT)
  ) {
    return { country: taskRegion };
  }
  return { country: driverRegion };
};
