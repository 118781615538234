/* eslint-disable react/forbid-prop-types */
import React, { FC, RefObject, useState } from 'react';
import PropTypes from 'prop-types';
import * as Flex from '@twilio/flex-ui';
import { useMacrosQuery } from '@kovi-cx-frontend/apollo-graphql-kovi';

import { whichRegion } from '../../helpers/whichRegion';
import { StateToProps, DispatchToProps } from './MessageInput.Container';

import {
  ChatMessageInput,
  SpecialKeyAction,
} from './components/ChatMessageInput/ChatMessageInput';
import { parseMacros } from '../Macros/Macros.Utils';

export interface OwnProps {
  flex: typeof Flex;
  specialKeysActions?: SpecialKeyAction[];
  messageInputRef: RefObject<HTMLTextAreaElement>;
  ToolbarComponent: any;
  channelSid: string;
}
type Props = StateToProps & DispatchToProps & OwnProps;

export const MessageInput: FC<Props> = (props: any) => {
  const [isLoadingSendTyping, setIsLoadingSendTyping] = useState<boolean>(
    false
  );
  const [isTextBoxMaximized, setIsTextBoxMaximized] = useState<boolean>(false);

  const {
    flex,
    specialKeysActions,
    messageInputRef,
    ToolbarComponent,
    setInputMessage,
    message,
    channelSid,
    task,
    agentName,
  } = props;

  function clearInputMessage() {
    setInputMessage('');
  }

  async function sendMessage(body: string) {
    await flex.Actions.invokeAction('SendMessage', {
      body,
      channelSid,
    });

    clearInputMessage();
  }

  async function sendTyping() {
    await flex.Actions.invokeAction('SendTyping', {
      channelSid,
    });
  }

  async function handleMessageInputChange(newMessage: string) {
    setInputMessage(newMessage);
    if (channelSid && !isLoadingSendTyping) {
      setIsLoadingSendTyping(true);
      await sendTyping();
      setIsLoadingSendTyping(true);
    }
  }

  function handleMessageInputSend() {
    if (message && channelSid) {
      sendMessage(message);
      clearInputMessage();
    }
  }

  function handleChangeTextBoxIsMaximized(value: boolean) {
    setIsTextBoxMaximized(value);
  }

  const renderToolbar = () => {
    return (
      <ToolbarComponent
        channelSid={channelSid}
        onChangeTextBoxIsMaximized={handleChangeTextBoxIsMaximized}
      />
    );
  };

  const driverRegion = task?.attributes?.driver?.region?.country;
  const taskRegion = task?.attributes?.country;
  const { data } = useMacrosQuery({
    filters: {
      is_active: true,
      region: whichRegion(driverRegion, taskRegion),
    },
    filtersIncludes: {
      tags: {
        is_active: true,
        required: false,
      },
    },
    attributesIncludes: {
      tags: ['id', 'name', 'color'],
    },
  }); // loading, error

  const macros = data?.macros?.items;
  const parsedMacros = macros && parseMacros(macros);
  const driverName = task?.attributes?.driver?.name || task?.attributes?.name;
  const placeholders = {
    driver_name: driverName || 'driver_name',
    agent_name: agentName || 'agent_name',
  };

  return (
    <ChatMessageInput
      ref={messageInputRef}
      message={message}
      onChange={handleMessageInputChange}
      onSend={handleMessageInputSend}
      specialKeysActions={specialKeysActions}
      toolbar={renderToolbar()}
      isTextBoxMaximized={isTextBoxMaximized}
      macros={parsedMacros}
      placeholders={placeholders}
    />
  );
};

MessageInput.defaultProps = {
  specialKeysActions: undefined,
};

MessageInput.propTypes = {
  flex: PropTypes.any.isRequired,
  specialKeysActions: PropTypes.any,
  channelSid: PropTypes.any.isRequired,
  messageInputRef: PropTypes.any.isRequired,
  setInputMessage: PropTypes.any.isRequired,
  ToolbarComponent: PropTypes.any.isRequired,
  message: PropTypes.string.isRequired,
};
