import { ApolloClient } from '@apollo/client';
import {
  GetTagsVariables,
  createTagVariables,
  updateTagVariables,
} from '../../__generated_rental__';
import { CREATE_TAG, UPDATE_TAG } from './Tags.Mutations';
import { GET_TAGS } from './Tags.Queries';

export function imperativelyQueryTags(
  client: ApolloClient<any>,
  tagsQueryVariables?: GetTagsVariables
) {
  const variables = { filters: {}, ...tagsQueryVariables };
  return client.query({
    query: GET_TAGS,
    variables,
    fetchPolicy: 'network-only',
  });
}

export function imperativelyCreateTag(
  client: ApolloClient<any>,
  variables: createTagVariables,
  refetchQueries?: string[]
) {
  return client.mutate({ mutation: CREATE_TAG, variables, refetchQueries });
}

export function imperativelyUpdateTag(
  client: ApolloClient<any>,
  variables: updateTagVariables,
  refetchQueries?: string[]
) {
  return client.mutate({ mutation: UPDATE_TAG, variables, refetchQueries });
}
