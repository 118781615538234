import React from 'react';
import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';
import {
  TagsContainer,
  chatTagsNamespace,
  mergeTags,
  setSelectedTags,
  chatTagsReduce,
  chatMacrosReducers,
  chatMacroNamespace,
  cleanSelectedMacro,
} from '@kovi-cx-frontend/flex-chat';

export function setupVoice(flex: typeof Flex, manager: Manager) {
  flex.CallCanvas.Content.add(<TagsContainer key="voice" />, {
    sortOrder: -1,
    if: (props) => {
      return props.task.attributes.direction !== 'outbound';
    },
  });
  if (manager.store.addReducer) {
    manager.store.addReducer(chatMacroNamespace, chatMacrosReducers);

    manager.store.addReducer(chatTagsNamespace, chatTagsReduce);
    manager.store.subscribe(() => {
      const state = manager.store.getState();
      const chatMacroState = state[chatMacroNamespace];
      if (chatMacroState.macros.hasSelectedMacro) {
        const macroTags = chatMacroState.macros.selected?.tags;
        const { selectedTags } = state[chatTagsNamespace];
        const mergedTags = mergeTags(selectedTags, macroTags);
        manager.store.dispatch(cleanSelectedMacro());
        manager.store.dispatch(setSelectedTags(mergedTags));
      }
    });
  }
}
