import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const ColorViewerBox = styled(Box)`
  background-color: ${(props) => {
    return props.color;
  }};
  width: 42px;
  height: 24px;
  border-radius: 4px;
`;

export const ColorEmptyBox = styled(Box)`
  width: 42px;
  border-top: 3px solid red;
  transform: rotate(-26deg) translate(0px, 14px);

  &:after {
    content: '';
    display: block;
    width: 42px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid ${grey[400]};
    transform: rotate(26deg) translate(-6px, -12px);
  }
`;
