import React, {
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import { Box } from '@material-ui/core';
import * as Styled from './ChatMessageInput.Styled';

import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import "@webscopeio/react-textarea-autocomplete/style.css";

import { deepFillTemplate, removeUndefineds } from '../../../Macros/Macros.Utils';

export type SpecialKeyAction = {
  code: string;
  action: () => void;
};

const buttonSendText = 'Enviar Mensagem';
const textInputPlaceholder = 'Digite aqui...';

export const ChatMessageInput = (props: any) => {
  const {
    message,
    onChange,
    onSend,
    toolbar,
    isTextBoxMaximized,
    macros,
    placeholders
  } = props;

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    onChange(value);
  };

  const handleButtonSendClick = () => {
    if (message) {
      onSend(message);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && message) {
      onSend(message);
    }
  };

  const Item = ({ entity: { text } }: any) => {
    const resume = text.length > 40 ? `${text.substr(0, 40)}...` : text;
    return (
      <div title={text}>
        {resume}
      </div>
    )
  };

  const styles: any = {
    height: isTextBoxMaximized ? '300px' : '120px',
    marginBottom: '4px',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    border: '1px solid #eeeeee',
    borderTop: 'none',
    color: '#263238',
    backgroundColor: '#fff',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '12px',
    outline: 'none',
    resize: 'none',
    padding: '6px 10px 6px 10px',
    width: '100%'
  }

  const trigger = {
    "/": {
      dataProvider: (token: string) => {
        if (!macros) {
          return []
        }
        return macros
          .map((item: any) => ({ text: item.value, key: item.id , name: item.name}))
          .filter(({ name }: any) => name.toLowerCase().includes(token.toLowerCase()))
          .slice(0, 5)
      },
      component: Item,
      output: ({ text }: any) => removeUndefineds(deepFillTemplate(text.toString(), placeholders))
    }
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end">
      <ReactTextareaAutocomplete
        loadingComponent={() => <span>Loading</span>}
        trigger={trigger}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        style={styles}
        placeholder={textInputPlaceholder}
        value={message || ''}
      />
      {toolbar}
      <Styled.ButtonSend
        type="button"
        className={`send ${!message && 'disabled'}`}
        disabled={!message}
        onClick={handleButtonSendClick}
      >
        {buttonSendText}
      </Styled.ButtonSend>
    </Box>
  );
};
