import 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { ContextProvider, RootContainer, Manager } from '@twilio/flex-ui';
import { KoviApolloProvider } from '@kovi-cx-frontend/apollo-graphql-kovi';
import { ThemeKovi } from '@kovi-cx-frontend/theme-kovi';
import { RecoilRoot } from 'recoil';
import * as Styled from './App.styled';

interface Props {
  manager: Manager;
}

function App(props: Props) {
  const { manager } = props;
  if (!manager) {
    return null;
  }

  return (
    <ThemeKovi>
      <Styled.GlobalStyle />
      <ContextProvider manager={manager}>
        <RecoilRoot>
          <KoviApolloProvider>
            <RootContainer />
          </KoviApolloProvider>
        </RecoilRoot>
      </ContextProvider>
    </ThemeKovi>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
