/* eslint-disable indent */
import styled from 'styled-components';
import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined';
import { grey } from '@material-ui/core/colors';

export const TagsWrapper = styled.div`
  margin-top: 10px;

  // padding-left: 19px;
  // padding-right: 22px;

  // @media (max-width: 1680px) {
  //   padding-left: 16px;
  //   padding-right: 20px;
  // }
  // @media (max-width: 1280px) {
  //   padding-left: 10px;
  //   padding-right: 10px;
  // }
  // @media (max-width: 1024px) {
  //   padding-left: 15px;
  //   padding-right: 18px;
  // }
`;

export const TagsIcon = styled(MoreOutlinedIcon)`
  color: ${grey[500]};
  font-size: 22px;
  margin-top: 4px;
  margin-left: 4px;
`;

export const ButtonSaveWrapper = styled.div`
  & svg {
    color: ${(props) => {
      return props.color || grey[500];
    }};
  }
`;
