import { Action } from './store';
import { Macro } from './Macros.Typing';

const ACTION_SET_MACRO_MODE: string = 'SET_MACRO_MODE';
const ACTION_SET_SELECTED_MACRO: string = 'SET_SELECTED_MACRO';
const ACTION_CLEAN_SELECTED_MACRO: string = 'CLEAN_SELECTED_MACRO';

export type ChannelIdType = string | null;
export type MacroMode = 'STARTS_WITH' | 'CONTAINS' | null;

export interface MacrosState {
  mode: MacroMode;
  selected: Macro | null;
}

const initialState: MacrosState = {
  mode: null,
  selected: null,
};

export const setMacroMode = (mode: MacroMode): Action => {
  return {
    type: ACTION_SET_MACRO_MODE,
    payload: mode,
  };
};

export const setSelectedMacro = (macro: Macro): Action => {
  return {
    type: ACTION_SET_SELECTED_MACRO,
    payload: macro,
  };
};

export const cleanSelectedMacro = (): Action => {
  return {
    type: ACTION_CLEAN_SELECTED_MACRO,
  };
};

export function chatMacroReduce(
  state: MacrosState = initialState,
  action: Action
) {
  const actions = {
    [ACTION_SET_MACRO_MODE]: {
      ...state,
      mode: action.payload,
    },
    [ACTION_SET_SELECTED_MACRO]: {
      ...state,
      selected: action.payload,
      hasSelectedMacro: true,
    },
    [ACTION_CLEAN_SELECTED_MACRO]: {
      ...state,
      hasSelectedMacro: null,
    },
  };
  return actions[action.type] ?? state;
}
