import React, { lazy } from 'react';
import { Box } from '@material-ui/core';
import * as Flex from '@twilio/flex-ui';
import { SuspenseBase } from '../components/SuspenseBase/SuspenseBase';

export const LazyCRMDriver = lazy(() => {
  return import('@kovi-cx-frontend/flex-crm-driver');
});

export function setupCRMDriver(flexInstance: typeof Flex) {
  flexInstance.CRMContainer.Content.replace(
    <Box key="crm-driver" height="calc(100vh - 70px)">
      <SuspenseBase>
        <LazyCRMDriver flexInstance={flexInstance} />
      </SuspenseBase>
    </Box>
  );
}
