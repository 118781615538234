/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { components } from 'react-select';
import * as Styled from './MacroSelectOption.Styled';

export const MacroSelectOption = (props: any) => {
  const { data } = props;
  const splited = data.label?.split('||');
  return (
    <div>
      <components.Option {...props}>
        <Styled.HighlightedLabel>{`${splited[0]} - `}</Styled.HighlightedLabel>
        <span>{splited[1]}</span>
      </components.Option>
    </div>
  );
};
