/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from './DropDownMenu.Styled';

interface Props {
  name: string;
  button: ReactNode;
  children: ReactNode;
}

export const DropDownMenu: FC<Props> = (props) => {
  const { children, button, name } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        role="button"
        aria-controls={name}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {button}
      </div>
      <Menu
        id={name}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
};

DropDownMenu.propTypes = {
  name: PropTypes.string.isRequired,
  button: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
