import React, { ComponentType, createRef } from 'react';
import MacrosContainer from './Macros.Container';

const SLASH_KEYBOARD_CODE = '/';
const BACKSLASH_KEYBOARD_CODE = '\\';

const messageInputRef = createRef();
export const withMacros = <T extends object>(Component: ComponentType<T>) => {
  function handleMacrosDone() {
    (messageInputRef?.current as HTMLElement).focus();
  }

  return (props: T) => {
    return (
      <MacrosContainer
        onDone={handleMacrosDone}
        messageInputRef={messageInputRef}
        renderProps={(
          setMacroStartsWithMode: () => void,
          setMacroContainsMode: () => void
        ) => {
          return (
            <Component
              messageInputRef={messageInputRef}
              specialKeysActions={[
                {
                  code: SLASH_KEYBOARD_CODE,
                  action: setMacroStartsWithMode,
                },
                {
                  code: BACKSLASH_KEYBOARD_CODE,
                  action: setMacroContainsMode,
                },
              ]}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          );
        }}
      />
    );
  };
};
