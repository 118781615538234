import React, { FC, HTMLAttributes, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import * as Styled from './ButtonIconClose.styled';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const ButtonIconClose: FC<Props> = (props) => {
  const { onClick } = props;
  return (
    <Styled.Button type="button" onClick={onClick}>
      <HighlightOffOutlinedIcon />
    </Styled.Button>
  );
};

ButtonIconClose.defaultProps = {
  onClick: undefined,
};

ButtonIconClose.propTypes = {
  onClick: PropTypes.func,
};
