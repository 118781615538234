import gql from 'graphql-tag';

export const GET_COMMENTS = gql`
    query ticket_comments($filters: SupportTicketsFilterInput!, $filtersIncludes: JSONObject, $attributesIncludes: JSONObject) {
        tickets(filters: $filters, filtersIncludes: $filtersIncludes, attributesIncludes: $attributesIncludes) {
            total
            items {
                id
                external_id
                driver_id
                status
                provider
                created_at{
                    date
                }
                comments {
                    id
                    support_ticket_id
                    support_agent_email
                    comment
                    is_active
                    internal
                    file_comment
                    created_at {
                        date
                        format(format: "dd/mm/yyyy")
                    }
                }
            }
        }
    }
`;
