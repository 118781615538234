import React, { FC, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ToolbarButton } from '../../components/ToolbarButton/ToolbarButton.Styled';
import { CommentsModalList } from './Comments.Modal.List';
import * as Styled from './Comments.Styled';

export interface Props {
  taskIncoming: string;
  workerEmail: string;
}

export const ListComments: FC<Props> = (props) => {
  const { taskIncoming, workerEmail } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Tooltip title="Adicionar comentário">
        <ToolbarButton onClick={handleClickOpen}>
          <Styled.IconInsertComment />
        </ToolbarButton>
      </Tooltip>
      <CommentsModalList
        isOpen={isOpen}
        onClose={handleClose}
        taskIncoming={taskIncoming}
        workerEmail={workerEmail}
      />
    </>
  );
};

ListComments.propTypes = {
  taskIncoming: PropTypes.string.isRequired,
  workerEmail: PropTypes.string.isRequired,
};
