import * as Flex from '@twilio/flex-ui';

const DEFAULT_TRANSFER_MODE = 'COLD';

const transferOverride = (payload: any, original: any) => {
  if (!Flex.TaskHelper.isChatBasedTask(payload.task)) {
    return original(payload);
  }

  const mode = payload.options.mode || DEFAULT_TRANSFER_MODE;

  return new Promise((resolve, reject) => {
    const manager = Flex.Manager.getInstance();

    const body = {
      mode,
      taskSid: payload.task.taskSid,
      targetSid: payload.targetSid,
      workerName: manager.user.identity,
      taskchannel: payload.task.channelType,
    };

    fetch(`${process.env.REACT_APP_FUNCTION_URL}/transfer-task`, {
      method: 'POST',
      body: new URLSearchParams(body),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log('Task Transfer Success:', response);

        return response.json();
      })
      .then((data) => {
        if (!data?.transferSuccess) {
          alert('Não é possivel transferir para um agente offline');
        }
        resolve(data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Task Transfer Error:', error);
        reject(error);
      });
  });
};

export default transferOverride;
