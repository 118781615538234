import styled from 'styled-components';

export const LikeDislikeBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
`;

export const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;

  svg {
    font-size: 30px !important;
  }
`;
