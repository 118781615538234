import styled from 'styled-components';
import { ToolbarButton } from '../../components/ToolbarButton/ToolbarButton.Styled';

export const CustomToolbarButton = styled(ToolbarButton)`
  & svg {
    font-size: 28px !important;
    margin-right: -2px;
    margin-top: 2px;
  }
  padding: 0;
`;
