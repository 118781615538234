import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from './locales/enUS';
import ptBR from './locales/ptBR';
import esMX from './locales/esMX';
import { getLanguageFromURL } from './utils';
import { Language, Locale, LocaleStrings } from './typing';

export { getLanguageFromURL, getValueMoneyByLanguage } from './utils';
export { Language, Locale, LocaleStrings };

i18next.use(initReactI18next).init({
  resources: {
    enUS: { translation: { ...enUS } },
    ptBR: { translation: { ...ptBR } },
    esMX: { translation: { ...esMX } },
  },
  lng: getLanguageFromURL(),
  fallbackLng: Language.enUS,
  interpolation: { escapeValue: false },
});

export default i18next;
