/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withTaskContext } from '@twilio/flex-ui';
import { AlertBox, AlertBoxSeverity } from '@kovi-cx-frontend/components';
import i18n, { LocaleStrings } from '@kovi-cx-frontend/i18n';
import { Box } from '@material-ui/core';
import { MessageInputContainer } from './MessageInput.Container';

export const MessageInputEntry = withTaskContext((props: any) => {
  const channelSid = props.task.attributes?.channelSid;

  return channelSid ? (
    <MessageInputContainer {...props} channelSid={channelSid} />
  ) : (
    <Box pt={1} pb={1}>
      <AlertBox severity={AlertBoxSeverity.ERROR}>
        {i18n.t(
          LocaleStrings['There is no channelSid information from the chat']
        )}
      </AlertBox>
    </Box>
  );
});
