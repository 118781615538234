import { AppState as FlexAppState } from '@twilio/flex-ui';
import { combineReducers, Action as ReduxAction } from 'redux';
import { MacrosState, chatMacroReduce } from './Macros.State';
import {
  MessageInputState,
  messageInputReduce,
} from '../MessageInput/MessageInput.State';

export const chatMacroNamespace = 'chat-macro';

export interface Action extends ReduxAction {
  payload?: any;
}

export interface AppState {
  flex: FlexAppState;
  [chatMacroNamespace]: {
    messageInput: MessageInputState;
    macros: MacrosState;
  };
}

export const chatMacrosReducers = combineReducers({
  messageInput: messageInputReduce,
  macros: chatMacroReduce,
});
