/* eslint-disable default-case */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Flex from '@twilio/flex-ui';
import App from './app/App';
import { applyCustomFlexFeatures } from './features/applyCustomFlexFeatures';
import { authenticate } from './auth';
import { setupNewRelic } from './newRelic';

authenticate();
setupNewRelic();

const mountNodeId = 'root';
const mountNodeSelector = `#${mountNodeId}`;
const mountNode: HTMLElement | null = document.getElementById(mountNodeId);

type FlexWindow = typeof window & { appConfig: string };

function renderApp(manager: any) {
  ReactDOM.render(<App manager={manager} />, mountNode);
}

function bootstrapFlexFeatures(manager: any) {
  if (!manager.store.addReducer) {
    // eslint-disable-next-line no-console
    console.error(
      `You need FlexUI > 1.9.0 to use built-in redux; you are currently on ${Flex.VERSION}`
    );
    return;
  }
  applyCustomFlexFeatures(Flex, manager);
}

function handleError(error: string) {
  throw error;
}

window.onload = () => {
  const predefinedConfig = (window as FlexWindow).appConfig || {};
  const configuration = {
    ...predefinedConfig,
  };
  Flex.progress(mountNodeSelector)
    .provideLoginInfo(configuration, mountNodeSelector)
    .then(() => {
      return Flex.Manager.create(configuration);
    })
    .then((manager: any) => {
      bootstrapFlexFeatures(manager);
      return manager;
    })
    .then((manager: any) => {
      return renderApp(manager);
    })
    .catch((error: string) => {
      return handleError(error);
    });
};
