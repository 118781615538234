import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

interface Props {
  onChange: (filter: any) => void;
}

export const CommentsCreate: FC<Props> = (props) => {
  const { onChange } = props;
  const [comment, setComment] = useState<string | undefined>();

  const handleCommentChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { value } = event.target;
    if (value === '') {
      setComment(undefined);
      return;
    }
    setComment(value as string);
  };

  useEffect(() => {
    onChange({ comment });
  }, [comment]);

  return (
    <TextField
      autoFocus
      multiline
      rows={4}
      margin="dense"
      id="name"
      label="Comentário interno"
      type="text"
      variant="outlined"
      fullWidth
      color="secondary"
      value={comment}
      onChange={handleCommentChange}
    />
  );
};

CommentsCreate.defaultProps = {};

CommentsCreate.propTypes = {
  onChange: PropTypes.func.isRequired,
};
