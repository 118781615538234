import styled from 'styled-components';
import { IconInsertComment as IconInsertCommentComponent } from '@kovi-cx-frontend/components';
import { Grid as MuiGrid, Paper as MuiPaper } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const IconInsertComment = styled(IconInsertCommentComponent)`
  font-size: 20px;
  margin-top: 5px;
  cursor: pointer;
`;

export const CommentPaper = styled(MuiPaper)`
  background-color: ${grey[100]};
`;

export const GridReverse = styled(MuiGrid)`
  flex-direction: row-reverse;
  text-align: right;
`;
