import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';

export function voiceCSAT(flex: typeof Flex, manager: Manager) {
  flex.Actions.addListener(
    'beforeHangupCall',
    async (payload, abortFunction) => {
      const body = {
        taskSid: payload?.task?.taskSid,
        callSid: payload?.task?.attributes?.call_sid,
        taskIncoming: payload?.task?.attributes?.taskIncoming,
        token: manager?.store?.getState()?.flex.session?.ssoTokenPayload?.token,
        country: payload?.task?.attributes?.country,
      };

      const httpOpts = {
        method: 'POST',
        body: new URLSearchParams(body),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      };

      await fetch(`${process.env.REACT_APP_FUNCTION_URL}/voice-csat`, httpOpts);

      abortFunction();
    }
  );
}
