/* eslint-disable react/forbid-prop-types */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, Paper } from '@material-ui/core';

import { Rows } from './Rows';
import { Header } from './Header';

interface Props<Column, Row> {
  columns: Column[];
  rows: Row[];
}

export interface RowBase {
  id: string | null;
}
export interface ColumnBase {
  field: string;
  title: string;
  render?: (rowData: any) => ReactNode;
}

export function TableMinimal<Column extends ColumnBase, Row extends RowBase>(
  props: Props<Column, Row>
) {
  const { rows, columns } = props;
  return (
    <TableContainer component={Paper}>
      <Table>
        <Header<Column> columns={columns} />
        <Rows<Column, any> columns={columns} rows={rows} />
      </Table>
    </TableContainer>
  );
}

TableMinimal.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};
