import { AppState as FlexAppState } from '@twilio/flex-ui';
import { combineReducers, Action as ReduxAction } from 'redux';
import { Tag, chatTagsReduce } from './Tags.State';

export const chatTagsNamespace = 'chat-tags';

export interface Action extends ReduxAction {
  payload?: any;
}

export interface AppState {
  flex: FlexAppState;
  [chatTagsNamespace]: {
    selectedTags: Tag[];
  };
}

const reducers = combineReducers({
  tags: chatTagsReduce,
});

export { reducers };
