/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Consolidated support tags
 */
export enum CxLikeDislikeEnum {
  AGENT = "AGENT",
  KOVINHO = "KOVINHO",
}

/**
 * Input to get like dislike
 */
export interface CxLikeDislike {
  external_id?: string | null;
  channel_sid?: string | null;
}

/**
 * Input to update like dislike
 */
export interface CxUpdateLikeDislike {
  external_id?: string | null;
  channel_sid?: string | null;
  from: CxLikeDislikeEnum;
  is_liked: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
