import React from 'react';
import * as Flex from '@twilio/flex-ui';
import i18n, { LocaleStrings } from '@kovi-cx-frontend/i18n';
import { Button } from './components/Button/Button';

export function addTransferButton(flexInstance: typeof Flex) {
  const handleTranferButtonClick = () => {
    flexInstance.Actions.invokeAction('ShowDirectory');
  };
  flexInstance.TaskCanvasHeader.Content.add(
    <Button key="transfer-button" onClick={handleTranferButtonClick}>
      {i18n.t(LocaleStrings.Transfer)}
    </Button>,
    {
      sortOrder: 1,
      if: (props) => {
        const {
          channelDefinition: { capabilities },
        }: { channelDefinition: any } = props;
        const {
          task: { taskStatus },
        }: { task: any } = props;
        return (
          (capabilities.has('Chat') || capabilities.has('Call')) &&
          taskStatus === 'assigned'
        );
      },
    }
  );
}
