import ptBR from './localized/pt-BR';
import esMX from './localized/es-MX';
import enUS from './localized/en-US';
import mapperAUX from './localized/mapper-AUX';

export default {
  'pt-br': ptBR,
  'es-mx': esMX,
  'en-us': enUS,
  'mapper-aux': mapperAUX,
};
export type Locale = 'pt-br' | 'es-mx' | 'en-us' | 'mapper-aux';
export interface LocaleStrings {
  ProtocolNumber?: string;
  InputPlaceHolder?: string;
  TypingIndicator?: string;
  TypingIndicatorMultiple?: string;
  Connecting?: string;
  Disconnected?: string;
  Read?: string;
  MessageSendingDisabled?: string;
  Today?: string;
  Yesterday?: string;
  MessageCanvasTrayContent?: string;
  MessageCanvasTrayButton?: string;
  MediaMessageError?: string;
  WelcomeMessage?: string;
  NotificationMessage?: string;
  Save?: string;
  Reset?: string;
  MessageCharacterCountStatus?: string;
  MessageCharacterCountReached?: string;
  SendMessageTooltip?: string;
  FieldValidationRequiredField?: string;
  FieldValidationInvalidEmail?: string;
  ChatInputUserNotMemberDisabledText?: string;
  FilterItemAny?: string;
  FilterItemAmountSelected?: string;
  FilterItemOnly?: string;
  DialpadNum0Caption?: string;
  DialpadNum0Description?: string;
  DialpadNum1Caption?: string;
  DialpadNum1Description?: string;
  DialpadNum2Caption?: string;
  DialpadNum2Description?: string;
  DialpadNum3Caption?: string;
  DialpadNum3Description?: string;
  DialpadNum4Caption?: string;
  DialpadNum4Description?: string;
  DialpadNum5Caption?: string;
  DialpadNum5Description?: string;
  DialpadNum6Caption?: string;
  DialpadNum6Description?: string;
  DialpadNum7Caption?: string;
  DialpadNum7Description?: string;
  DialpadNum8Caption?: string;
  DialpadNum8Description?: string;
  DialpadNum9Caption?: string;
  DialpadNum9Description?: string;
  DialpadHashCaption?: string;
  DialpadHashDescription?: string;
  DialpadAsteriskCaption?: string;
  DialpadAsteriskDescription?: string;
  NewChatMessageNotificationTitle?: string;
  NewChatMessageNotificationBody?: string;
  MediaMessageClickToOpen?: string;
  SendMediaMessageInvalidType?: string;
  SendMediaMessageInvalidSize?: string;
  AttachFileInvalidType?: string;
  AttachFileInvalidSize?: string;
  DownloadFileInvalidType?: string;
  DownloadFileInvalidSize?: string;
  DownloadFileNotParticipant?: string;
  MediaMessageTransferFailed?: string;
  AttachFileImageTooltip?: string;
  ManageAgentsToQueueBreadCrumb?: string;
  QueueStatsBreadCrumb?: string;
  SetYourStatus?: string;
  LogOut?: string;
  SigningIn?: string;
  SessionExpired?: string;
  NoTasksTitle?: string;
  NoTasks?: string;
  NoTasksHintNotAvailable?: string;
  NoTasksHintAvailable?: string;
  NoCRMConfigured?: string;
  NoCRMConfiguredAction?: string;
  NoCRMTitle?: string;
  NoCRMHint?: string;
  LoginUsername?: string;
  LoginPassword?: string;
  LoginLoading?: string;
  LoginWelcome?: string;
  LoginLogin?: string;
  LoginContinue?: string;
  LoginAgain?: string;
  LoginLaunch?: string;
  LoginTitle?: string;
  LoginErrorDomain?: string;
  LoginErrorDomainDetails?: string;
  LoginErrorSso?: string;
  LoginErrorSsoDetails?: string;
  LoginErrorPopupBlocked?: string;
  LoginErrorPopupBlockedDetails?: string;
  LoginErrorUntrustedDomain?: string;
  LoginErrorUntrustedDomainDetails?: string;
  LoginErrorInvalidCertificate?: string;
  LoginErrorInvalidCertificateDetails?: string;
  LoginErrorGeneric?: string;
  LoginIsAdmin?: string;
  LoginWithTwilio?: string;
  TaskHeaderLeaveCall?: string;
  TaskHeaderEndCall?: string;
  TaskHeaderWrapup?: string;
  TaskHeaderComplete?: string;
  TaskHeaderEndChat?: string;
  TaskHeaderStatusPending?: string;
  TaskHeaderStatusAccepted?: string;
  TaskHeaderWebChatStatusAccepted?: string;
  TaskHeaderGroupCallAccepted?: string;
  TaskHeaderStatusWrapup?: string;
  TaskHeaderStatusCompleted?: string;
  SideNavInsightsAgentsView?: string;
  SideNavInsightsAnalyzeView?: string;
  SideNavInsightsDashboardsView?: string;
  SideNavInsightsInspectionsView?: string;
  SideNavInsightsQuestionnairesView?: string;
  SideNavInsightsSpeechSearchesView?: string;
  SideNavTaskView?: string;
  SideNavTeamsView?: string;
  SideNavAgentDashboard?: string;
  SideNavSupervisorInspections?: string;
  SideNavSupervisorDashboard?: string;
  SideNavSupervisorReports?: string;
  SideNavSettings?: string;
  SideNavQueuesStatsView?: string;
  TaskHeaderLine?: string;
  TaskExtraInfo?: string;
  TaskLineCallIncomingTitle?: string;
  TaskLineCallTransferTitle?: string;
  TaskLineCallEndedTitle?: string;
  TaskLineQueue?: string;
  TaskLineCallDuration?: string;
  TaskLineCallReserved?: string;
  TaskLineCallAssigned?: string;
  TaskLineCallWrapup?: string;
  TaskLineGroupCallAssigned?: string;
  TaskLineCallTransferWorker?: string;
  TaskLineCallTransferWorkerDetail?: string;
  TaskLineCallTransferQueue?: string;
  TaskLineCallTransferQueueDetail?: string;
  TaskLineOutboundCallTitle?: string;
  TaskLineOutboundCallHeader?: string;
  TaskLineOutboundCallDetail?: string;
  TaskLineChatReserved?: string;
  TaskLineChatLineReserved?: string;
  TaskLineChatMessengerReserved?: string;
  TaskLineChatWhatsAppReserved?: string;
  TaskLineSmsReserved?: string;
  TaskLineChatAssigned?: string;
  TaskLineWebChatAssigned?: string;
  TaskLineTitle?: string;
  TaskReserved?: string;
  TaskAssigned?: string;
  TaskWrapup?: string;
  TaskCompleted?: string;
  TaskCanceled?: string;
  TaskFilterAll?: string;
  TaskFilterActive?: string;
  TaskFilterWrapping?: string;
  TaskInfoPanelContent?: string;
  ColumnHeaderAgent?: string;
  ColumnHeaderCalls?: string;
  ColumnHeaderTasks?: string;
  SupervisorUserCardFirstLine?: string;
  SupervisorUserCardSecondLine?: string;
  SupervisorTaskViewContentHeader?: string;
  SupervisorTaskHeaderLine?: string;
  SupervisorTaskLive?: string;
  SupervisorTaskGroupCall?: string;
  SupervisorTaskWrapUp?: string;
  SupervisorTaskCompleted?: string;
  SupervisorTaskCardHeader?: string;
  SupervisorTaskInfoPanelContent?: string;
  LiveCommsMonitoringMessage?: string;
  LiveCommsMonitoringMessageMoreWorkers?: string;
  LiveCommsMonitoringMessageNoWorkers?: string;
  LiveCommsOngoingCallMessage?: string;
  LiveCommsBarReturnToCall?: string;
  LiveCommsBarStopListening?: string;
  LiveCommsBarHold?: string;
  LiveCommsBarUnHold?: string;
  WorkerDirectoryTitle?: string;
  WorkerDirectoryItemFirstLine?: string;
  WorkerDirectoryItemSecondLine?: string;
  WorkerDirectoryAgentsTabLabel?: string;
  WorkerDirectoryQueuesTabLabel?: string;
  WorkerDirectoryQueueItemText?: string;
  WorkerDirectorySearchPlaceholder?: string;
  ChatInputDisabledText?: string;
  WorkerCanvasHeader?: string;
  WorkerCanvasDetailsHeader?: string;
  WorkerCanvasSkillsHeader?: string;
  WorkerSkillLevelInvalid?: string;
  WorkerSkillPleaseSelect?: string;
  WorkerSkillsNoSkills?: string;
  WorkerSkillsSchemaMismatch?: string;
  WorkerSkillsSaved?: string;
  WorkerSkillsError?: string;
  WorkerSkillsReverted?: string;
  ChatWelcomeText?: string;
  UiVersionDeprecated?: string;
  RuntimeLoginLabel?: string;
  RuntimeLoginPlaceholder?: string;
  RuntimeLoginLaunch?: string;
  RuntimeLoginWithTwilio?: string;
  RuntimeLoginIsAdmin?: string;
  RuntimeLoginErrorDomain?: string;
  RuntimeLoginErrorSso?: string;
  TransferFailedNotification?: string;
  TaskCannotBeTransferredNotification?: string;
  SupervisorTaskCanvasHeader?: string;
  TaskTabAgentIncomingLabel?: string;
  TaskTabAgentInfoLabel?: string;
  TaskTabSupervisorOverviewLabel?: string;
  TaskTabSupervisorCallLabel?: string;
  TaskTabSupervisorChatLabel?: string;
  TaskTabSupervisorInfoLabel?: string;
  MicNotAvailableNotification?: string;
  BrowserVoiceDisabledNotification?: string;
  MessageSizeExceeded?: string;
  UserControlWorkerSecondLine?: string;
  UserLoggedOutSuccess?: string;
  CannotChangeOtherWorkerActivity?: string;
  CallCanvasWorkerOnHold?: string;
  CallCanvasUnholdWorker?: string;
  CallParticipantStatusOnHold?: string;
  CallParticipantStatusLive?: string;
  CallParticipantStatusConnecting?: string;
  CallParticipantStatusLeft?: string;
  CallParticipantStatusTransferFailed?: string;
  CallParticipantStatusKickConfirmation?: string;
  LiveCommsIncomingDirectCallTransfer?: string;
  LiveCommsIncomingQueueCallTransfer?: string;
  CallParticipantCustomerName?: string;
  MonitoringFailedNotification?: string;
  PredefinedChatMessageAuthorName?: string;
  PredefinedChatMessageBody?: string;
  AgentStatusAvailable?: string;
  AgentStatusBusy?: string;
  AgentStatusUnavailable?: string;
  AgentStatusOffline?: string;
  ActiveTasksTileTitle?: string;
  WaitingTasksTileTitle?: string;
  AgentsByActivityTileTitle?: string;
  LongestWaitTimeTileTitle?: string;
  QueuesNoDataHeading?: string;
  QueuesNoDataBodyForAdmin?: string;
  QueuesNoDataBodyForSupervisor?: string;
  QueuesNoDataLinkLabelForAdmin?: string;
  PendingReservationsOnLogoutNotification?: string;
  PendingReservationsOnActivityStateChangeNotification?: string;
  AcceptTaskTooltip?: string;
  RejectTaskTooltip?: string;
  HangupCallTooltip?: string;
  LeaveCallTooltip?: string;
  MuteCallTooltip?: string;
  HoldCustomerTooltip?: string;
  UnholdCustomerTooltip?: string;
  HoldAgentTooltip?: string;
  UnholdAgentTooltip?: string;
  CancelTransferTooltip?: string;
  KickAgentTooltip?: string;
  ColdTransferTooltip?: string;
  WarmTransferTooltip?: string;
  MonitorCallTooltip?: string;
  LogToFileStartLoggingText?: string;
  LogToFileStartLoggingLabel?: string;
  LogToFileStopLoggingText?: string;
  LogToFileStopLoggingLabel?: string;
  ChatOrchestrationAddToChatChannelFailed?: string;
  ChatOrchestrationDeactivateChatChannelFailed?: string;
  ChatOrchestrationLeaveChatChannelFailed?: string;
  TeamsViewFilterAction?: string;
  TeamsViewClearSearchAction?: string;
  TeamsViewClearSearchPlaceholder?: string;
  TeamsViewResultsSummaryNoWorkersDisplayed?: string;
  TeamsViewResultsSummaryMaxWorkersDisplayed?: string;
  TeamsViewResultsSummaryFilterCTA?: string;
  TeamFiltersPanelTitle?: string;
  TeamFiltersPanelSingularLabel?: string;
  TeamFiltersPanelPluralLabel?: string;
  TeamFiltersApply?: string;
  TeamFiltersReset?: string;
  OutboundDialerPanelTitle?: string;
  OutboundDialerPanelPhoneInputCaption?: string;
  OutboundDialerPanelQueueSelectCaption?: string;
  OutboundCallFailedNotificationTitle?: string;
  OutboundCallFailedNotificationTitleBusy?: string;
  OutboundCallFailedNotificationTitleNoAnswer?: string;
  OutboundCallFailedNotificationTitleInvalidNumber?: string;
  OutboundCallFailedNotificationAction?: string;
  AudioPlayerDeniedError?: string;
  AudioPlayerInvalidMediaError?: string;
  AudioPlayerGenericError?: string;
  RequestBrowserNotificationPermissions?: string;
  IncomingCallBrowserNotificationTitle?: string;
  IncomingCallBrowserNotificationBody?: string;
  IncomingCallNotificationTitle?: string;
  IncomingCallTransferNotificationTitle?: string;
  IncomingCallTransferBrowserNotificationTitle?: string;
  IncomingCallTransferWorkerBrowserNotificationBody?: string;
  IncomingCallTransferQueueBrowserNotificationBody?: string;
  IncomingNotificationAccept?: string;
  IncomingNotificationReject?: string;
  IncomingChatNotificationTitle?: string;
  IncomingLineNotificationTitle?: string;
  IncomingMessengerNotificationTitle?: string;
  IncomingWhatsAppNotiticationTitle?: string;
  IncomingSmsNotificationTitle?: string;
  IncomingChatBrowserNotificationTitle?: string;
  IncomingLineBrowserNotificationTitle?: string;
  IncomingMessengerBrowserNotificationTitle?: string;
  IncomingWhatsAppBrowserNotiticationTitle?: string;
  IncomingSmsBrowserNotificationTitle?: string;
  IncomingChatBrowserNotificationBody?: string;
  TaskTabHeaderChat?: string;
  TaskTabHeaderCall?: string;
  TaskTabHeaderSMS?: string;
  TaskTabHeaderMessenger?: string;
  TaskTabHeaderLine?: string;
  TaskTabHeaderWhatsApp?: string;
  QueuesStatsHeaderQueueName?: string;
  QueuesStatsHeaderActiveTasks?: string;
  QueuesStatsHeaderWaitingTasks?: string;
  QueuesStatsHeaderLongestWait?: string;
  QueuesStatsHeaderAgentsActivity?: string;
  QueuesStatsHeaderSLA30mins?: string;
  QueuesStatsHeaderHandled30mins?: string;
  QueuesStatsHeaderAbandoned30mins?: string;
  FlexInsightsLoginFailedNotificationTitle?: string;
  FlexInsightsPlayerLoadingSegmentFailed?: string;
  FlexInsightsLoginFailedNotificationAction?: string;
  InputDeviceErrorNotification?: string;
  AnonymousParticipant?: string;
  TaskLineOutboundCallConnecting?: string;
  OutboundErrorGeneral?: string;
  OutboundErrorMissingDestination?: string;
  OutboundErrorMissingQueue?: string;
  NoOutboundCallingWhenOffline?: string;
  QueueAssignmentDialogTitle?: string;
  QueueAssignmentDialogDescription?: string;
  QueueAssignmentDialogCancel?: string;
  QueueAssignmentDialogConfirm?: string;
  QueueAgentsInQueue?: string;
  QueueHeaderTitle?: string;
  QueueHeaderSubtitle?: string;
  QueueHeaderSubtitleAlternative?: string;
  QueueSelectionBanner?: string;
  QueueDeselectAll?: string;
  QueueAdd?: string;
  QueueSuccessBanner?: string;
}
