/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import * as Styled from './TagsSelect.Styled';
import { Tag } from '../../Tags.State';

interface Props {
  options: Tag[];
  selectedOptions: Tag[];
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onChange: (selectedOptions: Tag[]) => void;
  disabled?: boolean;
}

export const TagsSelect: FC<Props> = (props) => {
  const {
    options,
    leftIcon,
    rightIcon,
    selectedOptions,
    onChange,
    disabled,
  } = props;
  const handleChangeSelectedTags = (_event: any, newValue: Tag[]) => {
    onChange(newValue);
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    setAnchorEl,
  } = useAutocomplete({
    id: 'flex-select-tags',
    value: selectedOptions,
    onChange: handleChangeSelectedTags,
    multiple: true,
    options,
    getOptionLabel: (option: any) => {
      return option.name;
    },
    getOptionSelected: (option: any, val: any) => {
      return option.id === val.id;
    },
  });

  return (
    <NoSsr>
      <div>
        <div {...getRootProps()}>
          <Styled.InputWrapper ref={setAnchorEl} disabled={!!disabled}>
            {value.map((option: Tag, index: number) => {
              return (
                <Styled.Tag
                  label={`${option.name}`}
                  disabled={disabled}
                  {...getTagProps({ index })}
                />
              );
            })}
            {leftIcon}
            <input
              {...getInputProps()}
              placeholder={value.length === 0 ? 'Tags' : undefined}
              disabled={disabled}
            />
            <Box
              component="span"
              className="right-icon"
              display="flex"
              alignItems="center"
              mr={1}
              ml={1}
            >
              {rightIcon}
            </Box>
          </Styled.InputWrapper>
        </div>

        {groupedOptions.length > 0 ? (
          <Styled.Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => {
              return (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.name}</span>
                  <CheckIcon fontSize="small" />
                </li>
              );
            })}
          </Styled.Listbox>
        ) : null}
      </div>
    </NoSsr>
  );
};

TagsSelect.defaultProps = {
  disabled: false,
};

TagsSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onChange: PropTypes.any.isRequired,
  leftIcon: PropTypes.any.isRequired,
  rightIcon: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};
