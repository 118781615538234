/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Tooltip } from '@material-ui/core';
import {
  IconAttachFile,
  IconUpload,
  IconDelete,
  Progress,
} from '@kovi-cx-frontend/components';
import { ToolbarButton } from '../../components/ToolbarButton/ToolbarButton.Styled';
import * as Styled from './Attachment.Styled';

import { serviceUploadApi } from './ServiceUoloadApi';

export interface Props {
  onChange: (filter: any) => void;
}

export const AttachmentComment: FC<Props> = (props) => {
  const [file, setFile] = useState<any>(null);
  const [fileUploaded, setFileUploaded] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileSaved, setFileSaved] = useState<boolean>(false);
  const { onChange } = props;
  const inputIdComment = 'upload-file-comment';

  const toBase64 = async (fileUp: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileUp);
      reader.onload = () => {
        return resolve(reader?.result);
      };
      reader.onerror = (error) => {
        return reject(error);
      };
    });
  };

  const handleSendAttachment = async () => {
    try {
      setLoading(true);
      await serviceUploadApi
        .post('/docs/upload-file-comment', {
          file: await toBase64(file),
          filename: file.name,
        })
        .then(() => {
          setLoading(false);
          setFileSaved(true);
        })
        .catch(() => {
          setFile(null);
          setLoading(false);
          setFileSaved(false);
        });
    } catch (error) {
      setFile(null);
    } finally {
      setFileUploaded(null);
    }
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileUploaded(selectedFile);
  };

  useEffect(() => {
    onChange({ file });
  }, [file]);

  const hasValidFile = (fileToCheck: any) => {
    return !!(fileToCheck && fileToCheck.name);
  };

  const renderAttachFileButton = () => {
    return (
      <Tooltip title="Add attachment Comments">
        <ToolbarButton>
          <label htmlFor={inputIdComment}>
            <Styled.FileInput
              type="file"
              id={inputIdComment}
              name={inputIdComment}
              onChange={handleFileChange}
            />
            <IconAttachFile color="primary" cursor="pointer" />
          </label>
        </ToolbarButton>
      </Tooltip>
    );
  };

  const renderSendAttachedFileButton = () => {
    return (
      <>
        <Box display="flex">
          <Typography>{file.name}</Typography>
          {!fileSaved && (
            <>
              <ToolbarButton
                type="button"
                onClick={() => {
                  setFile(null);
                }}
              >
                <IconDelete />
              </ToolbarButton>
              <ToolbarButton onClick={handleSendAttachment}>
                <IconUpload />
              </ToolbarButton>
            </>
          )}
          {loading && fileUploaded && <Progress />}
        </Box>
      </>
    );
  };

  return (
    <>
      {!hasValidFile(file) && renderAttachFileButton()}
      {hasValidFile(file) && renderSendAttachedFileButton()}
    </>
  );
};

AttachmentComment.propTypes = {
  onChange: PropTypes.func.isRequired,
};
