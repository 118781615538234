import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Modal } from '@kovi-cx-frontend/components';
import { CommentsList } from './Comments.List';
import { CreateInternalComments } from './Comments';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  taskIncoming: string;
  workerEmail: string;
}

export const CommentsModalList: FC<Props> = (props) => {
  const { isOpen, onClose, taskIncoming, workerEmail } = props;

  const [ticketId, setTicketId] = useState<string | null>(null);

  const handleGetTicket = (ticket: any) => {
    setTicketId(ticket);
  };

  const content = (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="column"
      width="50vw"
      minWidth="380px"
      height="300px"
      overflow="scroll"
    >
      <Box>
        <CommentsList taskIncoming={taskIncoming} onLoading={handleGetTicket} />
      </Box>
    </Box>
  );

  const actions = (
    <Box display="flex" flexDirection="column">
      <CreateInternalComments
        ticketId={ticketId as string}
        workerEmail={workerEmail}
        onClose={onClose}
      />
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      content={content}
      actions={actions}
    />
  );
};

CommentsModalList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  taskIncoming: PropTypes.string.isRequired,
  workerEmail: PropTypes.string.isRequired,
};
