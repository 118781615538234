import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { selectMessage } from '../MessageInput/MessageInput.Selectors';
import { setInputMessage } from '../MessageInput/MessageInput.State';
import { AppState } from './store';
import { Macros } from './Macros';
import { MacroMode, setMacroMode, setSelectedMacro } from './Macros.State';
import { selectMacroMode, selectWorker } from './Macros.Selectors';
import { Macro } from './Macros.Typing';

export interface StateToProps {
  mode: MacroMode;
  message: string;
  worker: any;
}

export interface DispatchToProps {
  setMacroMode: (mode: MacroMode) => void;
  setInputMessage: (message: string) => void;
  setSelectedMacro: (macro: Macro) => void;
}

const mapStateToProps = (state: AppState): StateToProps => {
  return {
    mode: selectMacroMode(state),
    message: selectMessage(state),
    worker: selectWorker(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchToProps => {
  return {
    setMacroMode: bindActionCreators(setMacroMode, dispatch),
    setInputMessage: bindActionCreators(setInputMessage, dispatch),
    setSelectedMacro: bindActionCreators(setSelectedMacro, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Macros);
