import React, { ReactNode, FC } from 'react';
import PropTypes from 'prop-types';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap as ReactGoogleMap,
} from 'react-google-maps';

export { Marker } from 'react-google-maps';

interface ComposedGoogleMapProps {
  children: ReactNode;
}

const ComposedGoogleMap = withScriptjs(
  withGoogleMap((props: ComposedGoogleMapProps) => {
    const { children } = props;
    return (
      <ReactGoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: -34.397, lng: 150.644 }}
      >
        {children}
      </ReactGoogleMap>
    );
  })
);

ComposedGoogleMap.defaultProps = {
  children: undefined,
};

ComposedGoogleMap.propTypes = {
  children: PropTypes.node,
};

interface GoogleMapProps {
  children: ReactNode;
}

export const GoogleMap: FC<GoogleMapProps> = (props) => {
  const { children } = props;
  const KOVI_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  return (
    <ComposedGoogleMap
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${KOVI_API_KEY}`}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '400px' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    >
      {children}
    </ComposedGoogleMap>
  );
};

GoogleMap.defaultProps = {
  children: undefined,
};

GoogleMap.propTypes = {
  children: PropTypes.node,
};
