import { ApolloClient } from '@apollo/client';
import { createTicketCommentVariables } from '__generated_rental__';
import { CREATE_COMMENTS } from './ticketComments.Mutations';

export function imperativelyCreateComment(
  client: ApolloClient<any>,
  variables: createTicketCommentVariables,
  refetchQueries?: string[]
) {
  return client.mutate({
    mutation: CREATE_COMMENTS,
    variables,
    refetchQueries,
  });
}
