import { ThemeColorsDefinitionCreator, createTheme } from '@twilio/flex-ui';

type Colors = {
  base1?: string;
  base2?: string;
  base3?: string;
  base4?: string;
  base5?: string;
  base6?: string;
  base7?: string;
  base8?: string;
  base9?: string;
  base10?: string;
  base11?: string;
  defaultButtonColor?: string;
  lightTextColor?: string;
  darkTextColor?: string;
  buttonHoverColor?: string;
  tabSelectedColor?: string;
  connectingColor?: string;
  disconnectedColor?: string;
  notificationBackgroundColorInformation?: string;
  notificationBackgroundColorSuccess?: string;
  notificationBackgroundColorError?: string;
  notificationBackgroundColorWarning?: string;
  notificationIconColorError?: string;
  notificationIconColorWarning?: string;
  userAvailableColor?: string;
  userUnavailableColor?: string;
  errorColor?: string;
  errorGlow?: string;
  disabledColor?: string;
  focusColor?: string;
  focusGlow?: string;
};

export const createThemeConfig = () => {
  const predefinedThemeColors = ThemeColorsDefinitionCreator.getPredefined('');
  const customThemeColors: Colors = {
    // Add custom colors here
  };
  const isLightTheme = true;
  const theme = createTheme(
    {
      ...predefinedThemeColors.colors,
      ...customThemeColors,
    },
    isLightTheme
  );

  const themeConfig = {
    ...predefinedThemeColors,
    colors: theme.colors,
    overrides: {
      MainHeader: {
        Container: {
          background: '#22252c',
          color: theme.colors.lightTextColor,
        },
      },
      SideNav: {
        Container: {
          background: '#F2F2F2',
        },
        Button: {
          background: '#F2F2F2',
        },
        Icon: {
          color: theme.colors.darkTextColor,
        },
      },
    },
  };
  return themeConfig;
};
