import { FlexState } from '@twilio/flex-ui';
import { createSelector } from 'reselect';
import { AppState, chatTagsNamespace } from './store';

const getTagState = (state: AppState) => {
  return state[chatTagsNamespace];
};

const getFlexState = (state: FlexState) => {
  return state.flex;
};

export const selectTags = createSelector([getTagState], (tagsState) => {
  return tagsState;
});

export const selectWorker = createSelector([getFlexState], (flexState) => {
  return flexState.worker;
});
