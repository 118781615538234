import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { getGraphQLUrl } from '../config/getGraphQLUrl';
import { createApolloClient } from '../config/client';

interface Props {
  children: ReactNode;
}

export const KoviApolloProvider: FC<Props> = (props) => {
  const { children } = props;
  const [idToken, setIdToken] = useState<string>();
  const [region, setRegion] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const graphQLURLBasedOnEnv = getGraphQLUrl();
  let client;

  useEffect(() => {
    const getIdToken = async () => {
      setIsLoading(true);
      setIsLoading(false);
      setIdToken(window.localStorage.getItem('user/CognitoToken') as string);
      setRegion(window.localStorage.getItem('user/Groups') as string);
    };
    getIdToken();
  }, []);

  if (isLoading) {
    return (
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (idToken) {
    client = createApolloClient(graphQLURLBasedOnEnv, {
      Authorization: idToken,
      region,
    });
  }

  if (client) {
    return <ApolloProvider client={client as any}>{children}</ApolloProvider>;
  }
  return null;
};

KoviApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
