/* eslint-disable no-shadow */
export const styles = {
  control: (styles: any) => {
    return {
      ...styles,
      border: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      padding: '6px 6px 0 6px',
      // outline: '1px solid red',
    };
  },
  indicatorsContainer: (styles: any) => {
    return {
      ...styles,
      display: 'none',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      border: 'none',
      // outline: '1px solid red',
    };
  },
  menuList: (styles: any) => {
    return {
      ...styles,
      height: '100px',
      backgroundColor: 'transparent',
      border: 'none',
      // outline: '1px solid red',
    };
  },
  // control: (styles) => ({
  //   ...styles,
  //   backgroundColor: 'transparent',
  // }),
  // input: (styles) => ({
  //   ...styles,
  //   outline: 'none',
  //   border: 'none',
  // }),
};
