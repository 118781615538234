import React, { FC, HTMLAttributes, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import * as Styled from './ButtonIconCheck.Styled';

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  label?: string;
  disabled?: boolean;
}

export const ButtonIconCheck: FC<Props> = (props) => {
  const { onClick, label, disabled } = props;
  return (
    <Styled.Button type="button" onClick={onClick} disabled={disabled}>
      <PlaylistAddCheckIcon
        style={{
          color: disabled ? grey[600] : red[500],
          cursor: disabled ? 'default' : 'pointer',
        }}
      />
      {label && <Typography component="span">{label}</Typography>}
    </Styled.Button>
  );
};

ButtonIconCheck.defaultProps = {
  onClick: undefined,
  label: undefined,
  disabled: false,
};

ButtonIconCheck.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
