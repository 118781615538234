import { ApolloClient } from '@apollo/client';
import * as GeneratedTypes from '__generated_rental__';
import {
  UPDATE_WORKER_MUTATION,
  UPDATE_ASSIGN_WORKER_TWILIO_MUTATION,
  UPDATE_WORKER_CHANNEL,
} from './Worker.Mutations';

import { GET_WORKERS, GET_WORKER_CHANNEL } from './Worker.Queries';

export function imperativelyGetWorkers(
  client: ApolloClient<any>,
  provider: GeneratedTypes.CxWorkerProvider,
  email?: string | null
): Promise<any> {
  return new Promise((resolve, reject) => {
    const queryPromise = client.query({
      query: GET_WORKERS,
      variables: { provider, email },
      fetchPolicy: 'network-only',
    });
    queryPromise.then(resolve).catch(reject);
  });
}

export function imperativelyGetWorkerChannel(
  client: ApolloClient<any>,
  workerSid: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    const queryPromise = client.query({
      query: GET_WORKER_CHANNEL,
      variables: {
        filters: {
          workerSid,
        },
      },
      fetchPolicy: 'network-only',
    });
    queryPromise.then(resolve).catch(reject);
  });
}

export function imperativelyUpdateWorkerChannel(
  client: ApolloClient<any>,
  sid: string,
  workChannels: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    const queryPromise = client.query({
      query: UPDATE_WORKER_CHANNEL,
      variables: {
        input: {
          sid,
          work_channels: workChannels,
        },
      },
      fetchPolicy: 'network-only',
    });
    queryPromise.then(resolve).catch(reject);
  });
}

export function imperativelyUpdateWorker(
  client: ApolloClient<any>,
  worker: GeneratedTypes.GetWorkers_workers,
  refetchQueries?: string[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    const mutationPromise = client.mutate({
      mutation: UPDATE_WORKER_MUTATION,
      variables: worker,
      refetchQueries,
    });
    mutationPromise
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function imperativelyUpdateWorkerTwilio(
  client: ApolloClient<any>,
  worker: GeneratedTypes.GetWorkers_workers,
  refetchQueries?: string[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    const mutationPromise = client.mutate({
      mutation: UPDATE_ASSIGN_WORKER_TWILIO_MUTATION,
      variables: {
        id: worker.id,
        email: worker.email,
        assign: worker.flex,
      },
      refetchQueries,
    });

    mutationPromise
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
