import styled from 'styled-components';
import { green, red, grey } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import SpeakerNotesOutlinedIconIcon from '@material-ui/icons/SpeakerNotesOutlined';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';

import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';

export const IconEnabled = styled(CheckCircleIcon)`
  color: ${green[600]};
`;

export const IconDisabled = styled(CancelIcon)`
  color: ${red[600]};
`;

export const IconBlocked = styled(BlockIcon)`
  color: ${red[600]};
`;

export const IconAttachFile = styled(AttachmentIcon)`
  color: ${grey[900]};
  cursor: pointer;
`;

export const IconMinimize = styled(ExpandMoreIcon)`
  color: ${grey[900]};
`;

export const IconMaximize = styled(ExpandLessIcon)`
  color: ${grey[900]};
`;

export const IconUpload = styled(PublishIcon)`
  color: ${green[600]};
`;

export const IconDelete = styled(DeleteOutlineIcon)`
  color: ${red[600]};
`;

export const IconInsertComment = styled(InsertCommentOutlinedIcon)`
  color: ${grey[900]};
`;

export const IconReadComments = styled(SpeakerNotesOutlinedIconIcon)`
  color: ${grey[900]};
`;

export const IconLike = styled(ThumbUpIcon)``;

export const IconDislike = styled(ThumbDownIcon)``;

export const IconDislikeOutlined = styled(ThumbDownOutlinedIcon)``;

export const IconLikeOutlined = styled(ThumbUpOutlinedIcon)``;
