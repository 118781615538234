import gql from 'graphql-tag';

export const GET_TAGS = gql`
  query GetTags(
    $limit: Int
    $offset: Int
    $page: Int
    $filters: SupportTagsFilterInput
  ) {
    tags(limit: $limit, offset: $offset, page: $page, filters: $filters) {
      total
      limit
      offset
      items {
        id
        name
        group
        color
        is_active
        region
      }
    }
  }
`;
