import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import * as Styled from './Modal.styled';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
}

export const Modal: FC<Props> = (props) => {
  const { isOpen, onClose, title, content, actions } = props;
  return (
    <div>
      <Styled.Modal open={isOpen} onClose={onClose}>
        <Card elevation={2}>
          {title && <CardHeader title={title} />}
          {content && <CardContent>{content}</CardContent>}
          {actions && <CardActions>{actions}</CardActions>}
        </Card>
      </Styled.Modal>
    </div>
  );
};

Modal.defaultProps = {
  title: undefined,
  content: undefined,
  actions: undefined,
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.node,
};
