/* eslint-disable react/forbid-prop-types */
import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  IconLike,
  IconLikeOutlined,
  IconDislike,
  IconDislikeOutlined,
} from '../Icons/Icons';
import * as Styled from './LikeDislike.Styled';

interface Props {
  isLiked: boolean | null;
  onChange: (isLiked: boolean) => void;
}

export const LikeDislike: FC<Props> = (props) => {
  const { isLiked, onChange } = props;
  const handleClickLike = () => {
    onChange(true);
  };
  const handleClickDislike = () => {
    onChange(false);
  };

  return (
    <Styled.LikeDislikeBox>
      <Box>
        <Styled.Button
          data-testid="like-button"
          type="button"
          onClick={handleClickLike}
        >
          {isLiked && <IconLike data-testid="icon-like" />}
          {!isLiked && <IconLikeOutlined data-testid="icon-like-outlined" />}
        </Styled.Button>
      </Box>

      <Box>
        <Styled.Button
          data-testid="dislike-button"
          type="button"
          onClick={handleClickDislike}
        >
          {!isLiked && isLiked !== null && (
            <IconDislike data-testid="icon-dislike" />
          )}
          {(isLiked || isLiked === null) && (
            <IconDislikeOutlined data-testid="icon-dislike-outlined" />
          )}
        </Styled.Button>
      </Box>
    </Styled.LikeDislikeBox>
  );
};

LikeDislike.defaultProps = {
  isLiked: null,
};

LikeDislike.propTypes = {
  isLiked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
