import Amplify, { Auth } from 'aws-amplify';
import { config } from './config';
import { getUserDisplayName } from './utils';

export function authenticate() {
  Amplify.configure(config);
  Auth.configure();

  Auth.currentAuthenticatedUser()
    .then((user) => {
      const userSession = user.signInUserSession.idToken;

      window.localStorage.setItem('user/CognitoToken', userSession.jwtToken);

      window.localStorage.setItem('user/Id', userSession.payload.sub);
      window.localStorage.setItem(
        'user/Groups',
        userSession.payload['cognito:groups']
      );
      const displayName = getUserDisplayName(user.attributes);

      const attributes = {
        ...user.attributes,
        name: displayName,
      };

      window.localStorage.setItem('user/attributes', attributes);
    })
    .catch(async () => {
      await Auth.federatedSignIn();
    });
}
