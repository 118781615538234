/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BgcStatusEnum {
  APPROVED = "APPROVED",
  APPROVED_MANUALLY = "APPROVED_MANUALLY",
  CHECKING = "CHECKING",
  CNH_REFUSED_MANUALLY = "CNH_REFUSED_MANUALLY",
  DISAPPROVED = "DISAPPROVED",
  DRIVER_HAS_EAR_PERMISSION = "DRIVER_HAS_EAR_PERMISSION",
  DRIVER_LICENSE_EXPIRING = "DRIVER_LICENSE_EXPIRING",
  DRIVER_LICENSE_IMPEDIMENTS = "DRIVER_LICENSE_IMPEDIMENTS",
  DRIVER_LICENSE_PENDING = "DRIVER_LICENSE_PENDING",
  FINANCE_FAILED = "FINANCE_FAILED",
  FINANCE_PENDING = "FINANCE_PENDING",
  FINANCIAL_REFUSED_MANUALLY = "FINANCIAL_REFUSED_MANUALLY",
  HAS_LEGAL_AGE = "HAS_LEGAL_AGE",
  HAS_LEGAL_AGE_MANUALLY = "HAS_LEGAL_AGE_MANUALLY",
  IS_ALIVE = "IS_ALIVE",
  MISSING_FINANCE = "MISSING_FINANCE",
  MISSING_TRANSIT = "MISSING_TRANSIT",
  NO_OPERATION = "NO_OPERATION",
  OUTSIDE_PRACTICE_AREA = "OUTSIDE_PRACTICE_AREA",
  PENDING = "PENDING",
  PROBLEM_ADDRESS = "PROBLEM_ADDRESS",
  PROBLEM_NATIONAL_ID = "PROBLEM_NATIONAL_ID",
  RISK_AREA = "RISK_AREA",
  RISK_AREA_REFUSED_MANUALLY = "RISK_AREA_REFUSED_MANUALLY",
  WAITING_FINANCIAL = "WAITING_FINANCIAL",
}

/**
 * Define country
 */
export enum CxSupportCountry {
  BR = "BR",
  MX = "MX",
  OT = "OT",
}

/**
 * Define activity
 */
export enum CxWorkerActivity {
  AVAILABLE = "AVAILABLE",
  BREAK = "BREAK",
  OFFLINE = "OFFLINE",
}

/**
 * Define channels
 */
export enum CxWorkerChannels {
  CHAT = "CHAT",
  DEFAULT = "DEFAULT",
  SMS = "SMS",
  VIDEO = "VIDEO",
  VOICE = "VOICE",
  WHATSAPP = "WHATSAPP",
}

/**
 * Define country
 */
export enum CxWorkerCountry {
  BR = "BR",
  MX = "MX",
}

/**
 * Define providers
 */
export enum CxWorkerProvider {
  ALL = "ALL",
  FLEX = "FLEX",
  RENTAL = "RENTAL",
}

/**
 * Define roles
 */
export enum CxWorkerRoles {
  ADMIN = "ADMIN",
  AGENT = "AGENT",
  FULL_ACCESS = "FULL_ACCESS",
  SUPERVISOR = "SUPERVISOR",
}

/**
 * Define skills
 */
export enum CxWorkerSkills {
  GROWTH = "GROWTH",
  MAINTENANCE = "MAINTENANCE",
  PAYMENTS = "PAYMENTS",
}

export enum DriverSignupStepEnum {
  APPS_PENDING = "APPS_PENDING",
  BGC_REJECTED = "BGC_REJECTED",
  DELIVERY_NOSHOW = "DELIVERY_NOSHOW",
  DELIVERY_PENDING = "DELIVERY_PENDING",
  DELIVERY_SCHEDULED = "DELIVERY_SCHEDULED",
  DOCS_APPROVED = "DOCS_APPROVED",
  DOCS_CHECKING = "DOCS_CHECKING",
  DOCS_PENDING = "DOCS_PENDING",
  DOCS_REJECTED = "DOCS_REJECTED",
  FINISHED = "FINISHED",
  INVITE_EXPIRED = "INVITE_EXPIRED",
  INVITE_PENDING = "INVITE_PENDING",
  INVITE_SENT = "INVITE_SENT",
  LEAD = "LEAD",
  NO_OPERATION = "NO_OPERATION",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_PENDING_PAYMENT = "PAYMENT_PENDING_PAYMENT",
  PLAN_PENDING = "PLAN_PENDING",
  PROSPECT = "PROSPECT",
  WAITING_LIST = "WAITING_LIST",
}

export enum DriverStatusEnum {
  ACTIVE = "ACTIVE",
  BANNED = "BANNED",
  INACTIVE = "INACTIVE",
  LEAD = "LEAD",
  LEAD_CHURN = "LEAD_CHURN",
  LEAD_PROSPECT = "LEAD_PROSPECT",
  PENDING_BOOKING = "PENDING_BOOKING",
  PENDING_BOOKING_CHURN = "PENDING_BOOKING_CHURN",
  PENDING_CAR = "PENDING_CAR",
  PENDING_CAR_CHURN = "PENDING_CAR_CHURN",
  PENDING_DOCS = "PENDING_DOCS",
  PENDING_DOCS_CHURN = "PENDING_DOCS_CHURN",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PENDING_PAYMENT_CHURN = "PENDING_PAYMENT_CHURN",
  REJECTED = "REJECTED",
  REVIEWING_BOOKING = "REVIEWING_BOOKING",
  REVIEWING_DOCS = "REVIEWING_DOCS",
  SIGNUP = "SIGNUP",
  WAITING_LIST = "WAITING_LIST",
  WAITING_PHONE_VERIFICATION = "WAITING_PHONE_VERIFICATION",
}

/**
 * Subject/Category of macros
 */
export enum SupportMacroSubject {
  BLOCK_CAR = "BLOCK_CAR",
  FINANCE = "FINANCE",
  MAINTENANCE = "MAINTENANCE",
  OTHERS = "OTHERS",
  SCHEDULE = "SCHEDULE",
  SIGNUP = "SIGNUP",
}

/**
 * Possible places where the driver started contact
 */
export enum SupportOriginEnum {
  AJUDA = "AJUDA",
  CHAT = "CHAT",
  DRIVER_CENTRAL = "DRIVER_CENTRAL",
  SIGNUP = "SIGNUP",
  SITE = "SITE",
  VOICE = "VOICE",
}

/**
 * Tags group
 */
export enum SupportTagsGroup {
  ACCESSORIES = "ACCESSORIES",
  BLOCK_CAR = "BLOCK_CAR",
  CLAIMS = "CLAIMS",
  CORRECTIVE = "CORRECTIVE",
  DEVOLUTION = "DEVOLUTION",
  FINES = "FINES",
  GUARANTEE = "GUARANTEE",
  INSURANCE = "INSURANCE",
  MAINTENANCE = "MAINTENANCE",
  MAINTENANCE_CHARGE = "MAINTENANCE_CHARGE",
  OTHERS = "OTHERS",
  PAYMENTS = "PAYMENTS",
  PREVENTIVE = "PREVENTIVE",
  RETENTION = "RETENTION",
  RTO = "RTO",
  SIGNUP = "SIGNUP",
}

/**
 * Channel type when driver open support
 */
export enum SupportTicketEventsChannel {
  CHAT = "CHAT",
  VOICE = "VOICE",
  WA = "WA",
}

/**
 * Consolidated support status
 */
export enum SupportTicketProviderEnum {
  OTHERS = "OTHERS",
  RENTAL = "RENTAL",
  TWILIO = "TWILIO",
  ZD = "ZD",
}

/**
 * Consolidated support status
 */
export enum SupportTicketStatusEnum {
  CLOSED = "CLOSED",
  IN_PROGRESS = "IN_PROGRESS",
  OPENED = "OPENED",
}

/**
 * Consolidated support tags
 */
export enum SupportTicketTagsEnum {
  AGENT = "AGENT",
  MACRO = "MACRO",
  SYSTEM = "SYSTEM",
}

/**
 * Message of macros create
 */
export enum macroMessageEnum {
  MACRO_ALREADY_EXISTS = "MACRO_ALREADY_EXISTS",
  MACRO_CREATED = "MACRO_CREATED",
}

/**
 * Status of macros create
 */
export enum macroStatusEnum {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

/**
 * Message of tags create
 */
export enum tagMessageEnum {
  TAG_ALREADY_EXISTS = "TAG_ALREADY_EXISTS",
  TAG_CREATED = "TAG_CREATED",
}

/**
 * Status of tags create
 */
export enum tagStatusEnum {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

/**
 * Channels available for Worker
 */
export interface ChannelsInput {
  sid?: string | null;
  friendly_name?: string | null;
  capacity?: number | null;
  available?: boolean | null;
}

/**
 * Input by manager capacities worker flex
 */
export interface CxUpdateCapacitiesWorkerInput {
  channel?: CxWorkerChannels | null;
  value?: number | null;
}

/**
 * Filter by get channels from worker flex
 */
export interface CxWorkerChannelFilterInput {
  workerSid: string;
}

/**
 * Channels available for Worker
 */
export interface CxWorkerChannelsAvailableInput {
  id?: string | null;
  enabled?: boolean | null;
  channels?: (ChannelsInput | null)[] | null;
}

/**
 * Input date filter
 */
export interface StartEndDateInput {
  start_date: string;
  end_date: string;
}

/**
 * Input create macros
 */
export interface SupportMacrosCreateInput {
  subject?: SupportMacroSubject | null;
  name?: string | null;
  text?: string | null;
  region?: SupportRegionInput | null;
  created_by?: string | null;
  tags?: (string | null)[] | null;
  is_active?: boolean | null;
}

/**
 * Input delete macros
 */
export interface SupportMacrosDeleteInput {
  id: string;
  deleted_by: string;
}

/**
 * Macros Filter Type
 */
export interface SupportMacrosFilterInput {
  id?: string | null;
  subject?: SupportMacroSubject | null;
  name?: string | null;
  is_active?: boolean | null;
  region?: SupportRegionInput | null;
  country?: CxSupportCountry | null;
  created_at?: StartEndDateInput | null;
  where?: any | null;
  order?: ((string | null)[] | null)[] | null;
}

/**
 * Input create macros
 */
export interface SupportMacrosUpdateInput {
  id: string;
  subject?: SupportMacroSubject | null;
  name?: string | null;
  text?: string | null;
  region?: SupportRegionInput | null;
  updated_by: string;
  is_active?: boolean | null;
  support_tags_to_add?: (string | null)[] | null;
  support_tags_to_remove?: (string | null)[] | null;
}

/**
 * Input macros region
 */
export interface SupportRegionInput {
  city?: string | null;
  state?: string | null;
  country?: string | null;
  group?: string | null;
}

/**
 * Tags create Type
 */
export interface SupportTagsCreateInput {
  name: string;
  group: SupportTagsGroup;
  slug?: string | null;
  color?: string | null;
  is_active?: boolean | null;
  region?: SupportRegionInput | null;
  created_by: string;
}

/**
 * Tags delete Type
 */
export interface SupportTagsDeleteInput {
  id: string;
  deleted_by: string;
}

/**
 * Tags Filter Type
 */
export interface SupportTagsFilterInput {
  id?: string | null;
  group?: SupportTagsGroup | null;
  slug?: string | null;
  color?: string | null;
  is_active?: boolean | null;
  region?: SupportRegionInput | null;
  country?: CxSupportCountry | null;
  created_at?: StartEndDateInput | null;
  where?: any | null;
  order?: ((string | null)[] | null)[] | null;
}

/**
 * Tags Type
 */
export interface SupportTagsUpdateInput {
  id: string;
  name?: string | null;
  group?: SupportTagsGroup | null;
  slug?: string | null;
  color?: string | null;
  is_active?: boolean | null;
  region?: SupportRegionInput | null;
  updated_by: string;
}

export interface SupportTicketsFilterInput {
  id?: string | null;
  protocol_number?: string | null;
  external_id?: string | null;
  status?: (SupportTicketStatusEnum | null)[] | null;
  provider?: (SupportTicketProviderEnum | null)[] | null;
  origin?: SupportOriginEnum | null;
  driver_id?: string | null;
  booking_id?: string | null;
  created_at?: StartEndDateInput | null;
  where?: any | null;
  order?: ((string | null)[] | null)[] | null;
}

/**
 * Input to manage tags for ticket
 */
export interface SupportTicketsTag {
  support_tag_id: string;
  support_agent_email?: string | null;
  origin?: SupportTicketTagsEnum | null;
}

/**
 * Comments Type
 */
export interface SupportTicksCommentsInput {
  support_ticket_id: string;
  support_agent_email: string;
  comment: string;
  is_active?: boolean | null;
  internal?: boolean | null;
  file_comment?: string | null;
}

/**
 * Input by manager tag for ticket
 */
export interface SupportUpdateTagsOnTicket {
  support_ticket_id: string;
  support_tags_to_add?: (SupportTicketsTag | null)[] | null;
  support_tags_to_remove?: (string | null)[] | null;
}

/**
 * Input Channels available for Worker
 */
export interface WorkerChannelsInput {
  sid: string;
  work_channels: (CxWorkerChannelsAvailableInput | null)[];
}

/**
 * support Communication archived Filter Type
 */
export interface supportCommunicationArchivedFilterInput {
  messageId: string;
}

/**
 * support Communication Filter Type
 */
export interface supportCommunicationFilterInput {
  customerId: string;
  limit?: string | null;
  next?: string | null;
}

/**
 * supportMessages Filter Type
 */
export interface supportMessagesFilterInput {
  support_ticket_id: string;
  support_agent_email?: string | null;
  external_id?: string | null;
  channel?: SupportTicketEventsChannel | null;
  sender?: string | null;
  receiver?: string | null;
  sent_at?: string | null;
  where?: any | null;
  order?: ((string | null)[] | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
