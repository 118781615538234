import gql from 'graphql-tag';

export const UPDATE_TAGS_ON_TICKET = gql`
  mutation updateTagsOnTicket(
    $input: SupportUpdateTagsOnTicket
    $attributesIncludes: JSONObject
  ) {
    updateTagsOnTicket(input: $input, attributesIncludes: $attributesIncludes) {
      id
      status
      tags {
        id
        name
        color
      }
    }
  }
`;
