import React, { FC } from 'react';

import { CircularProgress, CircularProgressProps } from '@material-ui/core';

interface Props extends CircularProgressProps {}

export const Progress: FC<Props> = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CircularProgress {...props} />;
};

Progress.propTypes = {};
