import { LocaleStrings } from '../index';

const enUS: LocaleStrings = {
  ProtocolNumber: 'Protocol ID',
  InputPlaceHolder: 'Type message',
  TypingIndicator: '{{name}} is typing … ',
  TypingIndicatorMultiple: '{{count}} people are typing … ',
  Connecting: 'Connecting …',
  Disconnected: 'Connection lost',
  Read: 'Read',
  MessageSendingDisabled: 'Message sending has been disabled',
  Today: 'TODAY',
  Yesterday: 'YESTERDAY',
  MessageCanvasTrayContent: `
          <h6>Complete</h6>
          <p>This chat is no longer active.</p>`,
  MessageCanvasTrayButton: 'START NEW CHAT',
  MediaMessageError: 'Media messages are not supported',
  WelcomeMessage: 'Welcome to customer service',
  NotificationMessage: 'Unable to perform action: {{message}}',
  Save: 'SAVE',
  Reset: 'RESET',
  MessageCharacterCountStatus: '{{currentCharCount}} / {{maxCharCount}}',
  MessageCharacterCountReached:
    'Character count reached - {{currentCharCount}} / {{maxCharCount}}',
  SendMessageTooltip: 'Send Message',
  FieldValidationRequiredField: 'Field required',
  FieldValidationInvalidEmail: 'Please provide a valid email address',
  ChatInputUserNotMemberDisabledText: 'You are not a chat participant',
  FilterItemAny: 'Any',
  FilterItemAmountSelected: '{{amount}} selected',
  FilterItemOnly: '{{item.label}} only',
  DialpadNum0Caption: '0',
  DialpadNum0Description: '+',
  DialpadNum1Caption: '1',
  DialpadNum1Description: '—',
  DialpadNum2Caption: '2',
  DialpadNum2Description: 'ABC',
  DialpadNum3Caption: '3',
  DialpadNum3Description: 'DEF',
  DialpadNum4Caption: '4',
  DialpadNum4Description: 'GHI',
  DialpadNum5Caption: '5',
  DialpadNum5Description: 'JKL',
  DialpadNum6Caption: '6',
  DialpadNum6Description: 'MNO',
  DialpadNum7Caption: '7',
  DialpadNum7Description: 'PQRS',
  DialpadNum8Caption: '8',
  DialpadNum8Description: 'TUV',
  DialpadNum9Caption: '9',
  DialpadNum9Description: 'WXYZ',
  DialpadHashCaption: '#',
  DialpadHashDescription: '',
  DialpadAsteriskCaption: '*',
  DialpadAsteriskDescription: '',
  NewChatMessageNotificationTitle:
    'New message from {{lastMessage.authorName}}',
  NewChatMessageNotificationBody:
    '{{#if lastMessage.body}} {{lastMessage.body}} {{else}} file sent {{/if}}',
  MediaMessageClickToOpen: 'Open file',
  SendMediaMessageInvalidType:
    '{{fileName}} cannot be sent because the file type is unsupported. Please try a different file.',
  SendMediaMessageInvalidSize:
    '{{fileName}} is too large to send. Maximum file size is {{maxFileSize}}.',
  AttachFileInvalidType:
    '{{fileName}} cannot be attached because the file type is unsupported. Please try a different file.',
  AttachFileInvalidSize:
    '{{fileName}} is too large to attach. Maximum file size is {{maxFileSize}}.',
  DownloadFileInvalidType:
    '{{fileName}} cannot be downloaded because the file type is unsupported. Please try a different file.',
  DownloadFileInvalidSize:
    '{{fileName}} is too large to download. Maximum file size is {{maxFileSize}}.',
  DownloadFileNotParticipant:
    'Cannot download file. You are not a chat participant.',
  MediaMessageTransferFailed: 'Sending failed',
  AttachFileImageTooltip: 'Attach file or image',
  ManageAgentsToQueueBreadCrumb: 'Manage Agents',
  QueueStatsBreadCrumb: 'Queue Stats',
  SetYourStatus: 'Set your status',
  LogOut: 'Log out',
  SigningIn: 'Signing in',
  SessionExpired:
    "Your session has expired. <a href='#' onClick='window.location.reload()'>Click here to log in again</a>",
  NoTasksTitle: 'Status',
  NoTasks: 'No active tasks',
  NoTasksHintNotAvailable: 'Change activity state to start receiving tasks.',
  NoTasksHintAvailable: 'You are ready to start receiving tasks.',
  NoCRMConfigured: 'No CRM configured',
  NoCRMConfiguredAction: 'HOW TO CONFIGURE',
  NoCRMTitle: 'CRM config',
  NoCRMHint: 'Our docs can help you get started.',
  LoginUsername: 'User Name',
  LoginPassword: 'Password',
  LoginLoading: 'Loading ...',
  LoginWelcome: 'Hi there, welcome to Flex!',
  LoginLogin: 'LOGIN',
  LoginContinue: 'Continue anyway',
  LoginAgain: 'Login again',
  LoginLaunch: 'Launch',
  LoginTitle: 'Twilio Flex',
  LoginErrorDomain: 'Oops! Your runtime domain is incorrect.',
  LoginErrorDomainDetails:
    "Find your runtime domain <a href='https://www.twilio.com/console/runtime/overview'>here</a>",
  LoginErrorSso: 'Hmm, Single Sign-On has not been configured.',
  LoginErrorSsoDetails:
    "Find out how to configure Single Sign-On <a href='https://www.twilio.com/console/flex/users/single-sign-on'>here</a>",
  LoginErrorPopupBlocked: 'Sorry, pop-ups are blocked.',
  LoginErrorPopupBlockedDetails:
    "<a href='https://support.google.com/chrome/answer/95472'>Find out how to enable pop-ups in your browser</a>",
  LoginErrorUntrustedDomain:
    'Trusted Login has not been enabled for this domain.',
  LoginErrorUntrustedDomainDetails:
    "Find out how to configure Single Sign-On <a href='https://www.twilio.com/docs/flex/configuring-single-sign-and-identity-provider-integration#how-do-i-login-to-a-self-hosted-domain' target='_blank'>here</a>",
  LoginErrorInvalidCertificate: 'Invalid SSO Settings',
  LoginErrorInvalidCertificateDetails:
    "This may be caused by malformed urls or missing/invalid certificates.<br><br>Please reconfigure the <a href='https://www.twilio.com/docs/api/errors/70251' target='_blank'>SSO settings</a> and retry.",
  LoginErrorGeneric:
    "Oops! Well, that didn't work :( Please double check your configuration.",
  LoginIsAdmin: 'Flex Admin?',
  LoginWithTwilio: 'Login with Twilio.',
  TaskHeaderLeaveCall: 'LEAVE CALL',
  TaskHeaderEndCall: 'HANG UP',
  TaskHeaderWrapup: 'WRAP UP',
  TaskHeaderComplete: 'COMPLETE',
  TaskHeaderEndChat: 'END CHAT',
  TaskHeaderStatusPending: '',
  TaskHeaderStatusAccepted: 'Live | {{helper.durationSinceUpdate}}',
  TaskHeaderWebChatStatusAccepted:
    "{{#if helper.chat.isCustomerOnline}} {{icon name='GreenIndicator'}} {{else}} {{icon name='GreyIndicator'}} {{/if}} Live | {{helper.durationSinceUpdate}}",
  TaskHeaderGroupCallAccepted:
    "Live | {{helper.durationSinceUpdate}} | {{icon name='Participant'}} {{task.conference.liveParticipantCount}}",
  TaskHeaderStatusWrapup: 'Wrap up | {{helper.durationSinceUpdate}}',
  TaskHeaderStatusCompleted: 'Completed | {{helper.durationSinceUpdate}}',
  SideNavInsightsAgentsView: 'Agents',
  SideNavInsightsAnalyzeView: 'Analyze',
  SideNavInsightsDashboardsView: 'Dashboards',
  SideNavInsightsInspectionsView: 'Inspections',
  SideNavInsightsQuestionnairesView: 'Questionnaires',
  SideNavInsightsSpeechSearchesView: 'Speech Searches',
  SideNavTaskView: 'Agent Desktop',
  SideNavTeamsView: 'Teams',
  SideNavAgentDashboard: 'Agent Dashboard',
  SideNavSupervisorInspections: 'Supervisor Inspections',
  SideNavSupervisorDashboard: 'Supervisor Dashboard',
  SideNavSupervisorReports: 'Supervisor Reports',
  SideNavSettings: 'Settings',
  SideNavQueuesStatsView: 'Queues Stats',
  TaskHeaderLine: '{{task.defaultFrom}}',
  TaskExtraInfo:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  TaskLineCallIncomingTitle: 'Incoming call',
  TaskLineCallTransferTitle: 'Transfer request',
  TaskLineCallEndedTitle: 'Call ended',
  TaskLineQueue: 'Queue: {{task.queueName}}',
  TaskLineCallDuration: '{{helper.durationSinceUpdate}}',
  TaskLineCallReserved: 'Incoming call from queue {{task.queueName}}',
  TaskLineCallAssigned: 'Live | {{helper.durationSinceUpdate}}',
  TaskLineCallWrapup: 'Wrap up | {{helper.durationSinceUpdate}}',
  TaskLineGroupCallAssigned:
    "Live | {{helper.durationSinceUpdate}} | {{icon name='Participant'}} {{task.conference.liveParticipantCount}}",
  TaskLineCallTransferWorker:
    'Request from {{task.incomingTransferObject.worker.fullName}} (direct)',
  TaskLineCallTransferWorkerDetail:
    'From: {{task.incomingTransferObject.worker.fullName}} (direct)<br />Queue: {{task.queueName}}',
  TaskLineCallTransferQueue:
    'Request from {{task.incomingTransferObject.worker.fullName}} ({{task.incomingTransferObject.queue.name}})',
  TaskLineCallTransferQueueDetail:
    'From: {{task.incomingTransferObject.worker.fullName}}<br />Queue: {{task.queueName}}',
  TaskLineOutboundCallTitle: 'Connecting Call',
  TaskLineOutboundCallHeader: '{{task.attributes.outbound_to}}',
  TaskLineOutboundCallDetail:
    'Your caller ID: {{task.attributes.from}}<br />Queue: {{task.queueName}}',
  TaskLineChatReserved: 'Incoming chat request',
  TaskLineChatLineReserved: 'Incoming Line request',
  TaskLineChatMessengerReserved: 'Incoming Messenger request',
  TaskLineChatWhatsAppReserved: 'Incoming WhatsApp request',
  TaskLineSmsReserved: 'Incoming SMS request',
  TaskLineChatAssigned:
    '{{helper.durationSinceUpdate}} | {{#if helper.chat.typers.length}} typing … {{else}} {{#if helper.chat.lastMessage}} {{helper.chat.lastMessage.authorName}}: {{helper.chat.lastMessage.body}}{{else}}No messages{{/if}}{{/if}}',
  TaskLineWebChatAssigned:
    "{{#if helper.chat.isCustomerOnline}} {{icon name='GreenIndicator'}} {{else}} {{icon name='GreyIndicator'}} {{/if}}{{helper.durationSinceUpdate}} | {{#if helper.chat.typers.length}} typing …{{else}} {{#if helper.chat.lastMessage}} {{helper.chat.lastMessage.authorName}}: {{#if helper.chat.lastMessage.body}}{{helper.chat.lastMessage.body}}{{else}}{{#if helper.chat.lastMessage.error}}<span class='error'>Sending failed</span>{{else}}File sent{{/if}}{{/if}}{{else}}No messages{{/if}}{{/if}}",
  TaskLineTitle: '',
  TaskReserved: 'Reserved',
  TaskAssigned: 'Assigned',
  TaskWrapup: 'In wrap up',
  TaskCompleted: 'Completed',
  TaskCanceled: 'Canceled',
  TaskFilterAll: 'ALL TASKS',
  TaskFilterActive: 'ACTIVE TASKS',
  TaskFilterWrapping: 'TASKS IN WRAP UP',
  TaskInfoPanelContent: `
  <h1>TASK CONTEXT</h1>
  <h2>Task type</h2>
  <p>{{task.channelType}}</p>
  <h2>Task created on</h2>
  <p>{{task.dateCreated}}</p>
  <h2>Task priority</h2>
  <p>{{task.priority}}</p>
  <h2>Task queue</h2>
  <p>{{task.queueName}}</p>
  <h2>Task Sid</h2>
  <p>{{task.taskSid}}</p>
  <h2>Reservation Sid</h2>
  <p>{{task.sid}}</p>
  <hr />
  <h1>CUSTOMER CONTEXT</h1>
  <h2>Customer name / phone number</h2>
  <p>{{task.defaultFrom}}</p>
  <h2>Country</h2>
  <p>{{task.attributes.caller_country}}</p>
  <hr />
  <h1>ADDONS</h1>
  <p>
      No add-ons enabled.
      To expand your experience, visit
  </p>
  <a href="https://www.twilio.com/marketplace/add-ons" target="blank">Twilio Marketplace</a>`,
  ColumnHeaderAgent: 'AGENT',
  ColumnHeaderCalls: 'CALLS',
  ColumnHeaderTasks: 'OTHER TASKS',
  SupervisorUserCardFirstLine: '{{worker.fullName}}',
  SupervisorUserCardSecondLine:
    '{{worker.activityName}} | {{worker.activityDuration}}',
  SupervisorTaskViewContentHeader: '{{worker.fullName}}, {{task.defaultFrom}}',
  SupervisorTaskHeaderLine: '{{task.defaultFrom}}',
  SupervisorTaskLive: '{{helper.durationSinceUpdateShort}}',
  SupervisorTaskGroupCall:
    '{{helper.durationSinceUpdateShort}} | ({{task.conference.liveParticipantCount}})',
  SupervisorTaskWrapUp: 'Wrap up | {{helper.durationSinceUpdateShort}}',
  SupervisorTaskCompleted: 'Completed | {{helper.durationSinceUpdateShort}}',
  SupervisorTaskCardHeader: '{{task.defaultFrom}}',
  SupervisorTaskInfoPanelContent: `
  <h1>TASK CONTEXT</h1>
  <h2>Task type</h2>
  <p>{{task.channelType}}</p>
  <h2>Task created on</h2>
  <p>{{task.dateCreated}}</p>
  <h2>Task priority</h2>
  <p>{{task.priority}}</p>
  <h2>Task queue</h2>
  <p>{{task.queueName}}</p>
  <h2>Task Sid</h2>
  <p>{{task.taskSid}}</p>
  <h2>Reservation Sid</h2>
  <p>{{task.sid}}</p>
  <hr />
  <h1>CUSTOMER CONTEXT</h1>
  <h2>Customer name / phone number</h2>
  <p>{{task.defaultFrom}}</p>
  <h2>Country</h2>
  <p>{{task.attributes.caller_country}}</p>
  <hr />
  <h1>ADDONS</h1>
  <p>
      No add-ons enabled.
      To expand your experience, visit
  </p>
  <a href="https://www.twilio.com/marketplace/add-ons" target="blank">Twilio Marketplace</a>`,
  LiveCommsMonitoringMessage:
    'You are listening to a call with {{task.defaultFrom}} and {{worker.fullName}}',
  LiveCommsMonitoringMessageMoreWorkers:
    'You are listening to a call with {{task.defaultFrom}} and {{workers.length}} workers',
  LiveCommsMonitoringMessageNoWorkers:
    'You are listening to a call with {{task.defaultFrom}}',
  LiveCommsOngoingCallMessage: 'You are on a call with {{task.defaultFrom}}',
  LiveCommsBarReturnToCall: 'RETURN',
  LiveCommsBarStopListening: 'STOP LISTENING',
  LiveCommsBarHold: 'HOLD',
  LiveCommsBarUnHold: 'UNHOLD',
  WorkerDirectoryTitle: 'Transfer',
  WorkerDirectoryItemFirstLine: '{{worker.fullName}}',
  WorkerDirectoryItemSecondLine: '{{worker.activityName}}',
  WorkerDirectoryAgentsTabLabel: 'AGENTS',
  WorkerDirectoryQueuesTabLabel: 'QUEUES',
  WorkerDirectoryQueueItemText: '{{queue.name}}',
  WorkerDirectorySearchPlaceholder: 'Search',
  ChatInputDisabledText: 'Conversation ended',
  WorkerCanvasHeader: 'Profile',
  WorkerCanvasDetailsHeader: 'Agent Details',
  WorkerCanvasSkillsHeader: 'Skills',
  WorkerSkillLevelInvalid:
    'Please select a skill level between {{min}} - {{max}}',
  WorkerSkillPleaseSelect: 'Add skill',
  WorkerSkillsNoSkills: 'No skills attributed',
  WorkerSkillsSchemaMismatch: 'Worker skills schema mismatch',
  WorkerSkillsSaved: 'Changes saved successfully',
  WorkerSkillsError: 'Failed to save changes',
  WorkerSkillsReverted: 'Changes reverted',
  ChatWelcomeText: 'Conversation started with customer',
  UiVersionDeprecated: '{{message}}',
  RuntimeLoginLabel: 'Runtime domain',
  RuntimeLoginPlaceholder: 'Your Runtime Domain',
  RuntimeLoginLaunch: 'Launch',
  RuntimeLoginWithTwilio: 'Login with Twilio.',
  RuntimeLoginIsAdmin: 'Flex Admin?',
  RuntimeLoginErrorDomain:
    'Oops! Well, that didn’t work :( Check your <b>domain</b>.',
  RuntimeLoginErrorSso:
    'Oops! Well, that didn’t work :( Check your <b>SSO</b> setup.',
  TransferFailedNotification: 'Transfer failed: {{reason}}',
  TaskCannotBeTransferredNotification: 'Task cannot be transferred',
  SupervisorTaskCanvasHeader: 'TASK DETAILS',
  TaskTabAgentIncomingLabel: 'Incoming',
  TaskTabAgentInfoLabel: 'Info',
  TaskTabSupervisorOverviewLabel: 'Overview',
  TaskTabSupervisorCallLabel: 'Call',
  TaskTabSupervisorChatLabel: 'Chat',
  TaskTabSupervisorInfoLabel: 'Info',
  MicNotAvailableNotification:
    'Unable to access microphone, please check browser settings.',
  BrowserVoiceDisabledNotification:
    'Call cannot be accepted. Twilio Voice SDK has been disabled.',
  MessageSizeExceeded:
    'The entered character number exceeds the limit - {{currentCharCount}}/{{maxCharCount}}',
  UserControlWorkerSecondLine:
    '{{worker.activityName}} | {{worker.activityDuration}}',
  UserLoggedOutSuccess: 'You have logged out successfully!',
  CannotChangeOtherWorkerActivity:
    'You cannot change an agent’s activity while they have a pending task/reservation.',
  CallCanvasWorkerOnHold: 'You are on hold',
  CallCanvasUnholdWorker: 'Unhold',
  CallParticipantStatusOnHold: 'On Hold',
  CallParticipantStatusLive: 'Live',
  CallParticipantStatusConnecting: 'Calling...',
  CallParticipantStatusLeft: 'Caller left',
  CallParticipantStatusTransferFailed: 'No Answer',
  CallParticipantStatusKickConfirmation: 'Remove from call?',
  LiveCommsIncomingDirectCallTransfer:
    'Request from {{task.incomingTransferObject.worker.fullName}} (direct)',
  LiveCommsIncomingQueueCallTransfer:
    'Request from {{task.incomingTransferObject.worker.fullName}} ({{task.incomingTransferObject.queue.name}})',
  CallParticipantCustomerName: '{{task.defaultFrom}}',
  MonitoringFailedNotification: 'Monitoring attempt has failed',
  PredefinedChatMessageAuthorName: 'Bot',
  PredefinedChatMessageBody: 'Hi there! How can we help you today?',
  AgentStatusAvailable: 'Available',
  AgentStatusBusy: 'Busy',
  AgentStatusUnavailable: 'Unavailable',
  AgentStatusOffline: 'Offline',
  ActiveTasksTileTitle: 'Active tasks',
  WaitingTasksTileTitle: 'Waiting tasks',
  AgentsByActivityTileTitle: 'Agents',
  LongestWaitTimeTileTitle: 'Longest wait',
  QueuesNoDataHeading: 'Queues',
  QueuesNoDataBodyForAdmin:
    'You have no Queues set up. Please configure your Queues.',
  QueuesNoDataBodyForSupervisor:
    'There are no Queues set up. Please contact your Administrator.',
  QueuesNoDataLinkLabelForAdmin: 'Add Queue',
  PendingReservationsOnLogoutNotification:
    'You cannot logout while you have a pending task/reservation',
  PendingReservationsOnActivityStateChangeNotification:
    'You cannot update your activity while you have a pending task/reservation',
  AcceptTaskTooltip: 'Accept',
  RejectTaskTooltip: 'Reject',
  HangupCallTooltip: 'Hang up',
  LeaveCallTooltip: 'Leave',
  MuteCallTooltip: 'Mute',
  HoldCustomerTooltip: 'Hold Customer',
  UnholdCustomerTooltip: 'Unhold Customer',
  HoldAgentTooltip: 'Hold {{worker.fullName}}',
  UnholdAgentTooltip: 'Unhold {{worker.fullName}}',
  CancelTransferTooltip: 'Cancel Consult',
  KickAgentTooltip: 'Hang up {{worker.fullName}}',
  ColdTransferTooltip: 'Transfer',
  WarmTransferTooltip: 'Consult',

  LogToFileStartLoggingLabel: 'Refresh and start',
  LogToFileStopLoggingText: `<div>
      <h2>Logging is active</h2>
      <p>
      If you've repeated your actions and reproduced the issue, stop logging to download the file with captured logs.
      </p>
    </div>`,
  LogToFileStopLoggingLabel: 'Finish and download',
  ChatOrchestrationAddToChatChannelFailed:
    'Failed to add worker to the chat channel.',
  ChatOrchestrationDeactivateChatChannelFailed:
    'Failed to deactivate the chat channel.',
  ChatOrchestrationLeaveChatChannelFailed:
    'Failed to remove worker from the chat channel.',
  TeamsViewFilterAction: 'Filter{{#if filterCount}} ({{filterCount}}){{/if}}',
  TeamsViewClearSearchAction: 'Clear search result',
  TeamsViewClearSearchPlaceholder: 'Search Agents',
  TeamsViewResultsSummaryNoWorkersDisplayed:
    'No agents matched your criteria. Refine results via search or filter.',
  TeamsViewResultsSummaryMaxWorkersDisplayed:
    'Showing maximum number of agents. Refine results via search or ',
  TeamsViewResultsSummaryFilterCTA: 'filter',
  TeamFiltersPanelTitle: 'Filters',
  TeamFiltersPanelSingularLabel: 'Viewing <strong>{{number}}</strong> agent',
  TeamFiltersPanelPluralLabel: 'Viewing <strong>{{number}}</strong> agents',
  TeamFiltersApply: 'Apply',
  TeamFiltersReset: 'Reset',
  OutboundDialerPanelTitle: 'Call',
  OutboundDialerPanelPhoneInputCaption: 'Enter a number',
  OutboundDialerPanelQueueSelectCaption: 'Assign a queue',
  OutboundCallFailedNotificationTitle:
    'Unable to connect your call. Please try again or contact support. [{{reasonCode}}]',
  OutboundCallFailedNotificationTitleBusy:
    'This number is currently busy. Please try again.',
  OutboundCallFailedNotificationTitleNoAnswer:
    'No answer from this number. Please try again.',
  OutboundCallFailedNotificationTitleInvalidNumber:
    'Dialed number is invalid. Please check the number and try again.',
  OutboundCallFailedNotificationAction: 'redial',
  AudioPlayerDeniedError:
    "Cannot play sound, because permissions for playback of media were not given or denied. To find out more about how to fix this error, go to <a href='https://www.twilio.com/docs/flex/audio-player' target='blank'>FlexDocs</a>.",
  AudioPlayerInvalidMediaError:
    'Cannot play sound, because provided media is invalid.',
  AudioPlayerGenericError: 'Error playing media.',
  RequestBrowserNotificationPermissions:
    "We need your permission to <a href='#'>enable browser notifications</a>",
  IncomingCallBrowserNotificationTitle: 'Incoming call',
  IncomingCallBrowserNotificationBody: 'From: {{task.defaultFrom}}',
  IncomingCallNotificationTitle: 'Incoming call from {{task.defaultFrom}}',
  IncomingCallTransferNotificationTitle:
    'Transfer request from {{task.defaultFrom}}',
  IncomingCallTransferBrowserNotificationTitle: 'Transfer request',
  IncomingCallTransferWorkerBrowserNotificationBody:
    'From {{task.incomingTransferObject.worker.fullName}} (direct)',
  IncomingCallTransferQueueBrowserNotificationBody:
    'From {{task.incomingTransferObject.worker.fullName}} ({{task.incomingTransferObject.queue.name}})',
  IncomingNotificationAccept: 'accept',
  IncomingNotificationReject: 'reject',
  IncomingChatNotificationTitle:
    'Incoming chat request from {{task.defaultFrom}}',
  IncomingLineNotificationTitle:
    'Incoming Line request from {{task.defaultFrom}}',
  IncomingMessengerNotificationTitle:
    'Incoming Messenger request from {{task.defaultFrom}}',
  IncomingWhatsAppNotiticationTitle:
    'Incoming WhatsApp request from {{task.defaultFrom}}',
  IncomingSmsNotificationTitle:
    'Incoming SMS request from {{task.defaultFrom}}',
  IncomingChatBrowserNotificationTitle: 'Incoming chat request',
  IncomingLineBrowserNotificationTitle: 'Incoming Line request',
  IncomingMessengerBrowserNotificationTitle: 'Incoming Messenger request',
  IncomingWhatsAppBrowserNotiticationTitle: 'Incoming WhatsApp request',
  IncomingSmsBrowserNotificationTitle: 'Incoming SMS request',
  IncomingChatBrowserNotificationBody: 'From: {{task.defaultFrom}}',
  TaskTabHeaderChat: 'Chat',
  TaskTabHeaderCall: 'Call',
  TaskTabHeaderSMS: 'SMS',
  TaskTabHeaderMessenger: 'Messenger',
  TaskTabHeaderLine: 'Line',
  TaskTabHeaderWhatsApp: 'WhatsApp',
  QueuesStatsHeaderQueueName: 'Queues',
  QueuesStatsHeaderActiveTasks: 'Active tasks',
  QueuesStatsHeaderWaitingTasks: 'Waiting tasks',
  QueuesStatsHeaderLongestWait: 'Longest wait',
  QueuesStatsHeaderAgentsActivity: 'Agents by activity',
  QueuesStatsHeaderSLA30mins: 'SLA (30 min)',
  QueuesStatsHeaderHandled30mins: 'Handled tasks (30 min)',
  QueuesStatsHeaderAbandoned30mins: 'Abandoned tasks (30 min)',
  FlexInsightsLoginFailedNotificationTitle:
    'Flex Insights has been unable to load, please reload Flex to gain access',
  FlexInsightsPlayerLoadingSegmentFailed: 'Failed to fetch conversation data',
  FlexInsightsLoginFailedNotificationAction: 'Reload',
  InputDeviceErrorNotification:
    'Unable to find or initialize audio input device',
  AnonymousParticipant: 'Anonymous',
  TaskLineOutboundCallConnecting: 'Connecting call…',
  OutboundErrorGeneral:
    'Unable to connect your call. Please try again or contact support.',
  OutboundErrorMissingDestination:
    'Unable to connect your call. Please check the number and try again.',
  OutboundErrorMissingQueue:
    'Unable to connect your call. Please select a queue and try again.',
  NoOutboundCallingWhenOffline:
    "To make a call, please change your status from '{{offlineName}}'",
  QueueAssignmentDialogTitle:
    'Do you want to add the selected {{count}} agents to {{queueName}}?',
  QueueAssignmentDialogDescription:
    'This action may affect the SLA of other queues that these agents are assigned to. You can undo this action in the History tab.',
  QueueAssignmentDialogCancel: 'Cancel',
  QueueAssignmentDialogConfirm: 'Confirm',
  QueueAgentsInQueue: 'Agents in {{queueName}} ({{count}})',
  QueueHeaderTitle: 'Manage Agents',
  QueueHeaderSubtitle: 'All Agents in Contact Center',
  QueueHeaderSubtitleAlternative: '{{friendlyName}} Queue Stats',
  QueueSelectionBanner:
    "You have selected <b class='{{bold}}'>{{count}} agents</b> on this page. To confirm click on the Add button.",
  QueueDeselectAll: 'Deselect all',
  QueueAdd: 'Add',
  QueueSuccessBanner:
    "You have assigned <b class='{{bold}}'>{{assigned}} agents</b> to {{queueName}}.",
};

export default enUS;
