/* eslint-disable indent */
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { grey } from '@material-ui/core/colors';

interface InputWrapperProps {
  ref: any;
  disabled: boolean;
}

export const InputWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${grey[300]};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;

  &: > not(.right-icon) {
    opacity: ${(props: InputWrapperProps) => {
      return props.disabled ? 0 : 1;
    }};
  }

  & input {
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    background-color: none;
  }
` as React.ComponentType<InputWrapperProps>;

export const Tag = styled(({ label, onDelete, disabled, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props}>
      <span>{label}</span>
      {disabled ? <CloseIcon /> : <CloseIcon onClick={onDelete} />}
    </div>
  );
})`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: ${(props) => {
      return props.disabled ? 'default' : 'pointer';
    }};
    padding: 4px;
  }
`;

export const Listbox = styled('ul')`
  width: 100%;
  margin: 2px 0 0;
  radius: 0;
  padding: 4 0;
  position: 4 absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;
