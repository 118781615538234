import gql from 'graphql-tag';

export const GET_WORKERS = gql`
  query GetWorkers($provider: CxWorkerProvider!, $email: String) {
    workers(filters: { provider: $provider, email: $email }) {
      name
      email
      id
      activity
      skills
      roles
      countries
      flex
      work_channels {
        id
        enabled
        channels {
          sid
          friendly_name
          available
          capacity
        }
      }
      capacities {
        channel
        value
        label
      }
    }
  }
`;

export const GET_WORKER_CHANNEL = gql`
  query worker($filters: CxWorkerChannelFilterInput!) {
    workerChannel(filters: $filters) {
      sid
      channels {
        sid
        friendly_name
        available
        capacity
      }
    }
  }
`;
