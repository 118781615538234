import React, { useEffect, useState } from 'react';
import { withTaskContext, Actions } from '@twilio/flex-ui';
import { Box } from '@material-ui/core';
import i18n, { LocaleStrings } from '@kovi-cx-frontend/i18n';
import {
  AlertBox,
  AlertBoxSeverity,
  ButtonIconCheck,
  Progress,
} from '@kovi-cx-frontend/components';
import {
  GeneratedTypes,
  useTagsQuery,
  useTicketsQuery,
  useUpdateTagsOnTicketMutation,
} from '@kovi-cx-frontend/apollo-graphql-kovi';
import { whichRegion } from '../../helpers/whichRegion';
import { TagsSelect } from './components/TagsSelect/TagsSelect';
import { Tag } from './Tags.State';
import { compareTags } from './Tag.Utils';
import * as Styled from './Tags.Styled';

function selectFlexCompleteButton() {
  return document.querySelector(
    '.Twilio-Button.Twilio-TaskCanvasHeader-EndButton'
  );
}

function removeEndButton() {
  const originalCompleteButton = selectFlexCompleteButton();
  if (originalCompleteButton) {
    (originalCompleteButton as any).style.display = 'none';
  }
}

function readdEndButton() {
  const originalCompleteButton = selectFlexCompleteButton();
  if (originalCompleteButton) {
    (originalCompleteButton as any).style.display = 'block';
  }
}

function checkSavedIfHasTags(tags: any): boolean {
  if (!tags) return false;
  if (!tags || tags.length === 0) {
    return false;
  }
  return true;
}

function toggleEndAvailability(hasTags: boolean) {
  if (hasTags) {
    readdEndButton();
  } else {
    removeEndButton();
  }
}

export const Tags = withTaskContext((props: any) => {
  const { task } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [savedTags, setSavedTags] = useState<Tag[] | null>(null);
  const driverRegion = task?.attributes?.driver?.region?.country;
  const taskRegion = task?.attributes?.country;
  const [isTagWarningVisible, setIsTagWarningVisible] = useState<boolean>(
    false
  );
  const { setSelectedTags } = props;
  const selectedTags = props.tagsState?.selectedTags;
  const {
    data,
    loading: loadingAvailableTags,
    // error: tagsError,
  } = useTagsQuery({
    filters: {
      is_active: true,
      region: whichRegion(driverRegion, taskRegion),
    },
  });

  Actions.addListener('beforeSelectTask', () => {
    setSelectedTags([]);
  });

  const availableTags = data?.tags?.items || [];

  const handleSelectChange = (newTags: Tag[]) => {
    setSelectedTags(newTags);
  };

  const taskIncoming = props.task?.attributes?.taskIncoming;
  const workerEmail = props.worker?.attributes?.email;

  const {
    data: ticketData,
    loading: loadingTicket,
    // error: ticketError,
  } = useTicketsQuery({
    filters: {
      external_id: taskIncoming,
      status: [
        GeneratedTypes.SupportTicketStatusEnum.OPENED,
        GeneratedTypes.SupportTicketStatusEnum.IN_PROGRESS,
      ],
    },
    filtersIncludes: {
      tags: {
        is_active: 1,
        required: false,
        through: { is_active: 1 },
      },
    },
    attributesIncludes: {
      tags: ['id', 'name', 'color'],
    },
  });

  const ticketItems = ticketData?.tickets?.items;
  const currentTicket = Array.isArray(ticketItems) && ticketItems[0];
  const currentTicketTags = currentTicket?.tags;

  useEffect(() => {
    if (Array.isArray(currentTicketTags)) {
      setSelectedTags(currentTicketTags);
      setSavedTags(currentTicketTags);
    }
  }, [currentTicketTags]);

  const tagsToAdd =
    selectedTags
      ?.filter((updatedTag: any) => {
        return !savedTags?.find((savedTag: any) => {
          return savedTag.id === updatedTag.id;
        });
      })
      .map((updatedTag: any) => {
        return {
          support_tag_id: updatedTag.id,
          origin: 'SYSTEM',
          support_agent_email: workerEmail,
        };
      }) || [];

  const tagsToRemove =
    savedTags
      ?.filter((savedTag: any) => {
        return !selectedTags?.find((updatedTag: any) => {
          return updatedTag.id === savedTag.id;
        });
      })
      .map((savedTag: any) => {
        return savedTag.id;
      }) || [];

  const [
    updateTagsOnTicket,
    updateTagsOnTicketResult,
  ] = useUpdateTagsOnTicketMutation({
    input: {
      support_ticket_id: currentTicket?.id,
      support_tags_to_add: tagsToAdd,
      support_tags_to_remove: tagsToRemove,
    },
    attributesIncludes: {
      tags: ['id', 'name', 'color'],
    },
  });

  const {
    data: updateTagsOnTicketData,
    loading: loadingUpdateTagsOnTicket,
    // error: updateTagsOnTicketError,
  } = updateTagsOnTicketResult;

  const updatedTags = updateTagsOnTicketData?.updateTagsOnTicket[0]?.tags;
  useEffect(() => {
    if (Array.isArray(updatedTags)) {
      setSavedTags(updatedTags);
    }
  }, [updatedTags]);

  const handleSaveTagsButtonClick = () => {
    updateTagsOnTicket();
  };

  const isSaved =
    savedTags && selectedTags ? compareTags(savedTags, selectedTags) : true;

  const saveButtonIcon = (
    <Styled.ButtonSaveWrapper>
      <ButtonIconCheck onClick={handleSaveTagsButtonClick} disabled={isSaved} />
    </Styled.ButtonSaveWrapper>
  );

  const loadingIcon = <Progress size={18} />;

  useEffect(() => {
    const loading =
      loadingAvailableTags || loadingTicket || loadingUpdateTagsOnTicket;
    setIsLoading(loading);
  }, [loadingAvailableTags, loadingTicket, loadingUpdateTagsOnTicket]);

  const hasSavedTags = checkSavedIfHasTags(savedTags);
  const isWrappingTask =
    task.status === 'wrapping' && task.taskStatus === 'wrapping';

  if (
    isWrappingTask &&
    !['whatsapp', 'voice'].includes(task?.attributes?.channelType)
  ) {
    toggleEndAvailability(hasSavedTags);
  } else {
    readdEndButton();
  }

  const tagMessage: string = i18n.t(
    LocaleStrings['At least one tag must be selected!']
  );

  useEffect(() => {
    if (hasSavedTags) {
      setIsTagWarningVisible(false);
    } else {
      setIsTagWarningVisible(true);
    }
  }, [hasSavedTags]);

  const skills = task?.attributes?.skills;
  const skillsSplit = skills.split(':');
  const skillProduct = skillsSplit[3];

  if (!['INSURANCE'].includes(skillProduct)) {
    return (
      <Styled.TagsWrapper>
        {isWrappingTask && isTagWarningVisible && (
          <Box pb={1}>
            <AlertBox severity={AlertBoxSeverity.WARNING} showTitle={false}>
              {tagMessage}
            </AlertBox>
          </Box>
        )}
        <TagsSelect
          disabled={isLoading}
          options={availableTags}
          selectedOptions={selectedTags}
          onChange={handleSelectChange}
          leftIcon={<Styled.TagsIcon />}
          rightIcon={isLoading ? loadingIcon : saveButtonIcon}
        />
      </Styled.TagsWrapper>
    );
  }
  return null;
});
