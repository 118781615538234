import styled from 'styled-components';
import {
  FormControl as MuiFormControl,
  Chip as MuiChip,
} from '@material-ui/core';

export const FormControl = styled(MuiFormControl)`
  width: 100%;
`;

export const Chip = styled(MuiChip)`
  height: 18px;
  font-size: 12px;
`;
