// Safety for available languages
export enum Language {
  'enUS' = 'enUS',
  'ptBR' = 'ptBR',
  'esMX' = 'esMX',
}

// Safety for the locale files
export type Locale = {
  CommunicationTableHeaderOne: string;
  CommunicationTableHeaderTwo: string;
  CommunicationTableHeaderThree: string;
  CommunicationTableHeaderFour: string;
  CommunicationTableHeaderFive: string;
  CommunicationTableFooterAmount: string;
  CommunicationStatusOpen: string;
  CommunicationStatusSend: string;
  CommunicationStatusDelivered: string;
  CommunicationStatusFail: string;
  CommunicationButtonLoadMore: string;
  CommunicationButtonClose: string;
  CommunicationArchivedFrom: string;
  CommunicationArchivedTo: string;
  CommunicationArchivedSubject: string;
  Communication: string;
  ProtocolNumber: string;
  Attendance: string;
  Driver: string;
  Payments: string;
  Vehicles: string;
  Registration: string;
  Resolved: string;
  NotResolved: string;
  Open: string;
  'Initial date': string;
  'Final date': string;
  Status: string;
  Provider: string;
  Tags: string;
  Queues: string;
  Sent: string;
  'Joined on': string;
  RI: string;
  Email: string;
  'Main phone': string;
  'Emergency phone 1': string;
  'Emergency phone 2': string;
  DriverLicense: string;
  'Driver License Number': string;
  'Driver License Points': string;
  'Expiration Date': string;
  'Has EAR': string;
  Situation: string;
  'Emission State': string;
  'Driver License Time': string;
  Category: string;
  Plan: string;
  ActivePlan: string;
  NotPlan: string;
  Value: string;
  Next: string;
  'Payment Day': string;
  Yes: string;
  No: string;
  Address: string;
  'Transactions paid on time': string;
  'Transactions paid in overdue': string;
  'Probability of delay': string;
  'Average delay': string;
  day: string;
  'Total of invoices pending': string;
  'Total of fines pending': string;
  'Total of maintenances pending': string;
  'Total of others pending': string;
  'Total of debits pending': string;
  Name: string;
  Text: string;
  Subject: string;
  Edit: string;
  Delete: string;
  row: string;
  row_plural: string;
  of: string;
  selected: string;
  'First Page': string;
  'Previous Page': string;
  'Next Page': string;
  'Last Page': string;
  Actions: string;
  'No records': string;
  Search: string;
  Filter: string;
  Add: string;
  Save: string;
  Cancel: string;
  'Macros Management': string;
  'Tags Management': string;
  Description: string;
  Tag: string;
  Enabled: string;
  'Full Name': string;
  SID: string;
  Workspace: string;
  Activity: string;
  Languages: string;
  Skills: string;
  Levels: string;
  'Disabled Skills': string;
  'Disabled Levels': string;
  Roles: string;
  'Workers Management': string;
  'Error loading Workers list': string;
  'Error editing Worker': string;
  'Error deleting Worker': string;
  'Error adding Worker': string;
  Back: string;
  Workers: string;
  Macros: string;
  Slug: string;
  Group: string;
  'Block Car': string;
  Finance: string;
  Maintenance: string;
  Others: string;
  Schedule: string;
  Signup: string;
  Color: string;
  Country: string;
  Active: string;
  'No Driver Task selected!': string;
  'Validation error': string;
  'Sent through': string;
  'Task wrap up': string;
  'Task created': string;
  'Task update': string;
  'Task complete': string;
  'Task queue entered': string;
  'Reservation accepted': string;
  'Reservation complete': string;
  'Reservation created': string;
  Complete: string;
  'This user is not yet a customer There is no information to display!': string;
  'This might be a network or a server error Check you internet connection and retry or contact the': string;
  'developer team': string;
  'Driver not found!': string;
  Error: string;
  Warning: string;
  Info: string;
  Success: string;
  Transfer: string;
  MACRO_ALREADY_EXISTS: string;
  Activate: string;
  'Do you want to activate the macro named': string;
  REQUIRED_ATTRIBUTES: string;
  Inactive: string;
  'Do you want to activate the tag named': string;
  TAG_ALREADY_EXISTS: string;
  'The tag named': string;
  'At least one tag must be selected!': string;
  'Fail to find tags': string;
  'The macro named': string;
  'is alread active': string;
  Ok: string;
  'There is no channelSid information from the chat': string;
  Accessories: string;
  Claims: string;
  Devolution: string;
  Fines: string;
  Guarantee: string;
  Insurance: string;
  Corrective: string;
  Preventive: string;
  Retention: string;
  RTO: string;
  'Maintenance Charge': string;
};

// Offer a way to code using hint it with LocaleString. and choose
export enum LocaleStrings {
  CommunicationTableHeaderOne = 'CommunicationTableHeaderOne',
  CommunicationTableHeaderTwo = 'CommunicationTableHeaderTwo',
  CommunicationTableHeaderThree = 'CommunicationTableHeaderThree',
  CommunicationTableHeaderFour = 'CommunicationTableHeaderFour',
  CommunicationTableHeaderFive = 'CommunicationTableHeaderFive',
  CommunicationTableFooterAmount = 'CommunicationTableFooterAmount',
  CommunicationStatusOpen = 'CommunicationStatusOpen',
  CommunicationStatusSend = 'CommunicationStatusSend',
  CommunicationStatusDelivered = 'CommunicationStatusDelivered',
  CommunicationStatusFail = 'CommunicationStatusFail',
  CommunicationButtonLoadMore = 'CommunicationButtonLoadMore',
  CommunicationButtonClose = 'CommunicationButtonClose',
  CommunicationArchivedFrom = 'CommunicationArchivedFrom',
  CommunicationArchivedTo = 'CommunicationArchivedTo',
  CommunicationArchivedSubject = 'CommunicationArchivedSubject',
  Communication = 'Communication',
  ProtocolNumber = 'ProtocolNumber',
  Attendance = 'Attendance',
  Driver = 'Driver',
  Payments = 'Payments',
  Vehicles = 'Vehicles',
  Registration = 'Registration',
  Resolved = 'Resolved',
  NotResolved = 'NotResolved',
  Open = 'Open',
  'Initial date' = 'Initial date',
  'Final date' = 'Final date',
  Status = 'Status',
  Provider = 'Provider',
  Tags = 'Tags',
  Queues = 'Queues',
  Sent = 'Sent',
  'Joined on' = 'Joined on',
  RI = 'RI',
  Email = 'Email',
  'Main phone' = 'Main phone',
  'Emergency phone 1' = 'Emergency phone 1',
  'Emergency phone 2' = 'Emergency phone 2',
  'DriverLicense' = 'DriverLicense',
  'Driver License Number' = 'Driver License Number',
  'Driver License Points' = 'Driver License Points',
  'Expiration Date' = 'Expiration Date',
  'Has EAR' = 'Has EAR',
  Situation = 'Situation',
  'Emission State' = 'Emission State',
  'Driver License Time' = 'Driver License Time',
  Category = 'Category',
  Plan = 'Plan',
  ActivePlan = 'ActivePlan',
  NotPlan = 'NotPlan',
  Value = 'Value',
  Next = 'Next',
  'Payment Day' = 'Payment Day',
  Yes = 'Yes',
  No = 'No',
  Address = 'Address',
  'Transactions paid on time' = 'Transactions paid on time',
  'Transactions paid in overdue' = 'Transactions paid in overdue',
  'Probability of delay' = 'Probability of delay',
  'Average delay' = 'Average delay',
  'day' = 'day',
  'Total of invoices pending' = 'Total of invoices pending',
  'Total of fines pending' = 'Total of fines pending',
  'Total of maintenances pending' = 'Total of maintenances pending',
  'Total of others pending' = 'Total of others pending',
  'Total of debits pending' = 'Total of debits pending',
  Name = 'Name',
  Text = 'Text',
  Subject = 'Subject',
  Edit = 'Edit',
  Delete = 'Delete',
  row = 'row',
  row_plural = 'row_plural',
  of = 'of',
  selected = 'selected',
  'First Page' = 'First Page',
  'Previous Page' = 'Previous Page',
  'Next Page' = 'Next Page',
  'Last Page' = 'Last Page',
  Actions = 'Actions',
  'No records' = 'No records',
  Search = 'Search',
  Filter = 'Filter',
  Add = 'Add',
  Save = 'Save',
  Cancel = 'Cancel',
  'Macros Management' = 'Macros Management',
  'Tags Management' = 'Tags Management',
  Description = 'Description',
  Tag = 'Tag',
  Enabled = 'Enabled',
  'Full Name' = 'Full Name',
  SID = 'SID',
  Workspace = 'Workspace',
  Activity = 'Activity',
  Languages = 'Languages',
  Skills = 'Skills',
  Levels = 'Levels',
  'Disabled Skills' = 'Disabled Skills',
  'Disabled Levels' = 'Disabled Levels',
  Roles = 'Roles',
  'Workers Management' = 'Workers Management',
  'Error loading Workers list' = 'Error loading Workers list',
  'Error editing Worker' = 'Error editing Worker',
  'Error deleting Worker' = 'Error deleting Worker',
  'Error adding Worker' = 'Error adding Worker',
  'Back' = 'Back',
  Workers = 'Workers',
  Macros = 'Macros',
  Slug = 'Slug',
  Group = 'Group',
  'Block Car' = 'Block Car',
  Finance = 'Finance',
  Maintenance = 'Maintenance',
  Others = 'Others',
  Schedule = 'Schedule',
  Signup = 'Signup',
  Color = 'Color',
  Country = 'Country',
  Active = 'Active',
  'No Driver Task selected!' = 'No Driver Task selected!',
  'Validation error' = 'Validation error',
  'Sent through' = 'Sent through',
  'Task wrap up' = 'Task wrap up',
  'Task created' = 'Task created',
  'Task complete' = 'Task complete',
  'Task update' = 'Task update',
  'Task queue entered' = 'Task queue entered',
  'Reservation accepted' = 'Reservation accepted',
  'Reservation complete' = 'Reservation complete',
  'Reservation created' = 'Reservation created',
  'Complete' = 'Complete',
  'This user is not yet a customer There is no information to display!' = 'This user is not yet a customer There is no information to display!',
  'This might be a network or a server error Check you internet connection and retry or contact the' = 'This might be a network or a server error Check you internet connection and retry or contact the',
  'developer team' = 'developer team',
  'Driver not found!' = 'Driver not found!',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success',
  Transfer = 'Transfer',
  MACRO_ALREADY_EXISTS = 'MACRO_ALREADY_EXISTS',
  Activate = 'Activate',
  'Do you want to activate the macro named' = 'Do you want to activate the macro named',
  REQUIRED_ATTRIBUTES = 'REQUIRED_ATTRIBUTES',
  Inactive = 'Inactive',
  'Do you want to activate the tag named' = 'Do you want to activate the tag named',
  TAG_ALREADY_EXISTS = 'TAG_ALREADY_EXISTS',
  'The tag named' = 'The tag named',
  'At least one tag must be selected!' = 'At least one tag must be selected!',
  'Fail to find tags' = 'Fail to find tags',
  'The macro named' = 'The macro named',
  'is alread active' = 'is alread active',
  Ok = 'Ok',
  'There is no channelSid information from the chat' = 'There is no channelSid information from the chat',
  Accessories = 'Accessories',
  Claims = 'Claims',
  Devolution = 'Devolution',
  Fines = 'Fines',
  Guarantee = 'Guarantee',
  Insurance = 'Insurance',
  Corrective = 'Corrective',
  Preventive = 'Preventive',
  Retention = 'Retention',
  RTO = 'RTO',
  'Maintenance Charge' = 'Maintenance Charge',
}
