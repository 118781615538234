import styled from 'styled-components';
import { grey } from '@material-ui/core/colors';

export const Button = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: ${grey[800]};
  cursor: pointer;
`;
