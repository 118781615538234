import { GeneratedTypes } from '@kovi-cx-frontend/apollo-graphql-kovi';
import { Action } from './store';

const ACTION_SET_SELECTED_TAGS: string = 'SET_SELECTED_TAGS';

export type Tag = {
  id: string;
  name: string;
  origin: GeneratedTypes.SupportTicketTagsEnum;
};

export interface TagsState {
  selectedTags: Tag[];
}

const initialState: TagsState = {
  selectedTags: [],
};

export const setSelectedTags = (tags: Tag[]): Action => {
  return {
    type: ACTION_SET_SELECTED_TAGS,
    payload: tags,
  };
};

export function chatTagsReduce(
  state: TagsState = initialState,
  action: Action
) {
  const actions = {
    [ACTION_SET_SELECTED_TAGS]: {
      ...state,
      selectedTags: action.payload,
    },
  };
  return actions[action.type] ?? state;
}
