import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { CommentsComments } from './Comments.Comment';

export interface Props {
  ticketId: string;
  workerEmail: string;
  onClose: () => void;
}

export const CreateInternalComments: FC<Props> = (props) => {
  const { ticketId, workerEmail, onClose } = props;

  return (
    <>
      <CommentsComments
        ticketId={ticketId}
        workerEmail={workerEmail}
        onClose={onClose}
      />
    </>
  );
};

CreateInternalComments.propTypes = {
  ticketId: PropTypes.string.isRequired,
  workerEmail: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
