import { Action } from './store';

const ACTION_SET_INPUT_MESSAGE: string = 'SET_INPUT_MESSAGE';

export interface MessageInputState {
  message: string;
}

const initialState: MessageInputState = {
  message: '',
};

export const setInputMessage = (message: string): Action => {
  return {
    type: ACTION_SET_INPUT_MESSAGE,
    payload: message,
  };
};

export function messageInputReduce(
  state: MessageInputState = initialState,
  action: Action
) {
  const actions = {
    [ACTION_SET_INPUT_MESSAGE]: {
      ...state,
      message: action.payload,
    },
  };
  return actions[action.type] ?? state;
}
