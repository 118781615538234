import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';
import { addTransferButton } from './addTransferButton';
import { setupActions } from './setupActions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function setupTaskTransfer(flex: typeof Flex, _manager: Manager) {
  addTransferButton(flex);
  setupActions(flex);
}
