import React, { FC } from 'react';
import PropTypes from 'prop-types';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import * as Styled from './DatePickerDialog.styled';

interface Props {
  label: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  format?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxDate?: Date;
  minDate?: Date;
  variant?: 'filled' | 'outlined' | 'standard';
}

export const DatePickerDialog: FC<Props> = (props) => {
  const {
    label,
    onChange,
    value,
    format,
    disableFuture,
    disablePast,
    maxDate,
    minDate,
    variant,
  } = props;

  const keyboardDatePickerId = `${label.toLocaleLowerCase()}-date-picker-dialog`;

  return (
    <Styled.FormControl>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id={keyboardDatePickerId}
          label={label}
          format={format}
          value={value}
          disableFuture={disableFuture}
          disablePast={disablePast}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          inputVariant={variant}
        />
      </MuiPickersUtilsProvider>
    </Styled.FormControl>
  );
};

DatePickerDialog.defaultProps = {
  format: 'MM/dd/yyyy',
  disableFuture: false,
  disablePast: false,
  maxDate: undefined,
  minDate: undefined,
  variant: 'outlined',
};

DatePickerDialog.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};
