/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, Typography } from '@material-ui/core';

interface Props<Column> {
  columns: Column[];
}

export function Header<Column extends { field: string; title: string }>(
  props: Props<Column>
) {
  const { columns } = props;
  return (
    <TableHead>
      <TableRow>
        {columns.map((item: Column) => {
          return (
            <TableCell key={item.field}>
              <Typography variant="body1">{item.title}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

Header.propTypes = {
  columns: PropTypes.array.isRequired,
};
