/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import * as Styled from './SelectMultiple.styled';
import { Chip } from '../Chip/Chip';

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

type OptionValue = string | number;
export type Option<T extends OptionValue> = {
  value: T;
  label: string;
  color?: string;
};

interface Props<T extends OptionValue> {
  label?: string;
  options: Option<T>[];
  selectedItems: string[];
  onChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  variant?: 'filled' | 'outlined' | 'standard';
}

export const SelectMultiple = <T extends OptionValue>(props: Props<T>) => {
  const { label, options, onChange, selectedItems, variant } = props;
  const uniqueId = uuidv4();
  const renderOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox
            checked={selectedItems.indexOf(option.value as string) > -1}
          />
          <ListItemText primary={option.label} />
        </MenuItem>
      );
    });
  };

  const renderChips = (selectedOptions: unknown) => {
    return (selectedOptions as []).map((selectedOption: string) => {
      const option = options.find((opt: any) => {
        return opt.value === selectedOption;
      });

      return (
        <Chip
          key={option?.value}
          label={option?.label}
          customcolor={option?.color || undefined}
          size="small"
        />
      );
    });
  };

  return (
    <Styled.FormControl variant={variant}>
      {label && <InputLabel id={`${uniqueId}-input-label`}>{label}</InputLabel>}
      <Select
        labelId={`${uniqueId}-input-label`}
        id={`${uniqueId}-input`}
        label={label}
        multiple
        value={selectedItems}
        onChange={onChange}
        renderValue={renderChips}
        MenuProps={MenuProps}
        variant={variant}
      >
        {renderOptions()}
      </Select>
    </Styled.FormControl>
  );
};

SelectMultiple.defaultProps = {
  label: null,
  variant: 'outlined',
};

SelectMultiple.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedItems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};
