import styled from 'styled-components';
import { FormControl as MuiFormControl } from '@material-ui/core';

export const FormControl = styled(MuiFormControl)`
  width: 100%;
  > .MuiFormControl-root {
    margin: 0;
    padding: 0;
  }
`;
