import { Language } from './typing';

type LanguageMap = {
  enUS: any;
  ptBR: any;
  esMX: any;
};

export function getLanguageFromURL() {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');
  const language = Language[langParam as Language] ?? Language[Language.ptBR];
  return language;
}

export function getValueMoneyByLanguage(
  valueInCents: number | string,
  language: Language
): string | undefined {
  const value = Number(valueInCents) / 100;
  const moneyValues: LanguageMap = {
    enUS: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value),
    ptBR: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value),
    esMX: new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(value),
  };
  const moneyValue = moneyValues[language];
  return moneyValue ?? undefined;
}
