import React, { ReactNode, FC } from 'react';
import PropTypes from 'prop-types';
import { AlertTitle } from '@material-ui/lab';
import i18n, { LocaleStrings } from '@kovi-cx-frontend/i18n';
import * as Styled from './AlertBox.Styled';

export enum AlertBoxSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface Props {
  children: ReactNode;
  severity: AlertBoxSeverity;
  showTitle?: boolean;
}

const configs = {
  [AlertBoxSeverity.ERROR]: {
    title: i18n.t(LocaleStrings.Error),
  },
  [AlertBoxSeverity.WARNING]: {
    title: i18n.t(LocaleStrings.Warning),
  },
  [AlertBoxSeverity.INFO]: {
    title: i18n.t(LocaleStrings.Info),
  },
  [AlertBoxSeverity.SUCCESS]: {
    title: i18n.t(LocaleStrings.Success),
  },
};

export const AlertBox: FC<Props> = (props) => {
  const { children, severity, showTitle } = props;

  const { title } = configs[severity];

  return (
    <Styled.Alert severity={severity}>
      {showTitle && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Styled.Alert>
  );
};

AlertBox.defaultProps = {
  showTitle: true,
};

AlertBox.propTypes = {
  children: PropTypes.node.isRequired,
  severity: PropTypes.oneOf([
    AlertBoxSeverity.ERROR,
    AlertBoxSeverity.WARNING,
    AlertBoxSeverity.INFO,
    AlertBoxSeverity.SUCCESS,
  ]).isRequired,
  showTitle: PropTypes.bool,
};
