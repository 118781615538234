/* eslint-disable no-restricted-globals */
export const config = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_AUTH_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_POOL_WEB_CLIENT_ID,
    mandatorySignIn: process.env.REACT_APP_AWS_AUTH_MANDATORY_SIGNIN,
    redirectSignIn: `${location.origin}/blush-boar-2943`,
    redirectSignOut: `${location.origin}/blush-boar-2943`,
    Storage: {
      bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
      level: process.env.REACT_APP_AWS_STORAGE_LEVEL,
    },
    Analytics: {
      disabled: process.env.REACT_APP_AWS_ANALYTICS_DISABLED,
      appId: process.env.REACT_APP_AWS_ANALYTICS_APP_ID,
      region: process.env.REACT_APP_AWS_REGION,
    },
    oauth: {
      domain: `${process.env.REACT_APP_AWS_COGNITO_HOSTED_UI}`,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: `${location.origin}/blush-boar-2943`,
      redirectSignOut: `${location.origin}/blush-boar-2943`,
      responseType: 'code',
    },
    API: {
      endpoints: [
        {
          name: 'API',
          endpoint: process.env.REACT_APP_KOVI_API_URL,
        },
      ],
    },
  },
};
