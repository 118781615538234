import { Tag } from './Tags.State';

export function mergeTags(oldTags: Tag[], newTags: Tag[]) {
  const mergedTags = [...oldTags, ...newTags].filter((value, index, array) => {
    return (
      array.findIndex((element) => {
        return element.id === value.id;
      }) === index
    );
  });

  return mergedTags;
}

export function compareTags(serverTags: Tag[], selectedTags: Tag[]): boolean {
  return serverTags.reduce((prev: boolean, serverTag: Tag) => {
    if (!prev) return false;
    return selectedTags.some((selectedTag: Tag) => {
      return serverTag.id === selectedTag.id;
    });
  }, serverTags.length === selectedTags.length);
}

export function l(...params: any) {
  // eslint-disable-next-line no-console
  console.log('%cTAGS:', 'font: 14px;', ...params);
}
