import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  actions?: ReactNode;
}

export const AlertDialog: FC<Props> = (props) => {
  const { isOpen, onClose, message, title, actions } = props;

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  message: undefined,
  title: undefined,
  actions: null,
};

AlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
  actions: PropTypes.node,
};
