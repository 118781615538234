import React, { FC, HTMLAttributes, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as Styled from './ButtonIconBack.styled';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  label?: string;
}

export const ButtonIconBack: FC<Props> = (props) => {
  const { onClick, label } = props;
  return (
    <Styled.Button type="button" onClick={onClick}>
      <ArrowBackIcon />
      {label && <Typography component="span">{label}</Typography>}
    </Styled.Button>
  );
};

ButtonIconBack.defaultProps = {
  onClick: undefined,
  label: undefined,
};

ButtonIconBack.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};
