import gql from 'graphql-tag';

export const CREATE_TAG = gql`
  mutation createTag($input: SupportTagsCreateInput) {
    createTag(input: $input) {
      status
      message
      statusCode
      tag {
        id
        name
        is_active
      }
    }
  }
`;

export const DELETE_TAG = gql`
  mutation deleteTag($input: SupportTagsDeleteInput) {
    deleteTag(input: $input) {
      id
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($input: SupportTagsUpdateInput) {
    updateTag(input: $input) {
      id
    }
  }
`;
