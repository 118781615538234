import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import NoSsr from '@material-ui/core/NoSsr';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

const colors = {
  primary: '#FF355A',
  secondary: '#22252C',
  accent: '#780051',
  dark: '#22252c',
  positive: '#bce071',
  negative: '#ffaa91',
  info: '#00d1c1',
  warning: '#ffb400',
};

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.negative,
    },
    warning: {
      main: colors.warning,
    },
    info: {
      main: colors.info,
    },
    success: {
      main: colors.positive,
    },
  },
});

interface Props {
  children: ReactNode;
}

export const ThemeKovi: FC<Props> = (props) => {
  const { children } = props;
  return (
    <NoSsr>
      <MuiThemeProvider theme={customTheme}>
        <ThemeProvider theme={customTheme}>
          <StylesProvider injectFirst>
            <CssBaseline />
            {children}
          </StylesProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </NoSsr>
  );
};

ThemeKovi.defaultProps = {
  children: null,
};

ThemeKovi.propTypes = {
  children: PropTypes.node,
};
