import { ApolloClient } from '@apollo/client';
import { GET_MACROS } from './Macros.Queries';
import { CREATE_MACRO, DELETE_MACRO, UPDATE_MACRO } from './Macros.Mutations';
import {
  GetMacrosVariables,
  createMacroVariables,
  updateMacroVariables,
  deleteMacroVariables,
} from '../../__generated_rental__';

export function imperativeMacrosQuery(
  client: ApolloClient<any>,
  variables: GetMacrosVariables
): Promise<any> {
  return new Promise((resolve, reject) => {
    const promise = client.query({
      query: GET_MACROS,
      variables,
      fetchPolicy: 'network-only',
    });
    promise.then(resolve).catch(reject);
  });
}

export function imperativeMacroCreate(
  client: ApolloClient<any>,
  variables: createMacroVariables,
  refetchQueries?: string[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    const promise = client.mutate({
      mutation: CREATE_MACRO,
      variables,
      refetchQueries,
    });
    promise.then(resolve).catch(reject);
  });
}

export function imperativeMacroUpdate(
  client: ApolloClient<any>,
  variables: updateMacroVariables,
  refetchQueries?: string[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    const promise = client.mutate({
      mutation: UPDATE_MACRO,
      variables,
      refetchQueries,
    });
    promise.then(resolve).catch(reject);
  });
}

export function imperativeMacroDelete(
  client: ApolloClient<any>,
  variables: deleteMacroVariables,
  refetchQueries?: string[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    const promise = client.mutate({
      mutation: DELETE_MACRO,
      variables,
      refetchQueries,
    });
    promise.then(resolve).catch(reject);
  });
}
