import React, { lazy } from 'react';
import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';
import Icon from '@material-ui/icons/LocalOfferOutlined';
import i18n, { LocaleStrings } from '@kovi-cx-frontend/i18n';
import { SuspenseBase } from '../components/SuspenseBase/SuspenseBase';

export const LazyAdminTags = lazy(() => {
  return import('@kovi-cx-frontend/flex-admin-tags');
});

export function setupAdminTags(
  flex: typeof Flex,
  _manager: Manager,
  agentEmail: string
) {
  flex.SideNav.Content.add(
    <Flex.SideLink
      showLabel
      icon={<Icon />}
      isActive
      onClick={() => {
        Flex.Actions.invokeAction('HistoryPush', '/tags');
      }}
      key="admin-tags-sidebar-link"
    >
      {i18n.t(LocaleStrings.Tags)}
    </Flex.SideLink>
  );

  flex.ViewCollection.Content.add(
    <Flex.View name="tags" key="admin-tags">
      <SuspenseBase>
        <div
          style={{
            zIndex: 1,
            height: 'calc(100vh - 70px)',
            width: '100%',
            paddingLeft: '1px',
          }}
        >
          <LazyAdminTags agentEmail={agentEmail} />
        </div>
      </SuspenseBase>
    </Flex.View>
  );
}
