/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import MaterialTable, { Query } from 'material-table';
import i18n, { LocaleStrings } from '@kovi-cx-frontend/i18n';
import { tableIcons } from './tableIcons';

type Column = { title: string; field: string };

export interface TableQuery extends Query<[]> {}

interface Props<Row> {
  tableRef?: any;
  title?: string;
  columns: Column[];
  data?: Row[];
  remoteData?: any;
  loading?: boolean;
  actions?: any;
  onRowUpdate?: any;
  onRowAdd?: any;
  onRowDelete?: any;
}

interface RowBase {}

export const TableEditable = <Row extends RowBase>(props: Props<Row>) => {
  const {
    tableRef,
    title,
    data,
    remoteData,
    loading,
    columns,
    actions,
    onRowUpdate,
    onRowAdd,
    onRowDelete,
  } = props;

  return (
    <Paper elevation={2}>
      <MaterialTable
        tableRef={tableRef}
        title={title}
        columns={columns}
        data={data || remoteData}
        isLoading={loading}
        icons={tableIcons}
        options={{
          actionsColumnIndex: columns.length,
          pageSize: 10,
          debounceInterval: 1000,
        }}
        editable={{
          onRowAdd,
          onRowUpdate,
          onRowDelete,
        }}
        components={{
          Container: (p: any) => {
            return <Paper {...p} elevation={0} />;
          },
        }}
        actions={actions}
        localization={{
          pagination: {
            labelDisplayedRows: `{from}-{to} ${i18n.t(
              LocaleStrings.of
            )} {count}`,
            labelRowsSelect: i18n.t(LocaleStrings.row_plural),
            firstAriaLabel: i18n.t(LocaleStrings['First Page']),
            firstTooltip: i18n.t(LocaleStrings['First Page']),
            previousAriaLabel: i18n.t(LocaleStrings['Previous Page']),
            previousTooltip: i18n.t(LocaleStrings['Previous Page']),
            nextAriaLabel: i18n.t(LocaleStrings['Next Page']),
            nextTooltip: i18n.t(LocaleStrings['Next Page']),
            lastAriaLabel: i18n.t(LocaleStrings['Last Page']),
            lastTooltip: i18n.t(LocaleStrings['Last Page']),
          },
          toolbar: {
            searchTooltip: i18n.t(LocaleStrings.Search),
            searchPlaceholder: i18n.t(LocaleStrings.Search),
          },
          header: {
            actions: i18n.t(LocaleStrings.Actions),
          },
          body: {
            emptyDataSourceMessage: '',
            filterRow: {
              filterTooltip: i18n.t(LocaleStrings.Filter),
            },
            addTooltip: i18n.t(LocaleStrings.Add),
            editTooltip: i18n.t(LocaleStrings.Edit),
            deleteTooltip: i18n.t(LocaleStrings.Delete),
            editRow: {
              deleteText: i18n.t(LocaleStrings.Delete),
              cancelTooltip: i18n.t(LocaleStrings.Cancel),
              saveTooltip: i18n.t(LocaleStrings.Save),
            },
          },
        }}
      />
    </Paper>
  );
};

TableEditable.defaultProps = {
  title: '',
  onRowAdd: undefined,
  onRowUpdate: undefined,
  onRowDelete: undefined,
  actions: undefined,
  data: undefined,
  remoteData: undefined,
  loading: undefined,
  tableRef: undefined,
};

TableEditable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  remoteData: PropTypes.any,
  loading: PropTypes.bool,
  onRowAdd: PropTypes.func,
  onRowUpdate: PropTypes.func,
  onRowDelete: PropTypes.func,
  actions: PropTypes.any,
  tableRef: PropTypes.any,
};
