import React, { FC, ReactNode, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Fallback } from '../Fallback/Fallback';

interface Props {
  children: ReactNode;
}

export const SuspenseBase: FC<Props> = (props) => {
  const { children } = props;

  const fallback = () => {
    return <Fallback />;
  };
  return <Suspense fallback={fallback}>{children}</Suspense>;
};

SuspenseBase.propTypes = { children: PropTypes.node.isRequired };
