import { Locale } from '../index';

const locale: Locale = {
  CommunicationTableHeaderOne: 'Visualizar',
  CommunicationTableHeaderTwo: 'Enviado',
  CommunicationTableHeaderThree: 'Assunto',
  CommunicationTableHeaderFour: 'Email',
  CommunicationTableHeaderFive: 'Status',
  CommunicationTableFooterAmount: 'Total',
  CommunicationStatusOpen: 'Aberto',
  CommunicationStatusSend: 'Enviado',
  CommunicationStatusDelivered: 'Entreque',
  CommunicationStatusFail: 'Falha',
  CommunicationButtonLoadMore: 'Buscar mais',
  CommunicationButtonClose: 'Fechar',
  CommunicationArchivedFrom: 'De',
  CommunicationArchivedTo: 'Para',
  CommunicationArchivedSubject: 'Assunto',
  Communication: 'Comunicação',
  ProtocolNumber: 'Protocolo',
  Attendance: 'Atendimento',
  Driver: 'Motorista',
  Payments: 'Pagamentos',
  Vehicles: 'Veículos',
  Registration: 'Cadastro',
  Resolved: 'Resolvido',
  NotResolved: 'Não resolvido',
  Open: 'Aberto',
  'Initial date': 'Data inicial',
  'Final date': 'Data final',
  Status: 'Status',
  Provider: 'Origem',
  Tags: 'Tags',
  Queues: 'Filas',
  Sent: 'Enviado',
  'Joined on': 'Cadastrou-se em',
  RI: 'CPF',
  Email: 'Email',
  'Main phone': 'Telefone principal',
  'Emergency phone 1': 'Telefone de emergência 1',
  'Emergency phone 2': 'Telefone de emergência 2',
  DriverLicense: 'CNH',
  'Driver License Number': 'Número da CNH',
  'Driver License Points': 'Pontos na CNH',
  'Expiration Date': 'Data de Validade',
  'Has EAR': 'Possui EAR',
  Situation: 'Situação',
  'Emission State': 'Estado de emissão',
  'Driver License Time': 'Tempo de CNH',
  Category: 'Categoria',
  Plan: 'Plano',
  ActivePlan: 'Plano Ativo',
  NotPlan: 'não há plano ativo',
  Value: 'Valor',
  Next: 'Próximo',
  'Payment Day': 'Dia de pagamento',
  Yes: 'Sim',
  No: 'Não',
  Address: 'Endereço',
  'Transactions paid on time': 'Faturas pagas em dia',
  'Transactions paid in overdue': 'Faturas pagas em atraso',
  'Probability of delay': 'Probabilidade de atraso',
  'Average delay': 'Media de atraso',
  day: 'dia',
  'Total of invoices pending': 'Total de faturas pendentes',
  'Total of fines pending': 'Total de multas pendentes',
  'Total of maintenances pending': 'Total de manutenções pendentes',
  'Total of others pending': 'Total de outras pendências',
  'Total of debits pending': 'Total de débitos pendentes',
  Name: 'Nome',
  Text: 'Texto',
  Subject: 'Assunto',
  Edit: 'Editar',
  Delete: 'Excluir',
  row: 'linha',
  row_plural: 'linhas',
  of: 'de',
  selected: 'selecionado',
  'First Page': 'Primeira página',
  'Previous Page': 'Página anterior',
  'Next Page': 'Próxima página',
  'Last Page': 'Última página',
  Actions: 'Ações',
  'No records': 'Não há registros',
  Search: 'Buscar',
  Filter: 'Filtrar',
  Add: 'Adicionar',
  Save: 'Salvar',
  Cancel: 'Cancelar',
  'Macros Management': 'Gerenciamento de Macros',
  'Tags Management': 'Gerenciamento de Tags',
  Description: 'Descrição',
  Tag: 'Tag',
  Enabled: 'Habilitado',
  'Full Name': 'Nome Completo',
  SID: 'SID',
  Workspace: 'Workspace',
  Activity: 'Activity',
  Languages: 'Idiomas',
  Skills: 'Habilidades',
  Levels: 'Níveis',
  'Disabled Skills': 'Habilidades desabilitadas',
  'Disabled Levels': 'Níveis desabilitados',
  Roles: 'Acessos',
  'Workers Management': 'Gerenciamento de Analistas',
  'Error loading Workers list': 'Error ao carregar lista de Analistas',
  'Error editing Worker': 'Erro ao editar Analistas',
  'Error deleting Worker': 'Erro ao excluir Analista',
  'Error adding Worker': 'Error ao adicionar Analista',
  Back: 'Voltar',
  Workers: 'Analistas',
  Macros: 'Macros',
  Slug: 'Slug',
  Group: 'Grupo',
  'Block Car': 'Bloqueio do Carro',
  Finance: 'Finança',
  Maintenance: 'Manutenção',
  Others: 'Outros',
  Schedule: 'Agendamento',
  Signup: 'Cadastro',
  Color: 'Cor',
  Country: 'País',
  Active: 'Ativo',
  'No Driver Task selected!': 'Não há tarefa selecionada!',
  'Validation error': 'Validation error',
  'Sent through': 'Enviado via',
  'Task wrap up': 'Conclusão da tarefa',
  'Task created': 'Tarefa criada',
  'Task update': 'Tarefa atualizada',
  'Task complete': 'Tarefa concluída',
  'Task queue entered': 'Fila de tarefas inserida',
  'Reservation accepted': 'Reserva aceita',
  'Reservation complete': 'Reserva completa',
  'Reservation created': 'Reserva criada',
  Complete: 'Completo',
  'This user is not yet a customer There is no information to display!':
    'Este usuário ainda não é cliente. Não há informações para exibir!',
  'This might be a network or a server error Check you internet connection and retry or contact the':
    'Aparentemente houve um erro de rede ou no servidor. Verifique sua conexão de internet e tente novamente ou entre em contato com o',
  'developer team': 'time de desenvolvimento',
  'Driver not found!': 'Driver não encontrado!',
  Error: 'Erro',
  Warning: 'Aviso',
  Info: 'Informação',
  Success: 'Sucesso',
  Transfer: 'Transferir',
  MACRO_ALREADY_EXISTS: 'Macro já existente!',
  Activate: 'Ativar',
  'Do you want to activate the macro named': 'Deseja ativar a macro chamada',
  REQUIRED_ATTRIBUTES: 'Atributos obrigatórios não informados',
  Inactive: 'Inativo',
  'Do you want to activate the tag named': 'Deseja ativar a tag chamada',
  TAG_ALREADY_EXISTS: 'Tag já existente!',
  'is alread active': 'já está ativa',
  'The tag named': 'A tag de nome',
  'At least one tag must be selected!':
    'Ao menos uma tag precisa ser selecionada!',
  'Fail to find tags': 'Falha ao buscar as tags',
  'The macro named': 'A macro chamada',
  Ok: 'Ok',
  'There is no channelSid information from the chat':
    'Não existe a informação "channelSid" do chat.',
  Accessories: 'Acessórios',
  Claims: 'Sinistros',
  Devolution: 'Devolução',
  Fines: 'Multas',
  Guarantee: 'Caução',
  Insurance: 'Seguros',
  Corrective: 'Corretiva',
  Preventive: 'Preventiva',
  Retention: 'Retenção',
  RTO: 'RTO',
  'Maintenance Charge': 'Cobranças de manutenção',
};

export default locale;
