import React, { FC, ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import PropTypes from 'prop-types';
import { getWebchatGraphQLUrl } from '../config/getGraphQLUrl';
import { createApolloClient } from '../config/client';

interface Props {
  children: ReactNode;
}

export const WebChatApolloProvider: FC<Props> = (props) => {
  const { children } = props;
  const graphQLURLBasedOnEnv = getWebchatGraphQLUrl();
  const client = createApolloClient(graphQLURLBasedOnEnv);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

WebChatApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
