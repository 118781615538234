import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';
import { getTextLocalization } from './getTextLocalization';
import { LocaleStrings } from './localization';

export function setupI18n(_flex: typeof Flex, manager: Manager) {
  const browserLanguage = window.navigator.language;

  const language = browserLanguage.includes('es') ? 'es-mx' : 'pt-br';
  const localizedStrings: LocaleStrings = getTextLocalization(language);
  // eslint-disable-next-line no-param-reassign
  manager.strings = { ...manager.strings, ...localizedStrings };
}
