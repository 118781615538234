import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import * as Styled from './Select.Styled';

type OptionValue = string | number;
type Option<T extends OptionValue> = {
  value: T;
  label: string;
};

interface Props<T extends OptionValue> {
  label?: string;
  options: Option<T>[];
  value?: T;
  multiple: boolean;
  onChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  variant?: 'filled' | 'outlined' | 'standard';
  disabled?: boolean;
}

export const Select = <T extends OptionValue>(props: Props<T>) => {
  const {
    label,
    options,
    value,
    multiple,
    onChange,
    variant,
    disabled,
  } = props;
  const uniqueId = uuidv4();

  const renderOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Styled.FormControl variant={variant}>
      {label && <InputLabel id={`${uniqueId}-input-label`}>{label}</InputLabel>}
      <MaterialSelect
        labelId={`${uniqueId}-input-label`}
        id={`${uniqueId}-input`}
        label={label}
        multiple={multiple}
        value={value}
        disabled={disabled}
        onChange={onChange}
        inputProps={{
          name: `status-${label}`,
          id: `status-${label}`,
        }}
      >
        <MenuItem aria-label="None" value="" />
        {renderOptions()}
      </MaterialSelect>
    </Styled.FormControl>
  );
};
Select.defaultProps = {
  label: null,
  value: '',
  multiple: false,
  variant: 'outlined',
  disabled: false,
};

Select.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  disabled: PropTypes.bool,
};
