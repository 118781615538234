import React, { FC } from 'react';
import PropTypes from 'prop-types';

import { ColorViewerBox, ColorEmptyBox } from './ColorViewer.Styled';

interface Props {
  color?: string | null;
}

export const ColorViewer: FC<Props> = (props) => {
  const { color } = props;
  return color ? <ColorViewerBox color={color} /> : <ColorEmptyBox />;
};

ColorViewer.defaultProps = {
  color: undefined,
};

ColorViewer.propTypes = {
  color: PropTypes.string,
};
