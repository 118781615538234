import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';
import { createThemeConfig } from './themeConfig';

export function setupThemeKovi(_flex: typeof Flex, manager: Manager) {
  const appConfig = {
    colorTheme: createThemeConfig(),
  };
  manager.updateConfig(appConfig);
}
