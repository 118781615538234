import React from 'react';
import { withTaskContext, Icon } from '@twilio/flex-ui';
import { Box } from '@material-ui/core';
import * as Styled from './DriverGoneNotification.Styled';

export const DriverGoneNotification = withTaskContext((props: any) => {
  const {
    taskDriverGone,
    taskIncoming,
    driverGone,
  } = props.task?.attributes;

  return driverGone && taskIncoming !== taskDriverGone ? (
    <Box display="flex" flexDirection="row" marginTop="20px">
      <Box width="40px" paddingLeft="5px">
        <Styled.Avatar>
          <Icon icon="DefaultAvatar" />
        </Styled.Avatar>
      </Box>
      <Box>
        <Styled.MessageBox display="flex" flexDirection="column">
          <Styled.Name>
            {props.task?.attributes?.driver?.name || 'Kovinho'}
          </Styled.Name>
          <Box>Saiu do chat.</Box>
        </Styled.MessageBox>
      </Box>
    </Box>
  ) : null;
});
