import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { TextField, Checkbox } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface Props<Option> {
  options: Option[];
  value?: Option | Option[] | null;
  label?: string;
  placeholder?: string;
  multiple?: boolean;

  onChange: (
    event?: ChangeEvent<{}>,
    value?: Option | Option[] | null,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option> | undefined
  ) => void;

  fieldOptionLabel: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function AutoComplete<Option>(props: Props<Option>) {
  const {
    options,
    value,
    label,
    placeholder,
    multiple,
    onChange,
    fieldOptionLabel,
  } = props;
  return (
    <Autocomplete
      id={`autocomplete-${label}`}
      multiple={multiple}
      options={options}
      value={value || []}
      disableCloseOnSelect
      getOptionLabel={(option) => {
        return option[fieldOptionLabel as keyof typeof Option];
      }}
      getOptionSelected={(option: Option, val: Option) => {
        return (option as any).id === (val as any).id;
      }}
      onChange={onChange}
      renderOption={(option, { selected }) => {
        return (
          <span>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
              color="primary"
            />
            {option[fieldOptionLabel as keyof typeof Option]}
          </span>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
}

AutoComplete.defaultProps = {
  multiple: false,
  label: undefined,
  placeholder: undefined,
  value: undefined,
};

AutoComplete.prototypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
};
