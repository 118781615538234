export default {
  ProtocolNumber: 'ProtocolNumber',
  SetYourStatus: 'SetYourStatus',
  LogOut: 'LogOut',
  SigningIn: 'SigningIn',
  SessionExpired: 'SessionExpired',
  NoTasksTitle: 'NoTasksTitle',
  NoTasks: 'NoTasks',
  NoTasksHintNotAvailable: 'NoTasksHintNotAvailable',
  NoTasksHintAvailable: 'NoTasksHintAvailable',
  NoCRMConfigured: 'NoCRMConfigured',
  NoCRMConfiguredAction: 'NoCRMConfiguredAction',
  NoCRMTitle: 'NoCRMTitle',
  NoCRMHint: 'NoCRMHint',
  LoginUsername: 'LoginUsername',
  LoginPassword: 'LoginPassword',
  LoginLoading: 'LoginLoading',
  LoginWelcome: 'LoginWelcome',
  LoginLogin: 'LoginLogin',
  LoginContinue: 'LoginContinue',
  LoginAgain: 'LoginAgain',
  LoginLaunch: 'LoginLaunch',
  LoginTitle: 'LoginTitle',
  LoginErrorDomain: 'LoginErrorDomain',
  LoginErrorDomainDetails: 'LoginErrorDomainDetails',
  LoginErrorSso: 'LoginErrorSso',
  LoginErrorSsoDetails: 'LoginErrorSsoDetails',
  LoginErrorPopupBlocked: 'LoginErrorPopupBlocked',
  LoginErrorPopupBlockedDetails: 'LoginErrorPopupBlockedDetails',
  LoginErrorUntrustedDomain: 'LoginErrorUntrustedDomain',
  LoginErrorUntrustedDomainDetails: 'LoginErrorUntrustedDomainDetails',
  LoginErrorInvalidCertificate: 'LoginErrorInvalidCertificate',
  LoginErrorInvalidCertificateDetails: 'LoginErrorInvalidCertificateDetails',
  LoginErrorGeneric: 'LoginErrorGeneric',
  LoginIsAdmin: 'LoginIsAdmin',
  LoginWithTwilio: 'LoginWithTwilio',
  TaskHeaderLeaveCall: 'TaskHeaderLeaveCall',
  TaskHeaderEndCall: 'TaskHeaderEndCall',
  TaskHeaderWrapup: 'TaskHeaderWrapup',
  TaskHeaderComplete: 'TaskHeaderComplete',
  TaskHeaderEndChat: 'TaskHeaderEndChat',
  TaskHeaderStatusPending: 'TaskHeaderStatusPending',
  TaskHeaderStatusAccepted: 'TaskHeaderStatusAccepted',
  TaskHeaderWebChatStatusAccepted: 'TaskHeaderWebChatStatusAccepted',
  TaskHeaderGroupCallAccepted: 'TaskHeaderGroupCallAccepted',
  TaskHeaderStatusWrapup: 'TaskHeaderStatusWrapup',
  TaskHeaderStatusCompleted: 'TaskHeaderStatusCompleted',
  SideNavTaskView: 'SideNavTaskView',
  SideNavTeamsView: 'SideNavTeamsView',
  SideNavAgentDashboard: 'SideNavAgentDashboard',
  SideNavSupervisorInspections: 'SideNavSupervisorInspections',
  SideNavSupervisorDashboard: 'SideNavSupervisorDashboard',
  SideNavSupervisorReports: 'SideNavSupervisorReports',
  SideNavSettings: 'SideNavSettings',
  SideNavQueuesStatsView: 'SideNavQueuesStatsView',
  TaskHeaderLine: 'TaskHeaderLine',
  TaskLineCallIncomingTitle: 'TaskLineCallIncomingTitle',
  TaskLineCallTransferTitle: 'TaskLineCallTransferTitle',
  TaskLineCallEndedTitle: 'TaskLineCallEndedTitle',
  TaskLineQueue: 'TaskLineQueue',
  TaskLineCallDuration: 'TaskLineCallDuration',
  TaskLineCallReserved: 'TaskLineCallReserved',
  TaskLineCallAssigned: 'TaskLineCallAssigned',
  TaskLineCallWrapup: 'TaskLineCallWrapup',
  TaskLineGroupCallAssigned: 'TaskLineGroupCallAssigned',
  TaskLineCallTransferWorker: 'TaskLineCallTransferWorker',
  TaskLineCallTransferWorkerDetail: 'TaskLineCallTransferWorkerDetail',
  TaskLineCallTransferQueue: 'TaskLineCallTransferQueue',
  TaskLineCallTransferQueueDetail: 'TaskLineCallTransferQueueDetail',
  TaskLineChatReserved: 'TaskLineChatReserved',
  TaskLineChatLineReserved: 'TaskLineChatLineReserved',
  TaskLineChatMessengerReserved: 'TaskLineChatMessengerReserved',
  TaskLineChatWhatsAppReserved: 'TaskLineChatWhatsAppReserved',
  TaskLineSmsReserved: 'TaskLineSmsReserved',
  TaskLineTitle: 'TaskLineTitle',
  TaskReserved: 'TaskReserved',
  TaskAssigned: 'TaskAssigned',
  TaskWrapup: 'TaskWrapup',
  TaskCompleted: 'TaskCompleted',
  TaskCanceled: 'TaskCanceled',
  TaskFilterAll: 'TaskFilterAll',
  TaskFilterActive: 'TaskFilterActive',
  TaskFilterWrapping: 'TaskFilterWrapping',
  ColumnHeaderAgent: 'ColumnHeaderAgent',
  ColumnHeaderCalls: 'ColumnHeaderCalls',
  ColumnHeaderTasks: 'ColumnHeaderTasks',
  SupervisorUserCardFirstLine: 'SupervisorUserCardFirstLine',
  SupervisorUserCardSecondLine: 'SupervisorUserCardSecondLine',
  SupervisorTaskViewContentHeader: 'SupervisorTaskViewContentHeader',
  SupervisorTaskHeaderLine: 'SupervisorTaskHeaderLine',
  SupervisorTaskLive: 'SupervisorTaskLive',
  SupervisorTaskGroupCall: 'SupervisorTaskGroupCall',
  SupervisorTaskWrapUp: 'SupervisorTaskWrapUp',
  SupervisorTaskCompleted: 'SupervisorTaskCompleted',
  SupervisorTaskCardHeader: 'SupervisorTaskCardHeader',
  LiveCommsMonitoringMessage: 'LiveCommsMonitoringMessage',
  LiveCommsMonitoringMessageMoreWorkers:
    'LiveCommsMonitoringMessageMoreWorkers',
  LiveCommsMonitoringMessageNoWorkers: 'LiveCommsMonitoringMessageNoWorkers',
  LiveCommsOngoingCallMessage: 'LiveCommsOngoingCallMessage',
  LiveCommsIncomingCallMessage: 'LiveCommsIncomingCallMessage',
  LiveCommsBarAccept: 'LiveCommsBarAccept',
  LiveCommsBarDeny: 'LiveCommsBarDeny',
  LiveCommsBarReturnToCall: 'LiveCommsBarReturnToCall',
  LiveCommsBarStopListening: 'LiveCommsBarStopListening',
  LiveCommsBarHold: 'LiveCommsBarHold',
  LiveCommsBarUnHold: 'LiveCommsBarUnHold',
  WorkerDirectoryTitle: 'WorkerDirectoryTitle',
  WorkerDirectoryItemFirstLine: 'WorkerDirectoryItemFirstLine',
  WorkerDirectoryItemSecondLine: 'WorkerDirectoryItemSecondLine',
  WorkerDirectoryAgentsTabLabel: 'WorkerDirectoryAgentsTabLabel',
  WorkerDirectoryQueuesTabLabel: 'WorkerDirectoryQueuesTabLabel',
  WorkerDirectoryQueueItemText: 'WorkerDirectoryQueueItemText',
  WorkerDirectorySearchPlaceholder: 'WorkerDirectorySearchPlaceholder',
  ChatInputDisabledText: 'ChatInputDisabledText',
  WorkerCanvasHeader: 'WorkerCanvasHeader',
  WorkerCanvasDetailsHeader: 'WorkerCanvasDetailsHeader',
  WorkerCanvasSkillsHeader: 'WorkerCanvasSkillsHeader',
  WorkerSkillLevelInvalid: 'WorkerSkillLevelInvalid',
  WorkerSkillPleaseSelect: 'WorkerSkillPleaseSelect',
  WorkerSkillsNoSkills: 'WorkerSkillsNoSkills',
  WorkerSkillsSchemaMismatch: 'WorkerSkillsSchemaMismatch',
  WorkerSkillsSaved: 'WorkerSkillsSaved',
  WorkerSkillsError: 'WorkerSkillsError',
  WorkerSkillsReverted: 'WorkerSkillsReverted',
  ChatWelcomeText: 'ChatWelcomeText',
  UiVersionDeprecated: 'UiVersionDeprecated',
  RuntimeLoginLabel: 'RuntimeLoginLabel',
  RuntimeLoginPlaceholder: 'RuntimeLoginPlaceholder',
  RuntimeLoginLaunch: 'RuntimeLoginLaunch',
  RuntimeLoginWithTwilio: 'RuntimeLoginWithTwilio',
  RuntimeLoginIsAdmin: 'RuntimeLoginIsAdmin',
  RuntimeLoginErrorDomain: 'RuntimeLoginErrorDomain',
  RuntimeLoginErrorSso: 'RuntimeLoginErrorSso',
  TransferFailedNotification: 'TransferFailedNotification',
  TaskCannotBeTransferredNotification: 'TaskCannotBeTransferredNotification',
  SupervisorTaskCanvasHeader: 'SupervisorTaskCanvasHeader',
  TaskTabAgentIncomingLabel: 'TaskTabAgentIncomingLabel',
  TaskTabAgentCallLabel: 'TaskTabAgentCallLabel',
  TaskTabAgentChatLabel: 'TaskTabAgentChatLabel',
  TaskTabAgentInfoLabel: 'TaskTabAgentInfoLabel',
  TaskTabSupervisorOverviewLabel: 'TaskTabSupervisorOverviewLabel',
  TaskTabSupervisorCallLabel: 'TaskTabSupervisorCallLabel',
  TaskTabSupervisorChatLabel: 'TaskTabSupervisorChatLabel',
  TaskTabSupervisorInfoLabel: 'TaskTabSupervisorInfoLabel',
  MicNotAvailableNotification: 'MicNotAvailableNotification',
  BrowserVoiceDisabledNotification: 'BrowserVoiceDisabledNotification',
  MessageSizeExceeded: 'MessageSizeExceeded',
  UserControlWorkerSecondLine: 'UserControlWorkerSecondLine',
  UserLoggedOutSuccess: 'UserLoggedOutSuccess',
  CannotChangeOtherWorkerActivity: 'CannotChangeOtherWorkerActivity',
  CallCanvasWorkerOnHold: 'CallCanvasWorkerOnHold',
  CallCanvasUnholdWorker: 'CallCanvasUnholdWorker',
  CallParticipantStatusOnHold: 'CallParticipantStatusOnHold',
  CallParticipantStatusLive: 'CallParticipantStatusLive',
  CallParticipantStatusConnecting: 'CallParticipantStatusConnecting',
  CallParticipantStatusLeft: 'CallParticipantStatusLeft',
  CallParticipantStatusTransferFailed: 'CallParticipantStatusTransferFailed',
  CallParticipantStatusKickConfirmation:
    'CallParticipantStatusKickConfirmation',
  LiveCommsIncomingDirectCallTransfer: 'LiveCommsIncomingDirectCallTransfer',
  LiveCommsIncomingQueueCallTransfer: 'LiveCommsIncomingQueueCallTransfer',
  CallParticipantCustomerName: 'CallParticipantCustomerName',
  MonitoringFailedNotification: 'MonitoringFailedNotification',
  PredefinedChatMessageAuthorName: 'PredefinedChatMessageAuthorName',
  PredefinedChatMessageBody: 'PredefinedChatMessageBody',
  AgentStatusAvailable: 'AgentStatusAvailable',
  AgentStatusBusy: 'AgentStatusBusy',
  AgentStatusUnavailable: 'AgentStatusUnavailable',
  AgentStatusOffline: 'AgentStatusOffline',
  ActiveTasksTileTitle: 'ActiveTasksTileTitle',
  WaitingTasksTileTitle: 'WaitingTasksTileTitle',
  AgentsByActivityTileTitle: 'AgentsByActivityTileTitle',
  LongestWaitTimeTileTitle: 'LongestWaitTimeTileTitle',
  QueuesNoDataHeading: 'QueuesNoDataHeading',
  QueuesNoDataBodyForAdmin: 'QueuesNoDataBodyForAdmin',
  QueuesNoDataBodyForSupervisor: 'QueuesNoDataBodyForSupervisor',
  QueuesNoDataLinkLabelForAdmin: 'QueuesNoDataLinkLabelForAdmin',
  PendingReservationsOnLogoutNotification:
    'PendingReservationsOnLogoutNotification',
  PendingReservationsOnActivityStateChangeNotification:
    'PendingReservationsOnActivityStateChangeNotification',
  AcceptTaskTooltip: 'AcceptTaskTooltip',
  RejectTaskTooltip: 'RejectTaskTooltip',
  HangupCallTooltip: 'HangupCallTooltip',
  LeaveCallTooltip: 'LeaveCallTooltip',
  MuteCallTooltip: 'MuteCallTooltip',
  HoldCustomerTooltip: 'HoldCustomerTooltip',
  UnholdCustomerTooltip: 'UnholdCustomerTooltip',
  HoldAgentTooltip: 'HoldAgentTooltip',
  UnholdAgentTooltip: 'UnholdAgentTooltip',
  CancelTransferTooltip: 'CancelTransferTooltip',
  KickAgentTooltip: 'KickAgentTooltip',
  ColdTransferTooltip: 'ColdTransferTooltip',
  WarmTransferTooltip: 'WarmTransferTooltip',
  MonitorCallTooltip: 'MonitorCallTooltip',
  LogToFileStartLoggingText: 'LogToFileStartLoggingText',
  LogToFileStartLoggingLabel: 'LogToFileStartLoggingLabel',
  LogToFileStopLoggingText: 'LogToFileStopLoggingText',
  LogToFileStopLoggingLabel: 'LogToFileStopLoggingLabel',
  ChatOrchestrationAddToChatChannelFailed:
    'ChatOrchestrationAddToChatChannelFailed',
  ChatOrchestrationDeactivateChatChannelFailed:
    'ChatOrchestrationDeactivateChatChannelFailed',
  ChatOrchestrationLeaveChatChannelFailed:
    'ChatOrchestrationLeaveChatChannelFailed',
  TeamsViewFilterAction: 'TeamsViewFilterAction',
  TeamsViewClearSearchAction: 'TeamsViewClearSearchAction',
  TeamsViewClearSearchPlaceholder: 'TeamsViewClearSearchPlaceholder',
  TeamsViewResultsSummaryNoWorkersDisplayed:
    'TeamsViewResultsSummaryNoWorkersDisplayed',
  TeamsViewResultsSummaryMaxWorkersDisplayed:
    'TeamsViewResultsSummaryMaxWorkersDisplayed',
  TeamsViewResultsSummaryFilterCTA: 'TeamsViewResultsSummaryFilterCTA',
  TeamFiltersPanelTitle: 'TeamFiltersPanelTitle',
  TeamFiltersPanelSingularLabel: 'TeamFiltersPanelSingularLabel',
  TeamFiltersPanelPluralLabel: 'TeamFiltersPanelPluralLabel',
  TeamFiltersApply: 'TeamFiltersApply',
  TeamFiltersReset: 'TeamFiltersReset',
  InputPlaceHolder: 'InputPlaceHolder',
  TypingIndicator: 'TypingIndicator',
  Connecting: 'Connecting',
  Disconnected: 'Disconnected',
  Read: 'Read',
  MessageSendingDisabled: 'MessageSendingDisabled',
  Today: 'Today',
  Yesterday: 'Yesterday',
  MessageCanvasTrayContent: 'MessageCanvasTrayContent',
  MessageCanvasTrayButton: 'MessageCanvasTrayButton',
  WelcomeMessage: 'WelcomeMessage',
  NotificationMessage: 'NotificationMessage',
  Save: 'Save',
  Reset: 'Reset',
  MessageCharacterCountStatus: 'MessageCharacterCountStatus',
  MessageCharacterCountReached: 'MessageCharacterCountReached',
  SendMessageTooltip: 'SendMessageTooltip',
  FieldValidationRequiredField: 'FieldValidationRequiredField',
  FieldValidationInvalidEmail: 'FieldValidationInvalidEmail',
  ChatInputUserNotMemberDisabledText: 'ChatInputUserNotMemberDisabledText',
  FilterItemAny: 'FilterItemAny',
  FilterItemAmountSelected: 'FilterItemAmountSelected',
  FilterItemOnly: 'FilterItemOnly',
  TaskLineChatAssigned: 'TaskLineChatAssigned',
  TaskLineWebChatAssigned: 'TaskLineWebChatAssigned',
};
