import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';

export function autoAcceptTask(flex: typeof Flex, manager: Manager) {
  manager.workerClient.on('reservationCreated', (reservation: any) => {
    if (reservation.task.attributes.direction !== 'outbound') {
      flex.Actions.invokeAction('AcceptTask', { sid: reservation.sid });
    }
  });
}
