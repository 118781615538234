/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
import styled from 'styled-components';

export const ButtonMaximize = styled.button`
  flex: 1;
  background: transparent;
  border: none;
  outline: none;
`;

export const ButtonSend = styled.button`
  padding: 8px;
  margin-bottom: 6px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background: #057d9e;
  outline: none;

  &.disabled {
    opacity: 0.5;
  }
`;

