import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import {
  AlertBox,
  AlertBoxSeverity,
  // Modal,
} from '@kovi-cx-frontend/components';
import { useApolloClient } from '@apollo/client';
import {
  GeneratedTypes,
  imperativelyCreateComment,
} from '@kovi-cx-frontend/apollo-graphql-kovi';
import { CommentsCreate } from './Comments.Form';
import { AttachmentComment } from '../Attachment/AttachmentComment';

interface Props {
  ticketId: string;
  workerEmail: string;
  onClose: () => void;
}

export const CommentsComments: FC<Props> = (props) => {
  const { ticketId, workerEmail, onClose } = props;
  const [commentState, setCommentState] = useState<any | null>(null);
  const [commentFileState, setCommentFileState] = useState<any | null>(null);
  const [errorItems, setErrorItems] = useState<boolean>(false);
  const [successComment, setSuccessComment] = useState<boolean>(false);
  const [errorComment, setErrosComment] = useState<boolean>(false);
  const client = useApolloClient();

  const handlerSaveComment = async () => {
    try {
      if (!commentState?.comment && !commentFileState?.file?.name) {
        setErrorItems(true);
        return;
      }

      const file_comment: string | undefined = commentFileState?.file?.name
        ? `${
            process.env.REACT_APP_CX_MEDIA_PUBLIC_URL ||
            'https://s3.amazonaws.com/cx-media.kovi.dev'
          }/comment/${commentFileState?.file?.name}`
        : undefined;

      const commentVariables: GeneratedTypes.createTicketCommentVariables = {
        input: {
          support_ticket_id: ticketId,
          support_agent_email: workerEmail || 'agent.kovi@kovi.com.br',
          comment: commentState?.comment || '.',
          internal: true,
          file_comment,
        },
      };

      const commentMutation = imperativelyCreateComment(
        client,
        commentVariables
      );

      commentMutation
        .then(() => {
          setSuccessComment(true);
          setTimeout(1000);
        })
        .catch(() => {
          setErrosComment(true);
        })
        .finally(() => {
          onClose();
        });
      // eslint-disable-next-line consistent-return
      return;
    } catch (erro) {
      // eslint-disable-next-line consistent-return
      return Promise.reject();
    }
  };

  const handleChangeComment = (comments: any) => {
    setCommentState({
      ...comments,
    });
    setErrorItems(false);
    setErrosComment(false);
    setSuccessComment(false);
  };

  const handleChangeFileComment = (file: any) => {
    setCommentFileState({
      ...file,
    });
    setErrorItems(false);
    setErrosComment(false);
    setSuccessComment(false);
  };

  interface PropsMessage {
    message: string;
    severity: AlertBoxSeverity;
  }
  const AlertMessage: FC<PropsMessage> = (alertProps) => {
    const { message, severity } = alertProps;
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100px"
      >
        <AlertBox severity={severity}>{message}</AlertBox>
      </Box>
    );
  };

  AlertMessage.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    message: PropTypes.string.isRequired,
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="50vw"
          minWidth="380px"
        >
          {errorItems && (
            <AlertMessage
              message="Comente ou adicione uma imagem."
              severity={AlertBoxSeverity.ERROR}
            />
          )}
          {errorComment && (
            <AlertMessage
              message="Erro ao salvar comentário."
              severity={AlertBoxSeverity.ERROR}
            />
          )}
          {successComment && (
            <AlertMessage
              message="Comentário salvo com sucesso!"
              severity={AlertBoxSeverity.SUCCESS}
            />
          )}
          <Box>
            <CommentsCreate onChange={handleChangeComment} />
          </Box>
          <Box display="flex" flexDirection="row">
            <Button onClick={handlerSaveComment} color="primary">
              Salvar
            </Button>
            <AttachmentComment onChange={handleChangeFileComment} />
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

CommentsComments.propTypes = {
  ticketId: PropTypes.string.isRequired,
  workerEmail: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
