import {
  from,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
} from '@apollo/client';

import { RetryLink } from '@apollo/client/link/retry';

export function createApolloClient(
  graphQLURI: string,
  headers: { Authorization?: string; region?: string } = {}
) {
  const cache = new InMemoryCache({
    addTypename: false,
  }) as any;

  const link = from([
    new RetryLink(),
    new HttpLink({
      uri: graphQLURI,
      headers,
    }),
  ]);

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link,
  });

  return client;
}
