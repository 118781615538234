/* eslint-disable indent */
/* eslint-disable import/no-unresolved */
import React, { KeyboardEvent } from 'react';
import { useMacrosQuery } from '@kovi-cx-frontend/apollo-graphql-kovi';

// import { StateToProps, DispatchToProps } from './Macros.Container';
import { withTaskContext, Actions } from '@twilio/flex-ui';
import { whichRegion } from '../../helpers/whichRegion';
import { MacroSelect } from './components/MacroSelect/MacroSelect';
import {
  deepFillTemplate,
  removeUndefineds,
  parseMacros,
} from './Macros.Utils';
import { Macro } from './Macros.Typing';

export const Macros = withTaskContext((props: any) => {
  const { messageInputRef } = props;
  const driverName = props.task?.attributes?.driver?.name;
  const agentName = props.worker?.full_name?.split(' ')[0] || '';
  const driverRegion = props.task?.attributes?.driver?.region?.country;
  const taskRegion = props.task?.attributes?.country;
  // const rect = messageInputRef?.current?.getBoundingClientRect();
  const verticalPosition = messageInputRef?.current?.offsetTop;

  Actions.addListener('beforeSelectTask', () => {
    const { setInputMessage } = props;
    setInputMessage('');
  });

  const placeholders = {
    driver_name: driverName || 'driver_name',
    agent_name: agentName || 'agent_name',
  };

  const { data } = useMacrosQuery({
    filters: {
      is_active: true,
      region: whichRegion(driverRegion, taskRegion),
    },
    filtersIncludes: {
      tags: {
        is_active: true,
        required: false,
      },
    },
    attributesIncludes: {
      tags: ['id', 'name', 'color'],
    },
  }); // loading, error
  const macros = data?.macros?.items;
  const parsedMacros = macros && parseMacros(macros);

  const handleSelectChange = (option: Macro) => {
    const { value: macroMessage } = option;
    const { setInputMessage, setMacroMode, setSelectedMacro, onDone } = props;
    const macroMessageWithPlaceholders = macroMessage
      ? removeUndefineds(deepFillTemplate(macroMessage, placeholders))
      : null;

    setInputMessage(macroMessageWithPlaceholders);
    setMacroMode(null);
    setSelectedMacro(option);
    onDone();
  };

  const handleSelectKeyDown = ({ nativeEvent: { code } }: KeyboardEvent) => {
    const { setInputMessage, setMacroMode, onDone } = props;
    if (code === 'Escape') {
      setInputMessage('');
      setMacroMode(null);
      onDone();
    }
  };

  const setMacroStartsWithMode = () => {
    const { message, setMacroMode } = props;
    if (!message) {
      setMacroMode('STARTS_WITH');
    }
  };

  const setMacroContainsMode = () => {
    const { message, setMacroMode } = props;
    if (!message) {
      setMacroMode('CONTAINS');
    }
  };

  const { renderProps, mode } = props;
  const isInMacroMode = Boolean(mode);

  return (
    <>
      {renderProps(setMacroStartsWithMode, setMacroContainsMode)}
      {isInMacroMode && macros && (
        <MacroSelect
          mode={mode}
          options={parsedMacros}
          verticalPosition={verticalPosition}
          onChange={handleSelectChange}
          onKeyDown={handleSelectKeyDown}
        />
      )}
    </>
  );
});
