import { Locale } from '../index';

const locale: Locale = {
  CommunicationTableHeaderOne: 'View',
  CommunicationTableHeaderTwo: 'Sent',
  CommunicationTableHeaderThree: 'Subject',
  CommunicationTableHeaderFour: 'Email',
  CommunicationTableHeaderFive: 'Status',
  CommunicationTableFooterAmount: 'Total',
  CommunicationStatusOpen: 'Open',
  CommunicationStatusSend: 'Sent',
  CommunicationStatusDelivered: 'Delivered',
  CommunicationStatusFail: 'Failure',
  CommunicationButtonLoadMore: 'Load more',
  CommunicationButtonClose: 'Close',
  CommunicationArchivedFrom: 'From',
  CommunicationArchivedTo: 'To',
  CommunicationArchivedSubject: 'Subject',
  Communication: 'Communication',
  ProtocolNumber: 'Protocol',
  Attendance: 'Attendance',
  Driver: 'Driver',
  Payments: 'Payments',
  Vehicles: 'Vehicles',
  Registration: 'Registration',
  Resolved: 'Resolved',
  NotResolved: 'Not resolved',
  Open: 'Open',
  'Initial date': 'Initial date',
  'Final date': 'Final date',
  Status: 'Status',
  Provider: 'Provider',
  Tags: 'Tags',
  Queues: 'Queues',
  Sent: 'Sent',
  'Joined on': 'Joined on',
  RI: 'SSN',
  Email: 'Email',
  'Main phone': 'Main phone',
  'Emergency phone 1': 'Emergency phone 1',
  'Emergency phone 2': 'Emergency phone 2',
  DriverLicense: 'Driver license',
  'Driver License Number': 'Driver License Number',
  'Driver License Points': 'Driving License Points',
  'Expiration Date': 'Expiration Date',
  'Has EAR': 'Has EAR',
  Situation: 'Situation',
  'Emission State': 'Emission State',
  'Driver License Time': 'Driver License Time',
  Category: 'Category',
  Plan: 'Plan',
  ActivePlan: 'Active Plan',
  NotPlan: 'there is no active plan',
  Value: 'Value',
  Next: 'Next',
  'Payment Day': 'Payment Day',
  Yes: 'Yes',
  No: 'No',
  Address: 'Address',
  'Transactions paid on time': 'Transactions paid on time',
  'Transactions paid in overdue': 'Transactions paid in overdue',
  'Probability of delay': 'Probability of delay',
  'Average delay': 'Average delay',
  day: 'day',
  'Total of invoices pending': 'Total of invoices pending',
  'Total of fines pending': 'Total of fines pending',
  'Total of maintenances pending': 'Total of maintenances pending',
  'Total of others pending': 'Total of others pending',
  'Total of debits pending': 'Total of debits pending',
  Name: 'Name',
  Text: 'Text',
  Subject: 'Subject',
  Edit: 'Edit',
  Delete: 'Delete',
  row: 'row',
  row_plural: 'rows',
  of: 'of',
  selected: 'selected',
  'First Page': 'First Page',
  'Previous Page': 'Previous Page',
  'Next Page': 'Next Page',
  'Last Page': 'Last Page',
  Actions: 'Actions',
  'No records': 'No records',
  Search: 'Search',
  Filter: 'Filter',
  Add: 'Add',
  Save: 'Save',
  Cancel: 'Cancel',
  'Macros Management': 'Macros Management',
  'Tags Management': 'Tags Management',
  Description: 'Description',
  Tag: 'Tag',
  Enabled: 'Enabled',
  'Full Name': 'Full Name',
  SID: 'SID',
  Workspace: 'Workspace',
  Activity: 'Activity',
  Languages: 'Languages',
  Skills: 'Skills',
  Levels: 'Levels',
  'Disabled Skills': 'Disabled Skills',
  'Disabled Levels': 'Disabled Levels',
  Roles: 'Roles',
  'Workers Management': 'Workers Management',
  'Error loading Workers list': 'Error loading Workers list',
  'Error editing Worker': 'Error editing Worker',
  'Error deleting Worker': 'Error deleting Worker',
  'Error adding Worker': 'Error adding Worker',
  Back: 'Back',
  Workers: 'Workers',
  Macros: 'Macros',
  Slug: 'Slug',
  Group: 'Group',
  'Block Car': 'Block Car',
  Finance: 'Finance',
  Maintenance: 'Maintenance',
  Others: 'Others',
  Schedule: 'Schedule',
  Signup: 'Signup',
  Color: 'Color',
  Country: 'Country',
  Active: 'Active',
  'No Driver Task selected!': 'No Driver Task selected!',
  'Validation error': 'Validation error',
  'Sent through': 'Sent through',
  'Task wrap up': 'Task wrap up',
  'Task created': 'Task created',
  'Task update': 'Task update',
  'Task complete': 'Task complete',
  'Task queue entered': 'Task queue entered',
  'Reservation accepted': 'Reservation accepted',
  'Reservation complete': 'Reservation complete',
  'Reservation created': 'Reservation created',
  Complete: 'Complete',
  'This user is not yet a customer There is no information to display!':
    'This user is not yet a customer. There is no information to display!',
  'This might be a network or a server error Check you internet connection and retry or contact the':
    'This might be a network or a server error! Check you internet connection and retry or contact the',
  'developer team': 'developer team',
  'Driver not found!': 'Driver not found!',
  Error: 'Error',
  Warning: 'Warning',
  Info: 'Info',
  Success: 'Success',
  Transfer: 'Transfer',
  MACRO_ALREADY_EXISTS: 'Macro already exists!',
  Activate: 'Activate',
  'Do you want to activate the macro named':
    'Do you want to activate the macro named',
  REQUIRED_ATTRIBUTES: 'Required attributes not informed',
  Inactive: 'Inactive',
  'Do you want to activate the tag named':
    'Do you want to activate the tag named',
  TAG_ALREADY_EXISTS: 'Tag already exists!',
  'is alread active': 'is already active',
  'The tag named': 'The tag named',
  'At least one tag must be selected!': 'At least one tag must be selected!',
  'Fail to find tags': 'Fail to find tags',
  'The macro named': 'The macro named',
  Ok: 'Ok',
  'There is no channelSid information from the chat':
    'There is no "channelSid" information from the chat.',
  Accessories: 'Accessories',
  Claims: 'Claims',
  Devolution: 'Devolution',
  Fines: 'Fines',
  Guarantee: 'Guarantee',
  Insurance: 'Insurance',
  Corrective: 'Corrective',
  Preventive: 'Preventive',
  Retention: 'Retention',
  RTO: 'RTO',
  'Maintenance Charge': 'Maintenance Charge',
};

export default locale;
