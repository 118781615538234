/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Actions } from '@twilio/flex-ui';
import { Box, Typography, Tooltip } from '@material-ui/core';
import {
  IconAttachFile,
  IconUpload,
  IconDelete,
} from '@kovi-cx-frontend/components';
import { ToolbarButton } from '../../components/ToolbarButton/ToolbarButton.Styled';
import * as Styled from './Attachment.Styled';

export interface Props {
  channelSid: string;
}

export const Attachment: FC<Props> = (props) => {
  const [file, setFile] = useState<any>(null);
  const { channelSid } = props;
  const inputId = 'upload-file';

  const handleSendAttachment = () => {
    Actions.invokeAction('SendMediaMessage', {
      file,
      channelSid,
    });
    setFile(null);
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const hasValidFile = (fileToCheck: any) => {
    if (fileToCheck && fileToCheck.name) return true;
    return false;
  };

  const renderAttachFileButton = () => {
    return (
      <Tooltip title="Add attachment">
        <ToolbarButton>
          <label htmlFor={inputId}>
            <Styled.FileInput
              type="file"
              id={inputId}
              name={inputId}
              onChange={handleFileChange}
            />
            <IconAttachFile color="primary" />
          </label>
        </ToolbarButton>
      </Tooltip>
    );
  };

  const renderSendAttachedFileButton = () => {
    return (
      <>
        <Box display="flex">
          <Typography>{file.name}</Typography>
          <ToolbarButton
            type="button"
            onClick={() => {
              setFile(null);
            }}
          >
            <IconDelete />
          </ToolbarButton>
          <ToolbarButton onClick={handleSendAttachment}>
            <IconUpload />
          </ToolbarButton>
        </Box>
      </>
    );
  };

  return (
    <>
      {!hasValidFile(file) && renderAttachFileButton()}
      {hasValidFile(file) && renderSendAttachedFileButton()}
    </>
  );
};

Attachment.propTypes = {
  channelSid: PropTypes.string.isRequired,
};
