/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import { MacroMode } from '../../Macros.State';
import { Macro } from '../../Macros.Typing';
import { styles } from './MacroSelect.Styles';
import { MacroSelectOption } from '../MacroSelectOption/MacroSelectOption';

interface Props {
  mode: MacroMode;
  options: Macro[];
  verticalPosition?: number;
  onChange: (option: Macro) => void;
  onKeyDown: (event: any) => void;
}

export enum matchFromEnum {
  STARTS_WITH = 'start',
  CONTAINS = 'any',
}

export const MacroSelect: FC<Props> = (props) => {
  const { options, verticalPosition, onChange, onKeyDown, mode } = props;
  const matchFrom = mode ? matchFromEnum[mode] : undefined;
  const filterOption = createFilter({ matchFrom });

  const handleChange = (option: any) => {
    onChange(option as Macro);
  };

  const VERTICAL_POSITION_ADJUSTMENT = 8;
  const top = `${
    (verticalPosition || VERTICAL_POSITION_ADJUSTMENT) -
    VERTICAL_POSITION_ADJUSTMENT
  }px`;

  return (
    <div
      style={{
        position: 'absolute',
        width: '568px',
        top,
      }}
    >
      <Select
        placeholder=""
        components={{ Option: MacroSelectOption }}
        onKeyDown={onKeyDown}
        options={options}
        onChange={handleChange}
        filterOption={filterOption}
        styles={styles}
        autoFocus
      />
    </div>
  );
};

MacroSelect.defaultProps = {
  options: undefined,
  verticalPosition: undefined,
  onChange: undefined,
  onKeyDown: undefined,
  mode: null,
};

MacroSelect.propTypes = {
  options: PropTypes.any,
  verticalPosition: PropTypes.number,
  onChange: PropTypes.any,
  onKeyDown: PropTypes.any,
  mode: PropTypes.any,
};
