import gql from 'graphql-tag';

export const UPDATE_ASSIGN_WORKER_TWILIO_MUTATION = gql`
  mutation assignWorkerTwilio($email: String!, $assign: Boolean!) {
    assignWorkerTwilio(input: { email: $email, assign: $assign }) {
      id
      name
      email
      activity
      skills
      roles
      countries
      capacities {
        channel
        value
      }
    }
  }
`;

export const UPDATE_WORKER_MUTATION = gql`
  mutation updateWorker(
    $id: ID!
    $name: String
    $email: String
    $activity: CxWorkerActivity
    $skills: [CxWorkerSkills]
    $roles: [CxWorkerRoles]
    $countries: [CxWorkerCountry]
    $capacities: [CxUpdateCapacitiesWorkerInput]
  ) {
    updateWorker(
      input: {
        id: $id
        name: $name
        email: $email
        activity: $activity
        skills: $skills
        roles: $roles
        countries: $countries
        capacities: $capacities
      }
    ) {
      id
      name
      email
      activity
      skills
      roles
      countries
      capacities {
        channel
        value
        label
      }
    }
  }
`;

export const UPDATE_WORKER_CHANNEL = gql`
  mutation workerSetChannel($input: WorkerChannelsInput) {
    workerSetChannel(input: $input) {
      sid
      work_channels {
        id
        enabled
        channels {
          sid
          friendly_name
          available
          capacity
        }
      }
    }
  }
`;
