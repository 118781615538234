import gql from 'graphql-tag';

const GET_ODOMETER_CHART = gql`
  query OdometerChart($id: ID!) {
    carHistories(id: $id) {
      items {
        created_at
        daily_odometer
        booking
      }
    }
    carHistoryCycles(id: $id) {
      cycles {
        start
        carHistories {
          daily_odometer
          created_at
        }
      }
    }
  }
`;

export default GET_ODOMETER_CHART;
