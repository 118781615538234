import gql from 'graphql-tag';

export const GET_TICKETS = gql`
  query tickets(
    $limit: Int
    $offset: Int
    $page: Int
    $filters: SupportTicketsFilterInput!
    $filtersIncludes: JSONObject
    $attributesIncludes: JSONObject
  ) {
    tickets(
      limit: $limit
      offset: $offset
      page: $page
      filters: $filters
      filtersIncludes: $filtersIncludes
      attributesIncludes: $attributesIncludes
    ) {
      limit
      total
      offset
      items {
        id
        status
        protocol_number
        tags {
          id
          name
          color
          SupportTicketsTag {
            origin
            support_agent_email
            is_active
          }
        }
      }
    }
  }
`;
