import gql from 'graphql-tag';

export const CREATE_COMMENTS = gql`
  mutation createTicketComment($input: SupportTicksCommentsInput) {
    createTicketComment(input: $input) {
      id
      support_ticket_id
      support_agent_email
      comment
      is_active
      internal
      created_at {
        date
        format(format: "dd/mm/yyyy")
      }
      updated_at {
        date
        format(format: "dd/mm/yyyy")
      }
    }
  }
`;
