import gql from 'graphql-tag';

export const GET_COMMUNICATION = gql`
  query Communication(
    $limit: Int
    $offset: Int
    $page: Int
    $filters: supportCommunicationFilterInput!
  ) {
    supportCommunication(
      limit: $limit
      offset: $offset
      page: $page
      filters: $filters
    ) {
      next
      items {
        id
        subject
        type
        created
        recipient
        metrics {
          delivered
          sent
          opened
          attempted
          failed
        }
      }
    }
  }
`;

export const GET_COMMUNICATION_ARCHIVED = gql`
  query CommunicationArchived(
    $limit: Int
    $offset: Int
    $page: Int
    $filters: supportCommunicationArchivedFilterInput!
  ) {
    supportCommunicationArchived(
      limit: $limit
      offset: $offset
      page: $page
      filters: $filters
    ) {
      items {
        id
        recipient
        from
        body
      }
    }
  }
`;
