/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy } from 'react';
import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';
import i18n, { LocaleStrings } from '@kovi-cx-frontend/i18n';
import Icon from '@material-ui/icons/PermPhoneMsg';
import { SuspenseBase } from '../components/SuspenseBase/SuspenseBase';

export const LazyAttendanceChannel = lazy(() => {
  return import('@kovi-cx-frontend/flex-attendance-channel');
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function setupAdminAttendanceChannel(
  flex: typeof Flex,
  _manager: Manager
) {
  flex.SideNav.Content.add(
    <Flex.SideLink
      showLabel
      icon={<Icon />}
      isActive
      onClick={() => {
        Flex.Actions.invokeAction('HistoryPush', '/attendance-channel');
      }}
      key="AttendanceChannelSidebarLink"
    >
      {i18n.t(LocaleStrings.Workers)}
    </Flex.SideLink>
  );

  flex.ViewCollection.Content.add(
    <Flex.View name="attendance-channel" key="attendance-channel-key">
      <SuspenseBase>
        <div
          style={{
            zIndex: 1,
            height: 'calc(100vh - 70px)',
            width: '100%',
            paddingLeft: '1px',
          }}
        >
          <LazyAttendanceChannel />
        </div>
      </SuspenseBase>
    </Flex.View>
  );
}
