/* eslint-disable no-restricted-globals */
import { Auth } from 'aws-amplify';
import { AUTH_REDIRECT_LOCAL_STORAGE } from './constants';

export const clearStorage = () => {
  return window.localStorage.removeItem(AUTH_REDIRECT_LOCAL_STORAGE);
};

export const getLocalPath = () => {
  return window.localStorage.getItem(AUTH_REDIRECT_LOCAL_STORAGE);
};

export const setLocalPath = (path: string) => {
  return window.localStorage.setItem(AUTH_REDIRECT_LOCAL_STORAGE, path);
};

export const getSignOutURL = () => {
  const domain = process.env.AWS_COGNITO_HOSTED_UI;
  const redirectSignIn = `${location.origin} /blush-boar-2943`;
  const responseType = 'code';
  const { userPoolWebClientId } = Auth.configure();

  return `https://${domain}/logout?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}`;
};

export const getUserDisplayName = (user: any) => {
  try {
    const [{ displayName }] = JSON.parse(user.name);

    return displayName;
  } catch (error) {
    return '';
  }
};

export const signOut = async () => {
  await Auth.signOut({
    global: true,
  });

  window.location.replace(getSignOutURL());
};
