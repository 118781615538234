/* eslint-disable import/no-unresolved */
import { GeneratedTypes } from '@kovi-cx-frontend/apollo-graphql-kovi';
import { Macro } from './Macros.Typing';

export function parseMacros(
  macros: GeneratedTypes.GetMacros_macros_items[]
): Macro[] {
  return macros.map((macro: GeneratedTypes.GetMacros_macros_items) => {
    return {
      label: `${macro.name} || ${macro.text}`,
      value: macro.text,
      tags: macro.tags,
      id: macro.id,
      name: macro.name
    };
  });
}

export function fillTemplate(template: string, placeholders: {}) {
  const formatedTemplate = template.replace('{{', '${this.').replace('}}', '}');
  // eslint-disable-next-line no-new-func
  return new Function(`return \`${formatedTemplate}\`;`).call(placeholders);
}

export function deepFillTemplate(template: string, placeholders: {}) {
  let result = fillTemplate(template, placeholders);
  while (result.includes('{{')) {
    result = fillTemplate(result, placeholders);
  }
  return result;
}

export function removeUndefineds(str: string) {
  return str.replace(/ undefined/g, '').trim();
}
