import { useQuery, useMutation } from '@apollo/client';
import { GET_MACROS } from './Macros.Queries';
import { CREATE_MACRO, UPDATE_MACRO, DELETE_MACRO } from './Macros.Mutations';
import {
  createMacroVariables,
  GetMacrosVariables,
  deleteMacroVariables,
  updateMacroVariables,
} from '../../__generated_rental__';

export function useMacrosQuery(macrosQueryVariables?: GetMacrosVariables) {
  const variables = { filters: { is_active: true }, ...macrosQueryVariables };
  return useQuery(GET_MACROS, { variables });
}

export function useMacroCreateMutation(variables: createMacroVariables) {
  return useMutation(CREATE_MACRO, { variables });
}

export function useMacroUpdateMutation(variables: updateMacroVariables) {
  return useMutation(UPDATE_MACRO, { variables });
}

export function useMacroDeleteMutation(variables: deleteMacroVariables) {
  return useMutation(DELETE_MACRO, { variables });
}
