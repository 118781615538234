/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { ColumnBase } from './TableMinimal';

interface Props<Column, Row> {
  columns: Column[];
  rows: Row[];
}

export function Rows<Column extends ColumnBase, Row extends { id: string }>(
  props: Props<Column, Row>
) {
  const { rows, columns } = props;

  const renderRow = (row: Row, column: Column) => {
    return (
      <TableCell key={`${column.field}-${row.id}`}>
        {column.render ? column.render(row) : (row as any)[column.field]}
      </TableCell>
    );
  };

  return (
    <TableBody>
      {rows.map((row: Row) => {
        return (
          <TableRow key={row.id}>
            {columns.map((column: Column) => {
              return renderRow(row, column);
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
}

Rows.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};
