// eslint-disable-next-line import/no-unresolved
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from './store';
import { selectTags, selectWorker } from './Tags.Selectors';
import { setSelectedTags, Tag } from './Tags.State';
import { Tags } from './Tags';

export interface DispatchToProps {
  setSelectedTags: (tags: Tag[]) => void;
}

const mapStateToProps = (state: AppState) => {
  const tagsState = selectTags(state);
  const worker = selectWorker(state);
  return { tagsState, worker };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchToProps => {
  return {
    setSelectedTags: bindActionCreators(setSelectedTags, dispatch),
  };
};

export const TagsContainer = connect(mapStateToProps, mapDispatchToProps)(Tags);
