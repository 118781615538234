import gql from 'graphql-tag';

export const GET_MACROS = gql`
  query GetMacros(
    $limit: Int
    $offset: Int
    $page: Int
    $filters: SupportMacrosFilterInput
    $filtersIncludes: JSONObject
    $attributesIncludes: JSONObject
  ) {
    macros(
      limit: $limit
      offset: $offset
      page: $page
      filters: $filters
      filtersIncludes: $filtersIncludes
      attributesIncludes: $attributesIncludes
    ) {
      limit
      total
      offset
      items {
        id
        name
        text
        subject
        is_active
        region
        tags {
          id
          name
          color
        }
      }
    }
  }
`;
