import gql from 'graphql-tag';

export const CREATE_MACRO = gql`
  mutation createMacro(
    $input: SupportMacrosCreateInput
    $attributesIncludes: JSONObject
  ) {
    createMacro(input: $input, attributesIncludes: $attributesIncludes) {
      status
      statusCode
      message
      macro {
        id
        name
        is_active
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const UPDATE_MACRO = gql`
  mutation updateMacro($input: SupportMacrosUpdateInput) {
    updateMacro(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_MACRO = gql`
  mutation deleteMacro($input: SupportMacrosDeleteInput) {
    deleteMacro(input: $input) {
      id
    }
  }
`;
