import * as Flex from '@twilio/flex-ui';
import { Manager } from '@twilio/flex-ui';
import { setupThemeKovi } from '@kovi-cx-frontend/flex-theme-kovi';
import { setupTaskTransfer } from '@kovi-cx-frontend/flex-task-transfer';
import { setupI18n } from '@kovi-cx-frontend/flex-i18n';
import { setupCRMDriver } from './setupCRMDriver';
import { setupAdminMacros } from './setupAdminMacros';
import { setupAdminWorkers } from './setupAdminWorkers';
import { setupAdminTags } from './setupAdminTags';
import { setupChat } from './setupChat';
import { setupVoice } from './setupVoice';
import { setupAdminAttendanceChannel } from './setupAdminAttendanceChannel';
import { voiceCSAT } from './voiceCSAT';
import { autoAcceptTask } from './autoAcceptTask';
import { flexVerifiedNumber } from './flexVerifiedNumber';

export function applyCustomFlexFeatures(
  flexInstance: typeof Flex,
  manager: Manager
) {
  const agentEmail = manager.store.getState()?.flex?.worker?.attributes?.email;
  const agentName = manager.store.getState()?.flex?.worker?.attributes.full_name;
  const roles = manager.store.getState()?.flex?.worker?.attributes?.roles;
  const hasAccessToAdminFeatures =
    roles.includes('admin') || roles.includes('supervisor');

  if (hasAccessToAdminFeatures) {
    setupAdminMacros(flexInstance, manager, agentEmail);
    setupAdminTags(flexInstance, manager, agentEmail);
    setupAdminWorkers(flexInstance, manager);
  }

  setupI18n(flexInstance, manager);
  setupTaskTransfer(flexInstance, manager);
  setupThemeKovi(flexInstance, manager);
  setupChat(flexInstance, manager, agentEmail, agentName);
  setupVoice(flexInstance, manager);
  setupCRMDriver(flexInstance);
  voiceCSAT(flexInstance, manager);
  autoAcceptTask(flexInstance, manager);
  setupAdminAttendanceChannel(flexInstance, manager);
  flexVerifiedNumber(flexInstance);
}
