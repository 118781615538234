import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { selectMessage } from './MessageInput.Selectors';
import { setInputMessage } from './MessageInput.State';
import { MessageInput } from './MessageInput';

export interface StateToProps {
  message: string;
}

export interface DispatchToProps {
  setInputMessage: (message: string) => void;
}

const mapStateToProps = (state: any): StateToProps => {
  return {
    message: selectMessage(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchToProps => {
  return {
    setInputMessage: bindActionCreators(setInputMessage, dispatch),
  };
};

export const MessageInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageInput);
