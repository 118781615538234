import { createSelector } from 'reselect';
import { AppState } from './store';

const getMacroState = (state: AppState) => {
  return state['chat-macro'];
};

const getFlexChatState = (state: AppState) => {
  return state.flex.chat;
};

export const selectMessage = createSelector([getMacroState], (state) => {
  return state.messageInput.message;
});

export const selectActiveChatChannel: any = createSelector(
  [getFlexChatState],
  (chat) => {
    return chat.messageList.activeChatChannel;
  }
);
