import React, { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Rating as MuiRating } from '@material-ui/lab';

type ID = string | number;

interface Props {
  id: ID;
  value: number;
  name: string;
  onChange?: (id: ID, newValue: number | null) => void;
}

export const Rating: FC<Props> = ({ id, value, name, onChange }) => {
  const handleChange = (_event: ChangeEvent<{}>, newValue: number | null) => {
    if (onChange) {
      onChange(id, newValue);
    }
  };

  return (
    <MuiRating
      name={name}
      size="small"
      value={value}
      readOnly={!onChange}
      onChange={handleChange}
    />
  );
};

Rating.defaultProps = {
  onChange: undefined,
};

Rating.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  value: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
