import { Locale } from '../index';

const locale: Locale = {
  CommunicationTableHeaderOne: 'Para ver',
  CommunicationTableHeaderTwo: 'Enviado',
  CommunicationTableHeaderThree: 'Tema en cuestion',
  CommunicationTableHeaderFour: 'Correo electrónico',
  CommunicationTableHeaderFive: 'Estado',
  CommunicationTableFooterAmount: 'Total',
  CommunicationStatusOpen: 'Abierto',
  CommunicationStatusSend: 'Enviado',
  CommunicationStatusDelivered: 'Entreque',
  CommunicationStatusFail: 'Falla',
  CommunicationButtonLoadMore: 'Carga más',
  CommunicationButtonClose: 'Cerca',
  CommunicationArchivedFrom: 'En',
  CommunicationArchivedTo: 'Para',
  CommunicationArchivedSubject: 'Tema en cuestion',
  Communication: 'Comunicación',
  ProtocolNumber: 'Protocolo',
  Attendance: 'Asistencia',
  Driver: 'Conductor',
  Payments: 'Pagos',
  Vehicles: 'Veículos',
  Registration: 'Registro',
  Resolved: 'Resuelto',
  NotResolved: 'No resuelto',
  Open: 'Abierto',
  'Initial date': 'Fecha de inicio',
  'Final date': 'Fecha final',
  Status: 'Status',
  Provider: 'Fuente',
  Tags: 'Tags',
  Queues: 'Colas',
  Sent: 'Expedido',
  'Joined on': 'Unido a',
  RI: 'NIT',
  Email: 'Email',
  'Main phone': 'Teléfono principal',
  'Emergency phone 1': 'Teléfono de emergencia 1',
  'Emergency phone 2': 'Teléfono de emergencia 2',
  DriverLicense: 'Licencia para conducir',
  'Driver License Number': 'Número de licencia de conducir',
  'Driver License Points': 'Puntos de licencia de conducir',
  'Expiration Date': 'Fecha de Validez',
  'Has EAR': 'Tiene EAR',
  Situation: 'Situación',
  'Emission State': 'Estado de emisión',
  'Driver License Time': 'Tiempo de licencia de conducir',
  Category: 'Categoría',
  Plan: 'Plan',
  ActivePlan: 'Plano Activo',
  NotPlan: 'no hay plan activo',
  Value: 'Valor',
  Next: 'Próximo',
  'Payment Day': 'Payment Day',
  Yes: 'Si',
  No: 'No',
  Address: 'Domicilio',
  'Transactions paid on time': 'Facturas pagadas a tiempo',
  'Transactions paid in overdue': 'Transacciones pagadas vencidas',
  'Probability of delay': 'Probabilidad de retraso',
  'Average delay': 'Retraso promedio',
  day: 'día',
  'Total of invoices pending': 'Total de facturas pendientes',
  'Total of fines pending': 'Total de multas pendientes',
  'Total of maintenances pending': 'Total de mantenimientos pendientes',
  'Total of others pending': 'Total otros pendientes',
  'Total of debits pending': 'Total de deudas pendientes',
  Name: 'Nombre',
  Text: 'Texto',
  Subject: 'Sujeto',
  Edit: 'Editar',
  Delete: 'Eliminar',
  row: 'línea',
  row_plural: 'líneas',
  of: 'de',
  selected: 'seleccionado',
  'First Page': 'Primera página',
  'Previous Page': 'Pagina anterior',
  'Next Page': 'Siguiente página',
  'Last Page': 'Última página',
  Actions: 'Comportamiento',
  'No records': 'No hay registros',
  Search: 'Buscar',
  Filter: 'Filtrar',
  Add: 'Añadir',
  Save: 'Ahorrar',
  Cancel: 'Cancelar',
  'Macros Management': 'Administración de Macros',
  'Tags Management': 'Administración de Tags',
  Description: 'Descripción',
  Tag: 'Tag',
  Enabled: 'Habilitado',
  'Full Name': 'Nombre completo',
  SID: 'SID',
  Workspace: 'Workspace',
  Activity: 'Activity',
  Languages: 'Idiomas',
  Skills: 'Habilidades',
  Levels: 'Niveles',
  'Disabled Skills': 'Habilidades deshabilitadas',
  'Disabled Levels': 'Niveles deshabilitados',
  Roles: 'Acesos',
  'Workers Management': 'Gerenciamento de Analistas',
  'Error loading Workers list': 'Error al cargar la lista de Analistas',
  'Error editing Worker': 'Error al editar Analistas',
  'Error deleting Worker': 'Error al eliminar Analista',
  'Error adding Worker': 'Error al agregar Analista',
  Back: 'Volver',
  Workers: 'Analistas',
  Macros: 'Macros',
  Slug: 'Slug',
  Group: 'Grupo',
  'Block Car': 'Coche de bloque',
  Finance: 'Finanzas',
  Maintenance: 'Mantenimiento',
  Others: 'Otros',
  Schedule: 'Calendario',
  Signup: 'Regístrate',
  Color: 'Color',
  Country: 'País',
  Active: 'Activo',
  'No Driver Task selected!': '¡No se seleccionó ninguna tarea de controlador!',
  'Validation error': 'Error de validacion',
  'Sent through': 'Enviado a través de',
  'Task wrap up': 'Resumen de tareas',
  'Task created': 'Tarea creada',
  'Task update': 'Tarea actualizada',
  'Task complete': 'Tarea completada',
  'Task queue entered': 'Cola de tareas ingresada',
  'Reservation accepted': 'Reserva aceptada',
  'Reservation complete': 'Reserva completa',
  'Reservation created': 'Reserva creada',
  Complete: 'Completar',
  'This user is not yet a customer There is no information to display!':
    'Este usuario aún no es cliente. ¡No hay información para mostrar!',
  'This might be a network or a server error Check you internet connection and retry or contact the':
    '¡Esto podría ser un error de red o de servidor! Verifique su conexión a Internet y vuelva a intentarlo o comuníquese con el',
  'developer team': 'equipo desarrollador',
  'Driver not found!': 'Driver no encontrado!',
  Error: 'Error',
  Warning: 'Advertencia',
  Info: 'Información',
  Success: 'Éxito',
  Transfer: 'Transferir',
  MACRO_ALREADY_EXISTS: '¡Macro ya existe!',
  Activate: 'Activar',
  'Do you want to activate the macro named':
    '¿Quieres activar la macro llamada',
  REQUIRED_ATTRIBUTES: 'Atributos obligatorios no informados',
  Inactive: 'Inactivo',
  'Do you want to activate the tag named': '¿Quieres activar la tag llamada',
  TAG_ALREADY_EXISTS: 'La tag ya existe!',
  'is alread active': 'se lee activa',
  'The tag named': 'Las etiquetas nombradas',
  'At least one tag must be selected!':
    '¡Debe seleccionarse al menos una etiqueta!',
  'Fail to find tags': 'Error al encontrar etiquetas',
  'The macro named': 'La macro llamada',
  Ok: 'Ok',
  'There is no channelSid information from the chat':
    'No hay información de "channelSid" del chat.',
  Accessories: 'Accessories',
  Claims: 'Claims',
  Devolution: 'Devolution',
  Fines: 'Fines',
  Guarantee: 'Guarantee',
  Insurance: 'Insurance',
  Corrective: 'Corrective',
  Preventive: 'Preventive',
  Retention: 'Retencion',
  RTO: 'RTO',
  'Maintenance Charge': 'Maintenance Charge',
};

export default locale;
